import React from 'react';
import { Link } from 'react-router-dom';

export default function Explore() {
  return (
    <section className="explore__area py-5">
      <div className="container">
        <div
          className="section__title"
        >
          <h3 style={{ lineHeight: '1.2' }}>
            Explore The <br className="d-md-none" /> <span>Marketplace</span>
          </h3>
        </div>
        <div className="explore__inner__wrap">
          <div className="row g-4">
            <div className="col-xl-2 col-lg-4 col-md-4 col-4">
              <Link to="/s?pub_category=tools">
                <div
                  className="single__explore__card"
                >
                  <div className="explore__card__ico">
                    <img
                      src={`${process.env.PUBLIC_URL}/static/assets/img/explore_icon1.svg`}
                      alt=""
                    />
                  </div>
                  <div className="explore__content">
                    <p>Tools</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-4">
              <Link to="/s?pub_category=electronics">
                <div
                  className="single__explore__card"
                >
                  <div className="explore__card__ico active">
                    <img
                      src={`${process.env.PUBLIC_URL}/static/assets/img/explore_icon2.svg`}
                      alt=""
                    />
                  </div>
                  <div className="explore__content">
                    <p>Electronics </p>
                    <div className="hero__popular__btn explore__btn">
                      <a href="#" className="popular__btn tools_btn">
                        New
                      </a>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-4">
              <Link to="/s?pub_category=micromobility">
                <div
                  className="single__explore__card"
                >
                  <div className="explore__card__ico">
                    <img
                      src={`${process.env.PUBLIC_URL}/static/assets/img/explore_icon3.svg`}
                      alt=""
                    />
                  </div>
                  <div className="explore__content">
                    <p>Micromobility</p>
                    <div className="hero__popular__btn explore__btn">
                      <a href="#" className="popular__btn electronics_btn">
                        Beta
                      </a>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-4">
              <Link to="/s?pub_category=musicalInstruments">
                <div
                  className="single__explore__card"
                >
                  <div className="explore__card__ico">
                    <img
                      src={`${process.env.PUBLIC_URL}/static/assets/img/explore_icon4.svg`}
                      alt=""
                    />
                  </div>
                  <div className="explore__content">
                    <p>Musical instruments</p>
                    <div className="hero__popular__btn explore__btn">
                      <a href="#" className="popular__btn micromobility_btn">
                        Coming Soon
                      </a>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-4">
              <Link to="/s?pub_category=camerasPhoto">
                <div
                  className="single__explore__card"
                >
                  <div className="explore__card__ico">
                    <img
                      src={`${process.env.PUBLIC_URL}/static/assets/img/explore_icon5.svg`}
                      alt=""
                    />
                  </div>
                  <div className="explore__content">
                    <p>Cameras and Photo</p>
                    <div className="hero__popular__btn explore__btn">
                      <a href="#" className="popular__btn micromobility_btn">
                        Coming Soon
                      </a>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-4">
              <Link to="/s?pub_category=sportsEquipment">
                <div
                  className="single__explore__card"
                >
                  <div className="explore__card__ico">
                    <img
                      src={`${process.env.PUBLIC_URL}/static/assets/img/explore_icon6.svg`}
                      alt=""
                    />
                  </div>
                  <div className="explore__content">
                    <p>Sports Equipment</p>
                    <div className="hero__popular__btn explore__btn">
                      <a href="#" className="popular__btn micromobility_btn">
                        Coming Soon
                      </a>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
