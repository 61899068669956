import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SearchFiltersPrimary.module.css';
import IconCollection from '../IconCollection/IconCollection';
import NamedLink from '../NamedLink/NamedLink';
import layerContainer from '../../assets/sectionHero/dark-layer-container.png';
import appleVisionPro from '../../assets/sectionHero/apple-vision.png';
import linearGradient1 from '../../assets/sectionHero/linear-1.png';
import linearGradient2 from '../../assets/sectionHero/linear-2.png';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { displayCategoriesData } from '../../marketplace-custom-config';
// import IconBack from '../IconBack/IconBack';

const currentPath =
  typeof window !== 'undefined' && window.location && window.location.pathname;

const SearchFiltersPrimaryComponent = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    isSecondaryFiltersOpen,
    toggleSecondaryFiltersOpen,
    selectedSecondaryFiltersCount,
    isSearchMapOpenOnMobile,
    showList,
    showLists,
    showMap,
    showListState,
    isLandingPage,
    urlQueryParams,
  } = props;
  const params = useParams();

  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(rootClassName || css.root, className);
  const location = useLocation();

  const isAppleVisionPage =
    location.pathname === '/rent-apple-vision-pro/brooklyn-ny' ||
    urlQueryParams === 'apple vision';
  const isPressureWasherPage =
    location.pathname === '/pressure-washer-rental/brooklyn-ny' ||
    urlQueryParams === 'pressure washer';
  const isToolPage =
    location.pathname === '/tool-rental/brooklyn-ny' ||
    urlQueryParams === 'tool';
  const isMopedPage =
    location.pathname === '/moped-rental/brooklyn-ny' ||
    urlQueryParams === 'moped';
  const isBikePage =
    location.pathname === '/bike-rental/brooklyn-ny' ||
    urlQueryParams === 'bike';
  const isCarpetCleanerPage =
    location?.pathname === '/carpet-cleaner-rental/brooklyn-ny' ||
    urlQueryParams === 'carpet cleaner';
  const isLadderPage =
    location?.pathname === '/ladder-rental/brooklyn-ny' ||
    urlQueryParams === 'ladder';
  const isGeneratorPage =
    location?.pathname === '/generator-rental/brooklyn-ny' ||
    urlQueryParams === 'generator';
  const isPalletJackPage =
    location?.pathname === '/pallet-jack-rental/brooklyn-ny' ||
    urlQueryParams === 'pallet jack';
  const isPaintSprayerPage =
    location?.pathname === '/paint-sprayer-rental/brooklyn-ny' ||
    urlQueryParams === 'paint sprayer';
  const isJackHammerPage =
    location?.pathname === '/jack-hammer-rental/brooklyn-ny' ||
    urlQueryParams === 'jack hammer';
  const isAirCompressorPage =
    location?.pathname === '/air-compressor-rental/brooklyn-ny' ||
    urlQueryParams === 'air compressor';
  const isHandTruckPage =
    location?.pathname === '/hand-truck-rental/brooklyn-ny' ||
    urlQueryParams === 'hand truck';
  const isWelderPage =
    location?.pathname === '/welder-rental/brooklyn-ny' ||
    urlQueryParams === 'welder';
  const isNailGunPage =
    location?.pathname === '/nail-gun-rental/brooklyn-ny' ||
    urlQueryParams === 'nail gun';

  const toggleSecondaryFiltersOpenButtonClasses =
    isSecondaryFiltersOpen || selectedSecondaryFiltersCount > 0
      ? isAppleVisionPage ||
        isPressureWasherPage ||
        isToolPage ||
        isMopedPage ||
        isBikePage ||
        isCarpetCleanerPage ||
        isLadderPage ||
        isGeneratorPage ||
        isPalletJackPage ||
        isPaintSprayerPage ||
        isJackHammerPage ||
        isAirCompressorPage ||
        isHandTruckPage ||
        isWelderPage ||
        isNailGunPage ||
        !!params.category ||
        !!displayCategoriesData[params.category]
        ? css.searchFiltersPanelAppleOpen
        : css.searchFiltersPanelOpen
      : isAppleVisionPage ||
        isPressureWasherPage ||
        isToolPage ||
        isMopedPage ||
        isBikePage ||
        isCarpetCleanerPage ||
        isLadderPage ||
        isGeneratorPage ||
        isPalletJackPage ||
        isPaintSprayerPage ||
        isJackHammerPage ||
        isAirCompressorPage ||
        isHandTruckPage ||
        isWelderPage ||
        isNailGunPage ||
        !!params.category ||
        !!displayCategoriesData[params.category]
      ? css.searchFiltersPanelAppleClosed
      : css.searchFiltersPanelClosed;
  const toggleSecondaryFiltersOpenButton = toggleSecondaryFiltersOpen ? (
    <button
      className={classNames(
        toggleSecondaryFiltersOpenButtonClasses,
        isAppleVisionPage ||
          isPressureWasherPage ||
          isToolPage ||
          isMopedPage ||
          isBikePage ||
          isCarpetCleanerPage ||
          isLadderPage ||
          isGeneratorPage ||
          isPalletJackPage ||
          isPaintSprayerPage ||
          isJackHammerPage ||
          isAirCompressorPage ||
          isHandTruckPage ||
          isWelderPage ||
          isNailGunPage ||
          !!params.category ||
          !!displayCategoriesData[params.category]
          ? css.searchFilterButtonApple
          : css.searchFilterButton
      )}
      onClick={() => {
        toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
      }}
    >
      {/* {isAppleVisionPage || isPressureWasherPage ? (
        <IconCollection name="ICON_ FILTER_APPLE" />
      ) : ( */}
      <IconCollection name="ICON_SETTINGS" />
      {/* )} */}
      {/* <FormattedMessage
        id="SearchFiltersPrimary.moreFiltersButton"
        values={{ count: selectedSecondaryFiltersCount }}
      /> */}
    </button>
  ) : null;

  return (
    <>
      {urlQueryParams !== 'apple vision' &&
        location.pathname !== '/rent-apple-vision-pro/brooklyn-ny' &&
        urlQueryParams !== 'pressure washer' &&
        location.pathname !== '/pressure-washer-rental/brooklyn-ny' &&
        urlQueryParams !== 'tool' &&
        location.pathname !== '/tool-rental/brooklyn-ny' &&
        urlQueryParams !== 'moped' &&
        location.pathname !== '/moped-rental/brooklyn-ny' &&
        urlQueryParams !== 'bike' &&
        location.pathname !== '/bike-rental/brooklyn-ny' &&
        urlQueryParams !== 'carpet cleaner' &&
        location.pathname !== '/carpet-cleaner-rental/brooklyn-ny' &&
        urlQueryParams !== 'ladder' &&
        location.pathname !== '/ladder-rental/brooklyn-ny' &&
        urlQueryParams !== 'generator' &&
        location.pathname !== '/generator-rental/brooklyn-ny' &&
        urlQueryParams !== 'pallet jack' &&
        location.pathname !== '/pallet-jack-rental/brooklyn-ny' &&
        urlQueryParams !== 'paint sprayer' &&
        location.pathname !== '/paint-sprayer-rental/brooklyn-ny' &&
        urlQueryParams !== 'jack hammer' &&
        location.pathname !== '/jack-hammer-rental/brooklyn-ny' &&
        urlQueryParams !== 'air compressor' &&
        location.pathname !== '/air-compressor-rental/brooklyn-ny' &&
        urlQueryParams !== 'hand truck' &&
        location.pathname !== '/hand-truck-rental/brooklyn-ny' &&
        urlQueryParams !== 'welder' &&
        location.pathname !== '/welder-rental/brooklyn-ny' &&
        urlQueryParams !== 'nail gun' &&
        location.pathname !== '/nail-gun-rental/brooklyn-ny' &&
        (!params.category || !displayCategoriesData[params.category] ? (
          <div className={css.searchBarWrapper}>
            <div className={css.inputMain}>
              {showListState ? (
                <div className={css.searchFilterBack}>
                  <NamedLink name="LandingPage">
                    <IconCollection name="SEARCH_BAR_BACK" />
                  </NamedLink>
                </div>
              ) : null}

              <div className={classNames(css.inputWrapperMain)}>
                {children}
                {toggleSecondaryFiltersOpenButton}
                <IconCollection name="SEARCH_FILTER_ICON" />
              </div>
            </div>
          </div>
        ) : (
          <div className={css.appleVisionWrapper}>
            <div className={`${css.appleVisionContainer} px-4 py-3 bg-white`}>
              <div className={css.appleVisionContent}>
                <h1 className={`${css.appleVisionHeading} fw-bold`}>
                  {displayCategoriesData[params.category].titlePrimary}{' '}
                  <span className={css.appleVisionColor}>
                    {displayCategoriesData[params.category].titleSecondary}
                  </span>
                </h1>
                <p className={css.appleVisionParagraph}>
                  {displayCategoriesData[params.category].desc}
                </p>

                <div className={classNames(css.appleSearchBarWrapper)}>
                  {children}
                  <div className="d-flex justify-content-end align-items-center">
                    {toggleSecondaryFiltersOpenButton}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      {isAppleVisionPage && (
        <div className={css.appleVisionWrapper}>
          <div className={`${css.appleVisionContainer} px-4 py-3 bg-white`}>
            <div className={css.appleVisionContent}>
              <h1 className={`${css.appleVisionHeading} fw-bold`}>
                Rent{' '}
                <span className={css.appleVisionColor}>Apple Vision Pro</span>{' '}
                in <span className={css.appleVisionColor}>Brooklyn, NY</span>
              </h1>
              <p className={css.appleVisionParagraph}>
                The Runtize renting marketplace now offers Apple Vision Pro
                rentals, allowing you to rent it for one or more days and have
                it door delivered within hours.
              </p>
              <div className={classNames(css.appleSearchBarWrapper)}>
                {children}
                <div className="d-flex justify-content-end align-items-center">
                  {toggleSecondaryFiltersOpenButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isPressureWasherPage && (
        <div className={css.appleVisionWrapper}>
          <div className={`${css.appleVisionContainer} px-4 py-3 bg-white`}>
            <div className={css.appleVisionContent}>
              <h1 className={`${css.appleVisionHeading} fw-bold`}>
                Rent{' '}
                <span className={css.appleVisionColor}>Pressure Washers</span>{' '}
                in <span className={css.appleVisionColor}>Brooklyn, NY</span>
              </h1>
              <p className={css.appleVisionParagraph}>
                Power washer rentals in Brooklyn, NY door delivered on demand.
                Rent tools & equipment, electronics, and more on the Runtize
                rental marketplace.
              </p>
              <div className={classNames(css.appleSearchBarWrapper)}>
                {children}
                <div className="d-flex justify-content-end align-items-center">
                  {toggleSecondaryFiltersOpenButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isToolPage && (
        <div className={css.appleVisionWrapper}>
          <div className={`${css.appleVisionContainer} px-4 py-3 bg-white`}>
            <div className={css.appleVisionContent}>
              <h1 className={`${css.appleVisionHeading} fw-bold`}>
                Rent <span className={css.appleVisionColor}>Tools</span> in{' '}
                <span className={css.appleVisionColor}>Brooklyn, NY</span>
              </h1>
              <p className={css.appleVisionParagraph}>
                Tool rentals in Brooklyn, NY door delivered on demand. Rent
                tools & equipment, electronics, and more on the Runtize rental
                marketplace.
              </p>
              <div className={classNames(css.appleSearchBarWrapper)}>
                {children}
                <div className="d-flex justify-content-end align-items-center">
                  {toggleSecondaryFiltersOpenButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isBikePage && (
        <div className={css.appleVisionWrapper}>
          <div className={`${css.appleVisionContainer} px-4 py-3 bg-white`}>
            <div className={css.appleVisionContent}>
              <h1 className={`${css.appleVisionHeading} fw-bold`}>
                Rent <span className={css.appleVisionColor}>Bikes</span> in{' '}
                <span className={css.appleVisionColor}>Brooklyn, NY</span>
              </h1>
              <p className={css.appleVisionParagraph}>
                Bike rentals in Brooklyn, NY door delivered on demand. Rent
                tools & equipment, electronics, and more on the Runtize rental
                marketplace.
              </p>
              <div className={classNames(css.appleSearchBarWrapper)}>
                {children}
                <div className="d-flex justify-content-end align-items-center">
                  {toggleSecondaryFiltersOpenButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMopedPage && (
        <div className={css.appleVisionWrapper}>
          <div className={`${css.appleVisionContainer} px-4 py-3 bg-white`}>
            <div className={css.appleVisionContent}>
              <h1 className={`${css.appleVisionHeading} fw-bold`}>
                Rent <span className={css.appleVisionColor}>Mopes</span> in{' '}
                <span className={css.appleVisionColor}>Brooklyn, NY</span>
              </h1>
              <p className={css.appleVisionParagraph}>
                Mope rentals in Brooklyn, NY door delivered on demand. Rent
                tools & equipment, electronics, and more on the Runtize rental
                marketplace.
              </p>
              <div className={classNames(css.appleSearchBarWrapper)}>
                {children}
                <div className="d-flex justify-content-end align-items-center">
                  {toggleSecondaryFiltersOpenButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isCarpetCleanerPage && (
        <div className={css.appleVisionWrapper}>
          <div className={`${css.appleVisionContainer} px-4 py-3 bg-white`}>
            <div className={css.appleVisionContent}>
              <h1 className={`${css.appleVisionHeading} fw-bold`}>
                Rent{' '}
                <span className={css.appleVisionColor}>Carpet Cleaners</span> in{' '}
                <span className={css.appleVisionColor}>Brooklyn, NY</span>
              </h1>
              <p className={css.appleVisionParagraph}>
                Carpet Cleaner rentals in Brooklyn, NY door delivered on demand.
                Rent tools & equipment, electronics, and more on the Runtize
                rental marketplace.
              </p>
              <div className={classNames(css.appleSearchBarWrapper)}>
                {children}
                <div className="d-flex justify-content-end align-items-center">
                  {toggleSecondaryFiltersOpenButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLadderPage && (
        <div className={css.appleVisionWrapper}>
          <div className={`${css.appleVisionContainer} px-4 py-3 bg-white`}>
            <div className={css.appleVisionContent}>
              <h1 className={`${css.appleVisionHeading} fw-bold`}>
                Rent <span className={css.appleVisionColor}>Ladders</span> in{' '}
                <span className={css.appleVisionColor}>Brooklyn, NY</span>
              </h1>
              <p className={css.appleVisionParagraph}>
                Ladder rentals in Brooklyn, NY door delivered on demand. Rent
                tools & equipment, electronics, and more on the Runtize rental
                marketplace.
              </p>
              <div className={classNames(css.appleSearchBarWrapper)}>
                {children}
                <div className="d-flex justify-content-end align-items-center">
                  {toggleSecondaryFiltersOpenButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isGeneratorPage && (
        <div className={css.appleVisionWrapper}>
          <div className={`${css.appleVisionContainer} px-4 py-3 bg-white`}>
            <div className={css.appleVisionContent}>
              <h1 className={`${css.appleVisionHeading} fw-bold`}>
                Rent <span className={css.appleVisionColor}>Generators</span> in{' '}
                <span className={css.appleVisionColor}>Brooklyn, NY</span>
              </h1>
              <p className={css.appleVisionParagraph}>
                Generator rentals in Brooklyn, NY door delivered on demand. Rent
                tools & equipment, electronics, and more on the Runtize rental
                marketplace.
              </p>
              <div className={classNames(css.appleSearchBarWrapper)}>
                {children}
                <div className="d-flex justify-content-end align-items-center">
                  {toggleSecondaryFiltersOpenButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isPalletJackPage && (
        <div className={css.appleVisionWrapper}>
          <div className={`${css.appleVisionContainer} px-4 py-3 bg-white`}>
            <div className={css.appleVisionContent}>
              <h1 className={`${css.appleVisionHeading} fw-bold`}>
                Rent <span className={css.appleVisionColor}>Pallet Jacks</span>{' '}
                in <span className={css.appleVisionColor}>Brooklyn, NY</span>
              </h1>
              <p className={css.appleVisionParagraph}>
                Pallet Jack rentals in Brooklyn, NY door delivered on demand.
                Rent tools & equipment, electronics, and more on the Runtize
                rental marketplace.
              </p>
              <div className={classNames(css.appleSearchBarWrapper)}>
                {children}
                <div className="d-flex justify-content-end align-items-center">
                  {toggleSecondaryFiltersOpenButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isPaintSprayerPage && (
        <div className={css.appleVisionWrapper}>
          <div className={`${css.appleVisionContainer} px-4 py-3 bg-white`}>
            <div className={css.appleVisionContent}>
              <h1 className={`${css.appleVisionHeading} fw-bold`}>
                Rent{' '}
                <span className={css.appleVisionColor}>Paint Sprayers</span> in{' '}
                <span className={css.appleVisionColor}>Brooklyn, NY</span>
              </h1>
              <p className={css.appleVisionParagraph}>
                Paint Sprayer rentals in Brooklyn, NY door delivered on demand.
                Rent tools & equipment, electronics, and more on the Runtize
                rental marketplace.
              </p>
              <div className={classNames(css.appleSearchBarWrapper)}>
                {children}
                <div className="d-flex justify-content-end align-items-center">
                  {toggleSecondaryFiltersOpenButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isJackHammerPage && (
        <div className={css.appleVisionWrapper}>
          <div className={`${css.appleVisionContainer} px-4 py-3 bg-white`}>
            <div className={css.appleVisionContent}>
              <h1 className={`${css.appleVisionHeading} fw-bold`}>
                Rent <span className={css.appleVisionColor}>Jack Hammers</span>{' '}
                in <span className={css.appleVisionColor}>Brooklyn, NY</span>
              </h1>
              <p className={css.appleVisionParagraph}>
                Jack Hammer rentals in Brooklyn, NY door delivered on demand.
                Rent tools & equipment, electronics, and more on the Runtize
                rental marketplace.
              </p>
              <div className={classNames(css.appleSearchBarWrapper)}>
                {children}
                <div className="d-flex justify-content-end align-items-center">
                  {toggleSecondaryFiltersOpenButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isAirCompressorPage && (
        <div className={css.appleVisionWrapper}>
          <div className={`${css.appleVisionContainer} px-4 py-3 bg-white`}>
            <div className={css.appleVisionContent}>
              <h1 className={`${css.appleVisionHeading} fw-bold`}>
                Rent{' '}
                <span className={css.appleVisionColor}>Air Compressors</span> in{' '}
                <span className={css.appleVisionColor}>Brooklyn, NY</span>
              </h1>
              <p className={css.appleVisionParagraph}>
                Air Compressor rentals in Brooklyn, NY door delivered on demand.
                Rent tools & equipment, electronics, and more on the Runtize
                rental marketplace.
              </p>
              <div className={classNames(css.appleSearchBarWrapper)}>
                {children}
                <div className="d-flex justify-content-end align-items-center">
                  {toggleSecondaryFiltersOpenButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isHandTruckPage && (
        <div className={css.appleVisionWrapper}>
          <div className={`${css.appleVisionContainer} px-4 py-3 bg-white`}>
            <div className={css.appleVisionContent}>
              <h1 className={`${css.appleVisionHeading} fw-bold`}>
                Rent <span className={css.appleVisionColor}>Hand Trucks</span>{' '}
                in <span className={css.appleVisionColor}>Brooklyn, NY</span>
              </h1>
              <p className={css.appleVisionParagraph}>
                Hand Truck rentals in Brooklyn, NY door delivered on demand.
                Rent tools & equipment, electronics, and more on the Runtize
                rental marketplace.
              </p>
              <div className={classNames(css.appleSearchBarWrapper)}>
                {children}
                <div className="d-flex justify-content-end align-items-center">
                  {toggleSecondaryFiltersOpenButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isWelderPage && (
        <div className={css.appleVisionWrapper}>
          <div className={`${css.appleVisionContainer} px-4 py-3 bg-white`}>
            <div className={css.appleVisionContent}>
              <h1 className={`${css.appleVisionHeading} fw-bold`}>
                Rent <span className={css.appleVisionColor}>Welders</span> in{' '}
                <span className={css.appleVisionColor}>Brooklyn, NY</span>
              </h1>
              <p className={css.appleVisionParagraph}>
                Welder rentals in Brooklyn, NY door delivered on demand. Rent
                tools & equipment, electronics, and more on the Runtize rental
                marketplace.
              </p>
              <div className={classNames(css.appleSearchBarWrapper)}>
                {children}
                <div className="d-flex justify-content-end align-items-center">
                  {toggleSecondaryFiltersOpenButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isNailGunPage && (
        <div className={css.appleVisionWrapper}>
          <div className={`${css.appleVisionContainer} px-4 py-3 bg-white`}>
            <div className={css.appleVisionContent}>
              <h1 className={`${css.appleVisionHeading} fw-bold`}>
                Rent <span className={css.appleVisionColor}>Nail Guns</span> in{' '}
                <span className={css.appleVisionColor}>Brooklyn, NY</span>
              </h1>
              <p className={css.appleVisionParagraph}>
                Nail Gun rentals in Brooklyn, NY door delivered on demand. Rent
                tools & equipment, electronics, and more on the Runtize rental
                marketplace.
              </p>
              <div className={classNames(css.appleSearchBarWrapper)}>
                {children}
                <div className="d-flex justify-content-end align-items-center">
                  {toggleSecondaryFiltersOpenButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLandingPage ? null : (
        <div
          className={classNames(css.searchBarWrapper, css.searchBarWrapper2)}
        >
          <div className={css.inputMain}>
            {isSearchMapOpenOnMobile && showListState && (
              <div
                className={
                  isAppleVisionPage ||
                  isPressureWasherPage ||
                  isToolPage ||
                  isMopedPage ||
                  isBikePage ||
                  isCarpetCleanerPage ||
                  isLadderPage ||
                  isGeneratorPage ||
                  isPalletJackPage ||
                  isPaintSprayerPage ||
                  isJackHammerPage ||
                  isAirCompressorPage ||
                  isHandTruckPage ||
                  isWelderPage ||
                  isNailGunPage
                    ? css.inputMainApple
                    : css.inputMain2
                }
              >
                <div
                  className={css.mobileFilterBack}
                  onClick={() => showLists()}
                >
                  <IconCollection name="SEARCH_BAR_BACK" />
                </div>
                <div className={css.inputWrapperMain}>
                  {children}
                  {toggleSecondaryFiltersOpenButton}
                  {/* {currentPath !== '/rent-apple-vision-pro' &&
                    currentPath !== '/pressure-washer-rental/brooklyn-ny' && (
                      <IconCollection name="SEARCH_FILTER_ICON" />
                    )} */}
                  <IconCollection name="SEARCH_FILTER_ICON" />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {!isAppleVisionPage &&
        !isPressureWasherPage &&
        !isToolPage &&
        !isMopedPage &&
        !isBikePage &&
        !isCarpetCleanerPage &&
        !isLadderPage &&
        !isGeneratorPage &&
        !isPalletJackPage &&
        !isPaintSprayerPage &&
        !isJackHammerPage &&
        !isAirCompressorPage &&
        !isHandTruckPage &&
        !isWelderPage &&
        !isNailGunPage && (
          <div className={css.searchOptions}>
            {listingsAreLoaded && !isLandingPage ? (
              <div className={css.searchResultSummary}>
                <div>
                  <span className={css.resultText}>
                    <FormattedMessage id="SearchFiltersPrimary.result" />
                  </span>
                  <span className={css.resultsFound}>
                    <FormattedMessage
                      id="SearchFiltersPrimary.foundResults"
                      values={{ count: resultsCount }}
                    />
                  </span>
                </div>
                {showListState ? (
                  <div className={css.listButton} onClick={() => showLists()}>
                    <IconCollection name="LIST_MAP_ICON_DESKTOP" />
                    <p>
                      {' '}
                      <FormattedMessage id="SearchPage.list" />
                    </p>
                  </div>
                ) : (
                  <div className={css.listButton} onClick={() => showMap()}>
                    <IconCollection name="WORLD_MAP" />
                    <p>
                      {' '}
                      <FormattedMessage id="SearchPage.map" />
                    </p>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        )}

      {/* {hasNoResult ? (
        <div className={css.noSearchResults}>
          <FormattedMessage id="SearchFiltersPrimary.noResults" />
        </div>
      ) : null} */}

      {searchInProgress ? (
        <div className={css.loadingResults}>
          <FormattedMessage id="SearchFiltersPrimary.loadingResults" />
        </div>
      ) : null}
    </>
  );
};

SearchFiltersPrimaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
  sortByComponent: null,
  urlQueryParams: null,
};

SearchFiltersPrimaryComponent.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
  sortByComponent: node,
  urlQueryParams: string,
};

const SearchFiltersPrimary = SearchFiltersPrimaryComponent;

export default SearchFiltersPrimary;
