import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { Button, NamedLink, IconAmenities } from '../../components';
import { userType } from '../../marketplace-custom-config';

import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { renterData, lenderData } = props;
  const { rootClassName, className } = props;

  const [activeTab, setTab] = useState(userType.RENTER);
  const classes = classNames(rootClassName || css.root, className);
  const tabData = activeTab === userType.RENTER ? renterData : lenderData;

  return (
    <div className={classes}>
      <div className={css.sectionContainer}>
        <div className={css.title}>
          <FormattedMessage id="SectionHowItWorks.howItWorks" />
          <br />
        </div>

        <div className={css.steps}>
          <Button
            className={classNames(css.menuItem, {
              [css.active]: activeTab === userType.RENTER,
            })}
            onClick={() => {
              setTab(userType.RENTER);
            }}
          >
            <FormattedMessage id="SectionHowItWorks.renter" />
          </Button>
          <Button
            className={classNames(css.menuItem, {
              [css.active]: activeTab === userType.LENDER,
            })}
            onClick={() => {
              setTab(userType.LENDER);
            }}
          >
            <FormattedMessage id="SectionHowItWorks.lender" />
          </Button>
        </div>

        <div className={css.cardContent}>
          {Array.isArray(tabData) &&
            tabData.length &&
            tabData.map((ele, i) => {
              return (
                <div key={ele + i}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <IconAmenities type={ele.icon} />
                  </NamedLink>
                  <div>
                    <h2>{ele.name}</h2>
                    <p>{ele.description}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionHowItWorks;
