import classNames from 'classnames';
import React from 'react';

export default function HowItWorks({
  selectedTab = 'renter',
}) {
  const isRenter = selectedTab === 'renter';
  const isLender = selectedTab === 'lender';
  return (
    <section id="how-it-works" className="work__area">
      <div className="container">
        <div className="work__inner__wrap">
          <div className="section__title">
            <h3>How it works</h3>
          </div>

          <div className="work__inner__blk">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  className={classNames("nav-link", isRenter && "active")}
                  id="renter-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#renter"
                  type="button"
                  role="tab"
                  aria-controls="renter"
                  aria-selected={isRenter ? 'true' : 'false'}
                >
                  Renter
                </button>
                <button
                  className={classNames("nav-link", isLender && "active")}
                  id="lender-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#lender"
                  type="button"
                  role="tab"
                  aria-controls="lender"
                  aria-selected={isLender ? 'true' : 'false'}
                >
                  Lender
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className={classNames("tab-pane fade", isRenter && "active show")}
                id="renter"
                role="tabpanel"
                aria-labelledby="renter-tab"
              >
                <div className="row g-4">
                  <div className="col-xl-3 col-md-6">
                    <div className="work__box position-relative">
                      <div className="work__ico">
                        <img
                          src={`${process.env.PUBLIC_URL}/static/assets/img/work_ico_1.svg`}
                          alt=""
                        />
                      </div>
                      <div className="work__content">
                        <h4>Browse</h4>
                        <p>
                          Browse by category or search for a keyword. Filter by
                          price, distance, and date
                        </p>
                      </div>
                      <img
                        className="position-absolute work__shape shape__one d-none d-md-block"
                        src={`${process.env.PUBLIC_URL}/static/assets/img/how-it-work-shape-1.svg`}
                        alt="shape"
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="work__box position-relative">
                      <div className="work__ico">
                        <img
                          src={`${process.env.PUBLIC_URL}/static/assets/img/work_ico_2.svg`}
                          alt=""
                        />
                      </div>
                      <div className="work__content">
                        <h4>Select</h4>
                        <p>
                          Select an item, read some reviews. Have any questions?
                          Message the owner
                        </p>
                      </div>
                      <img
                        className="position-absolute work__shape shape__two d-none d-xl-block"
                        src={`${process.env.PUBLIC_URL}/static/assets/img/how-it-work-shape-2.svg`}
                        alt="shape"
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="work__box position-relative">
                      <div className="work__ico">
                        <img
                          src={`${process.env.PUBLIC_URL}/static/assets/img/work_ico_3.svg`}
                          alt=""
                        />
                      </div>
                      <div className="work__content">
                        <h4>Book</h4>
                        <p>
                          Pick a date and time. Choose between picking up or
                          getting the item delivered directly to you
                        </p>
                      </div>
                      <img
                        className="position-absolute work__shape shape__three d-none d-md-block"
                        src={`${process.env.PUBLIC_URL}/static/assets/img/how-it-work-shape-3.svg`}
                        alt="shape"
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="work__box">
                      <div className="work__ico">
                        <img
                          src={`${process.env.PUBLIC_URL}/static/assets/img/work_ico_4.svg`}
                          alt=""
                        />
                      </div>
                      <div className="work__content">
                        <h4>Experience</h4>
                        <p>
                          Ready set go. Get to experience what Runtize is all
                          about
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames("tab-pane fade", isLender && "active show")}
                id="lender"
                role="tabpanel"
                aria-labelledby="lender-tab"
              >
                <div className="row g-4">
                  <div className="col-xl-3 col-md-6">
                    <div className="work__box position-relative">
                      <div className="work__ico">
                        <img
                          src={`${process.env.PUBLIC_URL}/static/assets/img/work-icon-5.svg`}
                          alt=""
                        />
                      </div>
                      <div className="work__content">
                        <h4>List</h4>
                        <p>
                          Take pictures, set a price, enter your payout details,
                          and list your stuff
                        </p>
                      </div>
                      <img
                        className="position-absolute work__shape shape__one d-none d-md-block"
                        src={`${process.env.PUBLIC_URL}/static/assets/img/how-it-work-shape-1.svg`}
                        alt="shape"
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="work__box position-relative">
                      <div className="work__ico">
                        <img
                          src={`${process.env.PUBLIC_URL}/static/assets/img/work-icon-6.svg`}
                          alt=""
                        />
                      </div>
                      <div className="work__content">
                        <h4>Manage</h4>
                        <p>
                          We'll notify you as soon as you get a booking request.
                          Review the renter's profile, and accept or decline the
                          request at your discretion
                        </p>
                      </div>
                      <img
                        className="position-absolute work__shape shape__two d-none d-xl-block"
                        src={`${process.env.PUBLIC_URL}/static/assets/img/how-it-work-shape-2.svg`}
                        alt="shape"
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="work__box position-relative">
                      <div className="work__ico">
                        <img
                          src={`${process.env.PUBLIC_URL}/static/assets/img/work-icon-7.svg`}
                          alt=""
                        />
                      </div>
                      <div className="work__content">
                        <h4>Facilitate</h4>
                        <p>
                          Meet the renter or delivery courier at your location
                          at the booked time. Utilize the in-app live chat to
                          communicate with the renter if necessary
                        </p>
                      </div>
                      <img
                        className="position-absolute work__shape shape__three d-none d-md-block"
                        src={`${process.env.PUBLIC_URL}/static/assets/img/how-it-work-shape-3.svg`}
                        alt="shape"
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="work__box">
                      <div className="work__ico">
                        <img
                          src={`${process.env.PUBLIC_URL}/static/assets/img/work-icon-8.svg`}
                          alt=""
                        />
                      </div>
                      <div className="work__content">
                        <h4>Earn</h4>
                        <p>
                          Ready, set, go. Let the money roll in and watch your
                          bank account grow
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
