import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import heroBikes from '../../assets/sectionHero/heroBikes.png';
import heroOven from '../../assets/sectionHero/heroOven.png';
import css from './IconHero.module.css';

// landingpage's SectionHowItWorks section  [type is coming from SectionHowItWorks]

const HERO_BIKES = 'heroBikes';
const HERO_OVEN = 'heroOven';

const IconHero = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  switch (type) {
    case HERO_BIKES:
      return (
        <img src={heroBikes} />
      );
    case HERO_OVEN:
      return (
        <img src={heroOven} />
      );
    default:
      return (
        <svg
          className={classes}
          width="29"
          height="19"
          viewBox="0 0 29 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <path
              d="M26.58 19H2.42A2.4004 2.4004 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4004 2.4004 0 0 1 29 2.38v14.25c-.0165 1.3216-1.0984 2.3811-2.42 2.37zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
              fill="#DADDE2"
            />
            <path
              d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
              fill="#B2B6C1"
            />
          </g>
        </svg>
      );
  }
};

IconHero.defaultProps = {
  className: null,
  rootClassName: null,
  brand: 'default',
};

IconHero.propTypes = {
  className: string,
  rootClassName: string,
  brand: string,
};

export default IconHero;
