import React from 'react';
import { TRANSITION_CANCEL, TRANSITION_CANCEL_BY_CUSTOMER_AFTER_ACCEPT, TRANSITION_CANCEL_BY_CUSTOMER_BEFORE_ACCEPT, TRANSITION_CANCEL_BY_PROVIDER_AFTER_ACCEPT } from '../../util/transaction';
import ListingCardInbox from '../ListingCardInbox/ListingCardInbox';
import css from './PastListItem.module.css';

const PastListItem = props => {
  const { transaction, params, Booking, currentUser } = props;
  return (
    <div className={css.sectionContainer}>
      <div className={css.container}>
        <div className={css.titleWrapper}>
        </div>
        <div className={css.imageContent}>
          {transaction &&
            transaction?.length > 0 &&
            transaction?.filter((e) => e.attributes.lastTransition == "transition/decline" ||
              e.attributes.lastTransition == "transition/complete" ||
              e.attributes.lastTransition == "transition/review-2-by-provider" ||
              e.attributes.lastTransition == "transition/review-1-by-provider" ||
              e.attributes.lastTransition == "transition/review-2-by-customer" ||
              e.attributes.lastTransition == "transition/review-1-by-customer" ||
              e.attributes.lastTransition == TRANSITION_CANCEL_BY_CUSTOMER_AFTER_ACCEPT ||
              e.attributes.lastTransition == TRANSITION_CANCEL_BY_PROVIDER_AFTER_ACCEPT ||
              e.attributes.lastTransition == TRANSITION_CANCEL ||
              e.attributes.lastTransition == TRANSITION_CANCEL_BY_CUSTOMER_BEFORE_ACCEPT).map((l, i) => (
                <div className={css.productContent}>
                  <ListingCardInbox
                    key={(l.id.uuid) + i}
                    transaction={l}
                    params={params}
                    Booking={Booking}
                    currentUser={currentUser}
                  />
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default PastListItem;
