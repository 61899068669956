import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';

import {
  Form,
  LocationAutocompleteInput,
  FieldTextInput,
  Button,
  IconAmenities,
  IconCollection,
  NamedLink,
} from '../../components';

import css from './TopbarSearchForm.module.css';

const identity = v => v;

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.searchInput = null;
  }

  render() {
    // const { getLocation } = this.props
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            location,
            getLocation,
            handleSubmit,
            landingHero,
            categoryPage,
            useSearchIcon,
          } = formRenderProps;

          let searchInputPlaceholder;

          let searchInputPlaceholderAfter = intl.formatMessage({
            id: 'TopbarSearchForm.searchInputPlaceholderAfter',
          });

          if (getLocation && getLocation.pathname === '/support-page') {
            searchInputPlaceholder = intl.formatMessage({
              id: 'TopbarSearchForm.searchInputPlaceholderForSupportPage',
            });
          } else {
            searchInputPlaceholder = intl.formatMessage({
              id: 'TopbarSearchForm.searchInputPlaceholder',
            });
          }

          const classes = classNames(rootClassName, className, categoryPage && css.categoryPage);
          const desktopInputRootClass =
            desktopInputRoot || css.desktopInputRoot;

          // Allow form submit only when the place has changed
          // const preventFormSubmit = e => e.preventDefault();

          return (
            <Form
              className={classes}
              onSubmit={handleSubmit}
              enforcePagePreloadFor="SearchPage"
            >
              {landingHero ? (
                <Field
                  id="keywords"
                  name="keywords"
                  format={identity}
                  render={({ input, meta }) => {
                    const { onChange, ...restInput } = input;
                    const searchOnChange = value => {
                      value = value.target.value;
                      return onChange(value);
                    };
                    const searchInput = {
                      ...restInput,
                      onChange: searchOnChange,
                    };

                    return (
                      <>
                        <FieldTextInput
                          id="landingSearch"
                          name="landingSearch"
                          type={"text"}
                          placeholder={searchInputPlaceholder}
                          input={searchInput}
                          meta={meta}
                        />
                        <Button
                          className={css.iconSearchBtn}
                          type={
                            searchInput && searchInput.value
                              ? 'submit'
                              : 'button'
                          }
                        >
                          {useSearchIcon ? (
                            <IconCollection name="ICON_SEARCH" />
                          ) : (
                            <>
                              <FormattedMessage id="TopbarSearchForm.searchButton" />
                              <IconCollection name="SEARCH_BUTTON" />
                            </>
                          )}

                        </Button>
                      </>
                    );
                  }}
                />
              ) : (
                <div className={css.inputWrapper}>
                  <Field
                    id="keywords"
                    name="keywords"
                    format={identity}
                    render={({ input, meta }) => {
                      const { onChange, ...restInput } = input;
                      const searchOnChange = value => {
                        value = value.target.value;
                        return onChange(value);
                      };
                      const searchInput = {
                        ...restInput,
                        type: "text",
                        onChange: searchOnChange,
                      };

                      return (
                        <>
                          <FieldTextInput
                            id="landingSearch"
                            name="landingSearch"
                            type="text"
                            placeholder={searchInputPlaceholderAfter}
                            input={searchInput}
                            meta={meta}
                          />
                          <Button
                            className={css.iconSearchBtn}
                            type={
                              searchInput && searchInput.value
                                ? 'submit'
                                : 'button'
                            }
                          >
                            <IconCollection name="ICON_SEARCH" />
                          </Button>
                        </>
                      );
                    }}
                  />

                  <NamedLink name="SearchPage">
                    <IconCollection name='ICON_CALENDAR' />
                  </NamedLink>
                  <NamedLink name="SearchPage">
                    <IconCollection name='ICON_LOCATION' />
                  </NamedLink>
                  <NamedLink name="SearchPage">
                    <IconCollection name='ICON_SETTINGS' />
                  </NamedLink>
                </div>
              )}

            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
