import React from 'react';
import css from './ItemCard.module.css';
import IconCollection from '../IconCollection/IconCollection';

const ItemCard = (props) => {
  const {img, miles, title, days} = props
  return (
    <div className={css.cardWrapper}>
      <div className={css.cardImage}>
        <img src={img} alt="" />
      </div>
      <div className={css.cardContent}>
        <div>
          <p>{miles}</p>
        </div>
        <div className={css.cardTitle}>
          <h5>{title}</h5>
          <h6>{days}</h6>
        </div>
        <div className={css.cardDescription}>
          <IconCollection name="CARD_DESCRIPTION" />
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
