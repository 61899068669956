import React from 'react';
import css from './ListingAvatar.module.css';
import {
  AvatarMedium,
  IconCollection,
  InlineTextButton,
} from '../../components';

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

const ListingAvatar = props => {
  const {
    currentAuthor,
    listingCertificate,
    certificateOptions,
    showContactUser,
    averageReview,
    reviewCount,
  } = props;
  const userImage = <AvatarMedium user={currentAuthor} />;
  const displayName =
    currentAuthor &&
    currentAuthor.attributes &&
    currentAuthor.attributes?.profile?.displayName;
  const Bio =
    currentAuthor &&
    currentAuthor.attributes &&
    currentAuthor.attributes?.profile?.bio;
  const certificate = getCertificateInfo(
    certificateOptions,
    listingCertificate
  );
  const showCertificate = certificate && !certificate.hideFromListingInfo;

  const onContactUser = () => {
    window.location.href = `/m/${currentAuthor.id.uuid}`;
  };

  return (
    <>
      <div className={css.profileContent}>
        <div className={css.profileContentLeft}>
          <div className={css.mobileAvatar}>
            <h6 className={css.ownedByMobile}>Owned By: </h6>
            {userImage}
          </div>
          <div className={css.ownerContent}>
            <h6 className={css.ownedBy}>Owned By: </h6>
            <p>{displayName}</p>
            <div className={css.locationContent}>
              <IconCollection name="RATING_STAR" />
              <h6 className={css.ratingText}>
                {averageReview} ({reviewCount})
              </h6>
            </div>
          </div>
        </div>
        <div className={css.listingMessage}>
          <div className={css.author}>
            {showCertificate ? <span>{certificate.label}</span> : null}
            {showContactUser ? (
              <span className={css.contactWrapper}>
                {showCertificate ? (
                  <span className={css.separator}>•</span>
                ) : null}
                <InlineTextButton
                  rootClassName={css.contactLink}
                  onClick={onContactUser}
                  enforcePagePreloadFor="SignupPage"
                >
                  <IconCollection name="LISTING_MESSAGE" />
                </InlineTextButton>
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default ListingAvatar;
