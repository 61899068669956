import React from 'react'
import css from './EarnTotal.module.css';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types'
import { month } from '../../../marketplace-custom-config';
import moment from 'moment';


const EarnTotal = (props) => {
    const { completeTransaction, TotalAmount } = props

    const newArray = month.map((st) => {
        const monthArray = (completeTransaction && completeTransaction.length > 0 && completeTransaction.filter((item) => st == moment(item?.attributes?.lastTransitionedAt).format('MMMM'))) || [];
        if (monthArray.length) {
            const amount = completeTransaction.reduce((acc, curr) => acc + curr.attributes?.payinTotal?.amount, 0);
            return { month: st, rentals: monthArray.length, amount: amount, details: completeTransaction }
        }
        else {
            return { month: st, rentals: 0, amount: 0, details: "" }
        }
    })


    return (
        <>
            <div className={css.Topheading}>
                <div className={css.topHeadingName}>
                    <h2>
                        <FormattedMessage id="DashboardPage.EarnTotal" />
                    </h2>
                </div>
                <div className={css.textAmount}>
                    <p>
                        Total Amount Earned : <span className={css.totalAmount}>${TotalAmount / 100}</span>
                    </p>
                </div>
            </div>
            <div className={css.amountTable}>
                <table>
                    <thead>
                        <tr>
                            <th>Month</th>
                            <th>Rentals</th>
                            <th>Amount</th>
                            {/* <th>Details</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {newArray && newArray.length > 0 &&
                            newArray.map((item) => {
                                return (<>
                                    <tr>
                                        <td>{item.month}</td>
                                        <td>{item.rentals}</td>
                                        <td>${item.amount / 100}</td>
                                        {/* <td>view</td> */}
                                    </tr>
                                </>)
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}



EarnTotal.propTypes = {
    completeTransaction: PropTypes.array,
    TotalAmount: PropTypes.string

}

export default EarnTotal

