import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, NamedLink } from '../../components';
import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        onPrevious,
        isSixHours,
        handleToggleState,
        handleToggle2State,
        isMoreDays
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
          ? 'EditListingPricingForm.pricePerDay'
          : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(
        config.listingMinimumPriceSubUnits,
        config.currency
      );
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInput}
            label={pricePerUnitMessage}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />
          <p>
            <span className={css.listDot}></span>
            <FormattedMessage id="EditListingPricingForm.priceTextMessage1" />
          </p>
         <div className={css.hourRentals}>
         <h2 className={css.rentalsText}> <FormattedMessage id="EditListingPricingForm.sixHourText" values={{optional:<span className={css.optionalText}>(optional)</span>}} /></h2>
          <div className={css.checkboxWrapper}
            onClick={() => {
              handleToggleState()
            }}
          >
            <div
              className={classNames(css.ball, {
                [css.toggled]: isSixHours,
              })}
            >
              {' '}
            </div>
          </div>
         </div>
          <p>
            <span className={css.listDot}></span>
            <FormattedMessage id="EditListingPricingForm.sixHoursTextMessage1" />
          </p>
          <div className={css.dayRentals}>
          <h2 className={css.rentalsText}> <FormattedMessage id="EditListingPricingForm.threeDaysText" values={{optional:<span className={css.optionalText}>(optional)</span>}} /></h2>
          <div className={css.checkboxWrapper}
            onClick={() => {
              handleToggle2State()
            }}
          >
            <div
              className={classNames(css.ball, {
                [css.toggled]: isMoreDays,
              })}
            >
              {' '}
            </div>
          </div>
          </div>
          <p>
            <span className={css.listDot}></span>
            <FormattedMessage id="EditListingPricingForm.threeDaysTextMessage1" />
          </p>
          
          <h2 className={css.textWrapper} onClick={() => window.fcWidget.open()}>
            <FormattedMessage id="EditListingPricingForm.priceTextMessage3"
              values={{
                liveChat: <FormattedMessage id='EditListingPricingForm.priceTextMessageLiveChat' />
              }} />
          </h2>
          <div className={classNames(css.bottomButtons, css.buttonContent)}>
            <Button
              className={css.submitButton}
              type="button"
              onClick={onPrevious} >
              <FormattedMessage id="EditListingDescriptionForm.backButtonText" />
            </Button>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
