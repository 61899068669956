import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { array, string, func } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';
import css from './ListingCardInbox.module.css';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL_BY_CUSTOMER_BEFORE_ACCEPT,
  TRANSITION_COMPLETE,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING,
  TRANSITION_DECLINE,
  TRANSITION_ENQUIRE,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER
} from '../../util/transaction';
import moment from 'moment';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

export const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);

    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} alt={"test"} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, {
  loadAfterInitialRendering: 3000,
});

export const ListingCardComponent = (props) => {
  const {
    className,
    rootClassName,
    intl,
    transaction,
    renderSizes,
    filtersConfig,
    setActiveListing,
    currentUser,
  } = props;
  const [difference, setDiffernce] = useState({});
  const classes = classNames(rootClassName || css.root, className);
  const currentTransaction = ensureListing(transaction);
  const { selectedTime, selectedPrice, diffBetweenDays } = currentTransaction && currentTransaction.attributes?.metadata?.bookingData || {};
  const paymentStatus = currentTransaction?.booking?.attributes?.state;
  const payinTotal = currentTransaction?.attributes?.payinTotal;
  const payoutTotal = currentTransaction?.attributes?.payoutTotal;
  const isEnquiryState = currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_ENQUIRE;
  const isInstant = currentTransaction?.listing?.attributes?.publicData?.instantBooking
  const isCompleteState = currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_COMPLETE ||
    currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_REVIEW_2_BY_PROVIDER || currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_REVIEW_1_BY_PROVIDER || currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_REVIEW_2_BY_CUSTOMER || currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_REVIEW_1_BY_CUSTOMER ||
    currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_EXPIRE_REVIEW_PERIOD ||
    currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD ||
    currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD;

  const isRivew1ProState = currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_REVIEW_1_BY_PROVIDER
  const isRivew2ProState = currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_REVIEW_2_BY_PROVIDER
  const isRivew1CusState = currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_REVIEW_1_BY_CUSTOMER
  const isRivew2CusState = currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_REVIEW_2_BY_CUSTOMER

  const id = currentTransaction.id.uuid;
  const { title = '', price, publicData } = currentTransaction?.listing?.attributes;
  const firstImage =
    currentTransaction?.listing?.images &&
      currentTransaction?.listing?.images.length > 0
      ? currentTransaction?.listing?.images[0]
      : null;
  const { priceTitle } = priceData(price, intl);
  const { formattedPrice: formattedPayinTotal } = priceData(payinTotal, intl);
  const { formattedPrice: formattedPayoutTotal } = priceData(payoutTotal, intl);


  useEffect(() => {
    const { bookingStart, bookingEnd } = currentTransaction?.attributes?.protectedData?.bookingData || {};
    if (currentTransaction.attributes.lastTransition != "transition/enquire") {
      Object.assign(currentTransaction?.booking?.attributes, {
        start: bookingStart ? moment(bookingStart).toDate() : currentTransaction?.booking?.attributes?.start,
        end: bookingEnd ? moment(bookingEnd).toDate() : currentTransaction?.booking?.attributes?.end,
        displayEnd: bookingEnd ? moment(bookingEnd).toDate() : currentTransaction?.booking?.attributes?.end,
        displayStart: bookingStart ? moment(bookingStart).toDate() : currentTransaction?.booking?.attributes?.start,
      })
    }

    const { start, end } = currentTransaction?.booking?.attributes || {};
    const currentDate = moment();
    if (currentDate.isBefore(start)) {
      const timeDifference = Math.abs(start - currentDate); // Difference in milliseconds

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setDiffernce({
        days,
        hours,
        minutes,
        seconds,
      });
    }
    else {
      setDiffernce({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
    }
  }, [!currentTransaction.attributes.lastTransition == "transition/enquire"])

  const userRole = currentTransaction?.customer?.id?.uuid === currentUser?.id?.uuid;
  const { days, hours: hour, minutes: mint, seconds } = difference || {};
  return (
    <NamedLink
      className={classes}
      name={userRole ? 'OrderDetailsPage' : 'SaleDetailsPage'}
      params={{ id: id }}
    >
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentTransaction.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={classNames(css.info, css.infoDesktop)}>
        <div className={css.mainInfo}>
          <h4 className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </h4>
        </div>
        <div className={css.price}>
          {
            isEnquiryState ?
              null
              :
              <div className={css.priceContent}>
                <h5 className={css.priceValue} title={priceTitle}>
                  <h5 style={{ color: '#000', display: 'inline' }}>Total:</h5> {userRole ? formattedPayinTotal : formattedPayoutTotal}
                </h5>
              </div>
          }
          {
            paymentStatus == 'pending' ? (
              <p className={classNames(css.statusPending, css.status)}>Pending</p>
            )
              :
              null
          }
          {
            currentTransaction &&
              (currentTransaction.attributes.lastTransition == TRANSITION_ACCEPT) &&
              (days == 0 && hour == 0 && mint == 0 && seconds == 0) && !isCompleteState ||

              (isInstant && currentTransaction.attributes.lastTransition == TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING) && paymentStatus == 'accepted' &&
              (days == 0 && hour == 0 && mint == 0 && seconds == 0) && !isCompleteState ||

              ((days == 0 && hour == 0 && mint == 0 && seconds == 0) && paymentStatus == 'accepted' && currentTransaction.attributes.lastTransition != 'transition/complete') && !isCompleteState && paymentStatus != 'declined' && paymentStatus != "cancelled"
              ? (
                <p className={classNames(css.statusInProgress, css.status)}>
                  In progress
                </p>
              )
              :

              paymentStatus == "accepted" &&
                (days > 0 || hour > 0 || mint > 0 || seconds > 0) && !isCompleteState ? (
                <p className={classNames(css.statusUpcoming, css.status)}>
                  Upcoming
                </p>
              )
                : paymentStatus == 'accepted' && isCompleteState ? (
                  <p className={classNames(css.statusCompeleted, css.status)}>
                    Compeleted
                  </p>
                )
                  : paymentStatus == 'accepted' && (isRivew1ProState || isRivew2ProState || isRivew1CusState || isRivew2CusState) ? (
                    <p className={classNames(css.statusReview, css.status)}>
                      Reviewed
                    </p>
                  )
                    :
                    null
          }
          {
            paymentStatus == 'declined' &&
              currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_DECLINE ? (
              <p className={classNames(css.statusDeclined, css.status)}>
                Declined
              </p>
            )
              :
              null
          }
          {
            paymentStatus == "cancelled" ||
              currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_CANCEL_BY_CUSTOMER_BEFORE_ACCEPT ? (
              <p className={classNames(css.statusDeclined, css.status)}>
                Cancelled
              </p>
            )
              :
              null
          }
          {isEnquiryState ? (
            <p className={classNames(css.statusPending, css.status)}>
              Enquiry
            </p>
          )
            :
            null
          }
        </div>
      </div>
      <div className={classNames(css.info, css.infoMobile)}>
        <div className={css.titleContainer}>
          <div className={css.mainInfo}>
            <h4 className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </h4>
          </div>
          <div className={css.price}>
            {
              isEnquiryState ?
                null
                :
                <div className={css.priceContent}>
                  <h5 className={css.priceValue} title={priceTitle}>
                    <h5 style={{ color: '#000',  display: 'inline' }}>Total:</h5> {userRole ? formattedPayinTotal : formattedPayoutTotal}
                  </h5>
                </div>
            }
          </div>
        </div>
        <div>
          {
            paymentStatus == 'pending' ? (
              <p className={classNames(css.statusPending, css.status)}>Pending</p>
            )
              :
              null
          }
          {currentTransaction &&
            (currentTransaction.attributes.lastTransition == TRANSITION_ACCEPT) &&
            (days == 0 && hour == 0 && mint == 0 && seconds == 0) && !isCompleteState ||

            (isInstant && currentTransaction.attributes.lastTransition == TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING) && paymentStatus == 'accepted' &&
            (days == 0 && hour == 0 && mint == 0 && seconds == 0) && !isCompleteState ||

            ((days == 0 && hour == 0 && mint == 0 && seconds == 0) && paymentStatus == 'accepted' && currentTransaction.attributes.lastTransition != 'transition/complete') && !isCompleteState && paymentStatus != 'declined' && paymentStatus != "cancelled"
            ? (
              <p className={classNames(css.statusInProgress, css.status)}>
                In progress
              </p>
            )
            :

            paymentStatus == "accepted" &&
              (days > 0 || hour > 0 || mint > 0 || seconds > 0) && !isCompleteState ? (
              <p className={classNames(css.statusUpcoming, css.status)}>
                Upcoming
              </p>
            )
              : paymentStatus == 'accepted' && isCompleteState ? (
                <p className={classNames(css.statusCompeleted, css.status)}>
                  Compeleted
                </p>
              )
                : paymentStatus == 'accepted'
                  && (isRivew1ProState || isRivew2ProState || isRivew1CusState || isRivew2CusState) ? (
                  <p className={classNames(css.statusReview, css.status)}>
                    Reviewed
                  </p>
                )
                  :
                  null
          }
          {
            paymentStatus == 'declined' &&
              currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_DECLINE ? (
              <p className={classNames(css.statusDeclined, css.status)}>
                Declined
              </p>
            )
              :
              null
          }
          {
            paymentStatus == "cancelled" ||
              currentTransaction && currentTransaction.attributes.lastTransition == TRANSITION_CANCEL_BY_CUSTOMER_BEFORE_ACCEPT ? (
              <p className={classNames(css.statusDeclined, css.status)}>
                Cancelled
              </p>
            )
              :
              null
          }
          {isEnquiryState ? (
            <p className={classNames(css.statusPending, css.status)}>
              Enquiry
            </p>
          )
            :
            null
          }
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,
  setActiveListing: func,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  return {
    currentUser,
    isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  bookmarkedData: (id) => dispatch(updateProfile(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  injectIntl
)(ListingCardComponent);
