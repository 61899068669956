import React, { Component, useState } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingPhotosForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { IconCollection, ListingLink, Modal } from '../../components';

import css from './EditListingPhotosPanel.module.css';

class EditListingPhotosPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
    };
    this.openModal2 = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ openModal: true });
  }
  render() {
    const {
      className,
      rootClassName,
      errors,
      disabled,
      ready,
      images,
      listing,
      onPrevious,
      onImageUpload,
      onUpdateImageOrder,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      onChange,
      onSubmit,
      onRemoveImage,
    } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);
    const currentListing = ensureOwnListing(listing);


    const panelTitle = <FormattedMessage id="EditListingPhotosPanel.createListingTitle" />;

    const handleModel = () => {
      this.openModal();
    };

    return (
      <div className={classes}>
     <div className={css.informationContents}>
     <div className={css.titleContent}>
          <h1 className={css.title}>{panelTitle} </h1>
          <div
            className={css.brandStoreIcon}
            onClick={() => handleModel('storeLink')}
          >
         <span className={css.desktopIcon}>   <IconCollection name="BRAND_STORE_INFO" /></span>
         <span className={css.mobileIcon}>   <IconCollection name="STORE_INFO_MOBILE" /></span>
          </div>
        </div>
        <div className={css.dividerLine}></div>
        <EditListingPhotosForm
          className={css.form}
          disabled={disabled}
          onPrevious={onPrevious}
          ready={ready}
          fetchErrors={errors}
          initialValues={{ images }}
          images={images}
          onImageUpload={onImageUpload}
          onSubmit={values => {
            const { addImage, ...updateValues } = values;
            onSubmit(updateValues);
          }}
          onChange={onChange}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={onRemoveImage}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
        />
        <Modal
          isOpen={this.state.openModal}
          addPhoto={true}
          onClose={() => {
            this.setState({ openModal: false });
          }}
          containerClassName={css.modal}
          onManageDisableScrolling={() => {}}
        >
          <div className={css.modalContainer}>
            <div className={css.modalTitle}>
              <IconCollection name="WRITE_ABOUT_INFO" />
            </div>

            <div>
              <h2>
                <strong>
                  <FormattedMessage id="EditListingPhotosPanel.modelTittle" />
                </strong>
              </h2>
              <p>
                <FormattedMessage id="EditListingPhotosPanel.modelText" />
              </p>
            </div>
          </div>
        </Modal>
     </div>
      </div>
    );
  }
}

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  onPrevious: func.onPrevious,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingPhotosPanel;
