import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  NamedLink,
  IconAmenities,
} from '../../components';
import * as validators from '../../util/validators';

import css from './LoginForm.module.css';
import { useHistory } from 'react-router-dom';

const LoginFormComponent = props => {
  const [passwordShown, setPasswordShown] = useState(false);
  const history = useHistory();
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <>
      <FinalForm
        {...props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
          } = fieldRenderProps;
          const onSubmit = values => {
            handleSubmit(values);
          }
          // email
          const emailLabel = intl.formatMessage({
            id: 'LoginForm.emailLabel',
          });
          const emailRequiredMessage = intl.formatMessage({
            id: 'LoginForm.emailRequired',
          });
          const emailRequired = validators.required(emailRequiredMessage);
          const emailInvalidMessage = intl.formatMessage({
            id: 'LoginForm.emailInvalid',
          });
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          // password
          const passwordLabel = intl.formatMessage({
            id: 'LoginForm.passwordLabel',
          });
          const passwordRequiredMessage = intl.formatMessage({
            id: 'LoginForm.passwordRequired',
          });
          const passwordRequired = validators.requiredStringNoTrim(
            passwordRequiredMessage
          );

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;

          const passwordRecoveryLink = (
            <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
              <FormattedMessage id="LoginForm.forgotPassword" />
            </NamedLink>
          );
          const scoreWithOr = <IconAmenities type="orSignUp" />;

          return (
            <Form className={classes} onSubmit={onSubmit}>
              <div>
                <FieldTextInput
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  label={emailLabel}
                  // placeholder={emailPlaceholder}
                  validate={validators.composeValidators(
                    emailRequired,
                    emailValid
                  )}
                />
                <div className={css.passwordContainer}>
                  <FieldTextInput
                    className={css.password}
                    type={passwordShown ? 'text' : 'password'}
                    id={formId ? `${formId}.password` : 'password'}
                    name="password"
                    autoComplete="current-password"
                    label={passwordLabel}
                    // placeholder={passwordPlaceholder}
                    validate={passwordRequired}
                  />
                  {passwordShown ? (
                    <div onClick={togglePassword}>
                      <IconAmenities type="openEye" />
                    </div>
                  ) : (
                    <div onClick={togglePassword}>
                      <IconAmenities type="closeEye" />
                    </div>
                  )}
                </div>
              </div>
              <div className={css.bottomWrapper}>
                <p className={css.bottomWrapperText}>
                  <span className={css.recoveryLinkInfo}>
                    <FormattedMessage
                      id="LoginForm.forgotPasswordInfo"
                      values={{ passwordRecoveryLink }}
                    />
                  </span>
                </p>
                <PrimaryButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="LoginForm.logIn" />
                </PrimaryButton>
                <h6>
                  {scoreWithOr}
                  <FormattedMessage id="LoginForm.or" />
                  {scoreWithOr}
                </h6>
              </div>
            </Form>
          );
        }}
      />
    </>
  );
};

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
