import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, IconCollection, UserDisplayName } from '../../components';
import { propTypes } from '../../util/types';

import moment from 'moment';
import css from './Reviews.module.css';

const Review = props => {
  const { review, intl } = props;
  const date = review.attributes.createdAt;
  const dateString = moment(date).format('DD MMM YYYY, hh:mm a');

  return (
    <div className={css.review}>
      <Avatar className={css.avatar} user={review.author} disableProfileLink />
      <div className={css.reviewContainer}>
        <div className={css.nameContent}>
          <div>
            <UserDisplayName
              className={css.userName}
              user={review.author}
              intl={intl}
            />
          </div>
          <div className={css.starReview}>
            <IconCollection name="RATING_STAR" />
            <h6>{review.attributes.rating}.0</h6>
          </div>
        </div>
        <p className={css.reviewInfo}>{dateString}</p>
        <p className={css.reviewContent}>{review.attributes.content}</p>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl, viewAll } = props;
  const classes = classNames(
    rootClassName || css.root,
    className,
    viewAll && css.viewAll
  );

  const showReviews = reviews?.length;
  return (
    <ul className={classes}>
      {showReviews
        ? reviews.map(r => {
            return (
              <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
                <Review review={r} intl={intl} />
              </li>
            );
          })
        : null}
    </ul>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
