import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';
import config from '../../config';
// ================ Action types ================ //
export const FETCH_LISTINGS_REQUEST = 'app/SupportPage/SEARCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/SupportPage/SEARCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/SupportPage/SEARCH_LISTINGS_ERROR';
// ================ Reducer ================ //
const RESULT_PAGE_SIZE = 24;
const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
};
const resultIds = data => data.data.map(l => l.id);
const supportPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      return {
        ...state,
        searchInProgress: false,
        searchListingsError: payload,
      };

    default:
      return state;
  }
};
export default supportPageReducer;
// ================ Action creators ================ //
export const fetchListingsRequest = searchParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { searchParams },
});
export const fetchListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const fetchListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});
export const fetchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(fetchListingsRequest(searchParams));
  const { perPage, price, dates, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };
  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchListingsError(storableError(e)));
      throw e;
    });
};
export const loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return fetchListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'author.profileImage', 'images'],
    'fields.listing': [
      'title',
      'geolocation',
      'description',
      'price',
      'publicData',
      'availabilityPlan',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': [
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.square-small',
      'variants.square-small2x',
    ],
    'limit.images': 1,
    pub_featured: true,
  });
};
