import React from 'react';
import css from './Earn.module.css';
import classNames from 'classnames';
import image from '../../assets/earnPage/logo.png';
import { IconCollection } from '../../components';
import { Link } from 'react-router-dom';

export default function Banner() {
  return (
    <section className="py-5 px-4 px-md-0">
      <div className="container">
        <div
          className={classNames(
            'rounded-4 py-3 px-lg-5 px-4 fw-medium d-flex justify-content-center',
            css.earnSection
          )}
        >
          <div
            style={{
              lineHeight: '2.3',
            }}
            className={css.textBox}
          >
            Join{' '}
            <span className="d-sm-inline d-none">
              rental businesses, entrepreneurs, students, and
            </span>{' '}
            others confidently listing their items knowing they're covered with
            our $20,000
            {/* <Link to={{ pathname: 'https://covize.ai/' }} target="_blank"> */}
            <a href="https://covize.ai/" target="_blank">
              <img src={image} className={css.imageCovise} />
              <sup
                style={{
                  color: 'black',
                  marginLeft: '-10px',
                }}
              >
                <IconCollection name="BRAND_LINK" />
              </sup>
            </a>
            {/* </Link> */} powered protection guarantee
          </div>
        </div>
      </div>
    </section>
  );
}
