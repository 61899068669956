import React from 'react'

export default function Card({
  title,
  description,
  icon
}) {
  {/* Icon on the left & title & description on the right. No borders & Shadow is needed  The card will be in col-lg-3 */}
  return (
    <div className="col-lg-3 px-lg-4 col-md-6 col-12">
      <div className="d-flex gap-md-2 gap-3">
        <div>
          {icon}
        </div>
        <div>
          <h3 style={{
            fontSize: "1.3rem",
          }} className='mt-0 mb-4 mb-sm-2'>{title}</h3>
          <p style={{
            color: "#4B5574"
          }}>{description}</p>
        </div>
      </div>
    </div>
  )
}
