import React, { useState } from 'react';
import { bool, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  Form,
  InlineTextButton,
  IconClose,
  PrimaryButton,
  FieldSelect,
  FieldTimeZoneSelect,
  FieldCheckbox,
  Button,
  IconCollection,
} from '../../components';

import css from './EditListingAvailabilityPlanForm.module.css';
import { capitalizeFirstLetter } from '../../util/dataExtractor';
import _, { isArray } from 'lodash';
import moment from 'moment';

const FROM = 'from';
const TO = 'to';
const DAYS = 'days';

const printHourStrings = h => (h > 9 ? `${h}:00` : `0${h}:00`);

const HOURS = Array(24).fill();
const ALL_START_HOURS = [...HOURS].map((v, i) => printHourStrings(i));
const ALL_END_HOURS = [...HOURS].map((v, i) => printHourStrings(i));

const filteredStartHours = ALL_START_HOURS.filter((e)=> e!="00:00"&& e!="01:00"&& e!="02:00"&& e!="03:00"&& e!="04:00"&& e!="05:00"&& e!="06:00"&& e!="07:00" )

const filteredEndHours = ALL_END_HOURS.filter((e)=> e!="00:00"&& e!="01:00"&& e!="02:00"&& e!="03:00"&& e!="04:00"&& e!="05:00"&& e!="06:00"&& e!="07:00" )

const sortEntries = (defaultCompareReturn = 0) => (a, b) => {
  if (a.startTime && b.startTime) {
    const aStart = Number.parseInt(a.startTime.split(':')[0]);
    const bStart = Number.parseInt(b.startTime.split(':')[0]);
    return aStart - bStart;
  }
  return defaultCompareReturn;
};

const findEntryFn = entry => e =>
  e.startTime === entry.startTime && e.endTime === entry.endTime;

const filterStartHours = (availableStartHours, values, dayOfWeek, index) => {
  const entries = values[dayOfWeek];
  const currentEntry = entries[index];

  // If there is no end time selected, return all the available start times
  if (!currentEntry.endTime) {
    return availableStartHours;
  }

  // By default the entries are not in order so we need to sort the entries by startTime
  // in order to find out the previous entry
  const sortedEntries = [...entries].sort(sortEntries());

  // Find the index of the current entry from sorted entries
  const currentIndex = sortedEntries.findIndex(findEntryFn(currentEntry));

  // If there is no next entry or the previous entry does not have endTime,
  // return all the available times before current selected end time.
  // Otherwise return all the available start times that are after the previous entry or entries.
  const prevEntry = sortedEntries[currentIndex - 1];
  const pickBefore = time => h => h < time;
  const pickBetween = (start, end) => h => h >= start && h < end;

  return !prevEntry || !prevEntry.endTime
    ? availableStartHours.filter(pickBefore(currentEntry.endTime))
    : availableStartHours.filter(
      pickBetween(prevEntry.endTime, currentEntry.endTime)
    );
};

const filterEndHours = (availableEndHours, values, dayOfWeek, index) => {
  const entries = values[dayOfWeek];
  const currentEntry = entries[index];

  // If there is no start time selected, return an empty array;
  if (!currentEntry.startTime) {
    return [];
  }

  // By default the entries are not in order so we need to sort the entries by startTime
  // in order to find out the allowed start times
  const sortedEntries = [...entries].sort(sortEntries(-1));

  // Find the index of the current entry from sorted entries
  const currentIndex = sortedEntries.findIndex(findEntryFn(currentEntry));

  // If there is no next entry,
  // return all the available end times that are after the start of current entry.
  // Otherwise return all the available end hours between current start time and next entry.
  const nextEntry = sortedEntries[currentIndex + 1];
  const pickAfter = time => h => h > time;
  const pickBetween = (start, end) => h => h > start && h <= end;

  return !nextEntry || !nextEntry.startTime
    ? availableEndHours.filter(pickAfter(currentEntry.startTime))
    : availableEndHours.filter(
      pickBetween(currentEntry.startTime, nextEntry.startTime)
    );
};

const getEntryBoundaries = (
  values,
  dayOfWeek,
  intl,
  findStartHours
) => index => {
  const entries = values[dayOfWeek];
  const boundaryDiff = findStartHours ? 0 : 1;

  return entries.reduce((allHours, entry, i) => {
    const { startTime, endTime } = entry || {};

    if (i !== index && startTime && endTime) {
      const startHour = Number.parseInt(startTime.split(':')[0]);
      const endHour = Number.parseInt(endTime.split(':')[0]);
      const hoursBetween = Array(endHour - startHour)
        .fill()
        .map((v, i) => printHourStrings(startHour + i + boundaryDiff));

      return allHours.concat(hoursBetween);
    }

    return allHours;
  }, []);
};

const DailyPlan = props => {
  const { dayOfWeek, values, intl } = props;
  const getEntryStartTimes = getEntryBoundaries(values, dayOfWeek, intl, true);
  const getEntryEndTimes = getEntryBoundaries(values, dayOfWeek, intl, false);

  const hasEntries = values[dayOfWeek] && values[dayOfWeek][0];

  const startTimePlaceholder = intl.formatMessage({
    id: 'EditListingAvailabilityPlanForm.startTimePlaceholder',
  });
  const endTimePlaceholder = intl.formatMessage({
    id: 'EditListingAvailabilityPlanForm.endTimePlaceholder',
  });

  return (
    <div
      className={classNames(css.weekDay, hasEntries ? css.hasEntries : null)}
    >
      <div className={css.dayOfWeek}>
        <FormattedMessage
          id={`EditListingAvailabilityPlanForm.dayOfWeek.${dayOfWeek}`}
        />
      </div>

      <FieldArray name={dayOfWeek}>
        {({ fields }) => {
          return (
            <div className={css.timePicker}>
              {fields.map((name, index) => {
                // Pick available start hours
                const pickUnreservedStartHours = h =>
                  !getEntryStartTimes(index).includes(h);
                const availableStartHours = ALL_START_HOURS.filter(
                  pickUnreservedStartHours
                );

                // Pick available end hours
                const pickUnreservedEndHours = h =>
                  !getEntryEndTimes(index).includes(h);
                const availableEndHours = ALL_END_HOURS.filter(
                  pickUnreservedEndHours
                );

                return (
                  <div className={css.fieldWrapper} key={name}>
                    <div className={css.formRow}>
                      <div className={css.field}>
                        <FieldSelect
                          id={`${name}.startTime`}
                          name={`${name}.startTime`}
                          selectClassName={css.fieldSelect}
                        >
                          <option disabled value="">
                            {startTimePlaceholder}
                          </option>
                          {filterStartHours(
                            availableStartHours,
                            values,
                            dayOfWeek,
                            index
                          ).map(s => (
                            <option value={s} key={s}>
                              {s}
                            </option>
                          ))}
                        </FieldSelect>
                      </div>
                      <span className={css.dashBetweenTimes}>-</span>
                      <div className={css.field}>
                        <FieldSelect
                          id={`${name}.endTime`}
                          name={`${name}.endTime`}
                          selectClassName={css.fieldSelect}
                        >
                          <option disabled value="">
                            {endTimePlaceholder}
                          </option>
                          {filterEndHours(
                            availableEndHours,
                            values,
                            dayOfWeek,
                            index
                          ).map(s => (
                            <option value={s} key={s}>
                              {s}
                            </option>
                          ))}
                        </FieldSelect>
                      </div>
                    </div>
                    <div
                      className={css.fieldArrayRemove}
                      onClick={() => fields.remove(index)}
                      style={{ cursor: 'pointer' }}
                    >
                      <IconClose rootClassName={css.closeIcon} />
                    </div>
                  </div>
                );
              })}

              {fields.length === 0 ? (
                <InlineTextButton
                  type="button"
                  className={css.buttonSetHours}
                  onClick={() =>
                    fields.push({ startTime: null, endTime: null })
                  }
                >
                  <FormattedMessage id="EditListingAvailabilityPlanForm.setHours" />
                </InlineTextButton>
              ) : (
                <InlineTextButton
                  type="button"
                  className={css.buttonAddNew}
                  onClick={() =>
                    fields.push({ startTime: null, endTime: null })
                  }
                >
                  <FormattedMessage id="EditListingAvailabilityPlanForm.addAnother" />
                </InlineTextButton>
              )}
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
};

const submit = (onSubmit, weekdays) => values => {
  const sortedValues = weekdays.reduce(
    (submitValues, day) => {
      return submitValues[day]
        ? {
          ...submitValues,
          [day]: submitValues[day].sort(sortEntries()),
        }
        : submitValues;
    },
    { ...values }
  );

  onSubmit(sortedValues);
};

const EditListingAvailabilityPlanFormComponent = props => {
  const { onSubmit, ...restOfprops } = props;
  return (
    <FinalForm
      {...restOfprops}
      onSubmit={submit(onSubmit, props.weekdays)}
      mutators={{
        ...arrayMutators,
      }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          intl,
          // listingTitle,
          weekdays,
          fetchErrors,
          values,
          weeks,
          setIsEditPlanModalOpen,
          form,
        } = fieldRenderProps;
       
        const [toggleDays, setToggleDays] = useState(null);
        const [allDays, setAllDays] = useState(false);
        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const disabledSave = !(values.allDays || weekdays.filter(wd => !values[wd] || (values[wd] && values[wd].length == 0)).length != weekdays.length) || !values.endTime || !values.startTime
        const concatDayEntriesReducer = (entries, day) =>
          values[day] ? entries.concat(values[day]) : entries;
        const hasUnfinishedEntries = !!weekdays
          .reduce(concatDayEntriesReducer, [])
          .find(e => !e.startTime || !e.endTime);


        const { updateListingError } = fetchErrors || {};
        const disabledSaveDays = !(values.allDays || weekdays.filter(wd => !values[wd] || (values[wd] && values[wd].length == 0)).length != weekdays.length);

        const startTime = values && values.startTime;
        const endTime = values && values.endTime;
        const [dateValue, setDateValue] = useState(-1)
        const allWeeks = values && _.mapValues(values, function (o) { return isArray(o) && o.length; });
        delete allWeeks.allDays;
        return (
          <Form id={formId} className={classes} onSubmit={handleSubmit}>
            <div className={css.dateAndTimeContent}>
              <h3> <FormattedMessage id="EditListingAvailabilityPlanForm.modalTitle" /></h3>
              {/* day */}
              <div>
                <p> <FormattedMessage id="EditListingAvailabilityPlanForm.modalDay" /></p>
                <div onClick={() => setToggleDays(DAYS)} className={css.timeButton}>
                  <div>
                    {values.allDays ? "All Days" : "Days"}
                  </div>
                  <IconCollection name="LISTING_MODAL_CALENDAR" />
                </div>
                {toggleDays === DAYS &&
                  <div className={css.timeContainer}>
                    <div className={css.allDaysContent}>
                      {weeks.map((item) =>
                        <FieldCheckbox
                          id={item.key}
                          name={item.key}
                          label={item.label}
                          value={true}
                          checked={allDays && false}
                          onChange={() => {
                            form.change('allDays', false)
                          }}
                        />)}
                      <h6><span>OR</span></h6>
                      <FieldCheckbox
                        id='allDays'
                        name="allDays"
                        label="All Days"
                        value={true}
                        onChange={(e) => {
                          form.reset();
                          setAllDays(!allDays);
                          form.change('allDays', allDays ? false : [true])
                         {!allDays && weeks.map(item => {
                            form.change(item.key, [true])
                          })}
                        }}
                      />
                      <div className={css.allDaysButtons}>
                        <Button
                          onClick={() => {
                            setToggleDays(null);
                            form.reset()
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => setToggleDays(null)}
                          type="button"
                          disabled={disabledSaveDays}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                }
              </div>
              {/* from  */}
              <div>
                <p> <FormattedMessage id="EditListingAvailabilityPlanForm.modalFrom" /></p>
                <div onClick={() => setToggleDays(FROM)} className={css.timeButton}>
                  <div>
                    {startTime ? startTime : capitalizeFirstLetter(FROM)}
                  </div>

                  <IconCollection name="LISTING_MODAL_CLOCK" />

                </div>
                {toggleDays === FROM ? <div className={css.popupWrapper}>
                  <div className={css.timeContainer}>
                    <div className={css.timeContent}>
                      {filteredStartHours.map((item, i) => {
                        return <div onClick={() => {
                          setDateValue(i)
                          form.change('startTime', moment(`${item}`, ["HH.mm"]).format("hh:mm A"));
                          setToggleDays(null);
                        }} key={i}>{moment(`${item}`, ["HH.mm"]).format("hh:mm A")}</div>
                      })}
                    </div>
                  </div>
                </div> : null}
              </div>
              {/* to */}
              <div>
                <p> <FormattedMessage id="EditListingAvailabilityPlanForm.modalTo" /></p>
                <div onClick={() => setToggleDays(TO)} className={css.timeButton}>
                  <div>
                    {endTime ? endTime : capitalizeFirstLetter(TO)}
                  </div>
                  <IconCollection name="LISTING_MODAL_CLOCK" />
                </div>
                {toggleDays === TO ? <div className={css.popupWrapper}>
                  <div className={css.timeContainer}>
                    <div className={css.timeContent}>
                      {(values.startTime
                        ? filteredEndHours.filter(aeh => aeh.split(":") > values.startTime.split(":"))
                        : filteredEndHours).map((item, i) => {
                          return <div onClick={() => {
                            form.change('endTime', moment(`${item}`, ["HH.mm"]).format("hh:mm A"));
                            setToggleDays(null);
                          }} key={i}>{moment(`${item}`, ["HH.mm"]).format("hh:mm A")}</div>
                        })}
                    </div>
                  </div>

                </div> : null}
              </div>
            </div>
            <div className={css.submitButton}>


              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingAvailabilityPlanForm.updateFailed" />
                </p>
              ) : null}
              <Button
                type="button"
                onClick={() => setIsEditPlanModalOpen(false)}
              >
                Cancel
              </Button>
              <PrimaryButton
                type="submit"
                inProgress={submitInProgress}
                disabled={disabledSave}
              >
                <FormattedMessage id="EditListingAvailabilityPlanForm.saveSchedule" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingAvailabilityPlanFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
};

EditListingAvailabilityPlanFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,
  fetchErrors: object.isRequired,

  listingTitle: string.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EditListingAvailabilityPlanForm = compose(injectIntl)(
  EditListingAvailabilityPlanFormComponent
);

EditListingAvailabilityPlanForm.displayName = 'EditListingAvailabilityPlanForm';

export default EditListingAvailabilityPlanForm;
