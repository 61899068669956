import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaTwitter.module.css';

const IconSocialMediaTwitter = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 2.72161C23.1456 3.16771 21.8526 3.37942 21.4291 3.43991C21.4291 3.41722 21.4216 3.4021 21.4216 3.37942C22.8658 2.4721 23.3119 0.77844 23.3119 0.77844C22.4121 1.29259 21.6181 1.5799 21.0208 1.73868C20.6881 1.82942 20.4083 1.87478 20.2117 1.90503C19.3043 0.975025 18.0416 0.400391 16.6427 0.400391C13.8904 0.400391 11.6522 2.63088 11.6522 5.39064C11.6522 5.48893 11.6522 5.58722 11.6597 5.68552C11.6749 5.92747 11.7051 6.16186 11.7505 6.38869C10.4197 6.36601 5.60302 5.95771 1.61059 1.32283C1.61059 1.32283 -0.612477 5.20161 3.12287 7.84796C3.12287 7.84796 1.61059 7.80259 0.809074 7.23552C0.809074 7.23552 0.574669 10.9706 4.87713 12.1502C4.87713 12.1502 3.36484 12.5282 2.6087 12.1955C2.6087 12.1955 3.36484 15.4089 7.14556 15.6433C7.14556 15.6433 4.431 18.1233 0 17.768C2.17769 19.1441 4.76371 19.9455 7.53119 19.9455C15.3195 19.9455 21.6333 13.6321 21.6333 5.8443C21.6333 5.74601 21.6333 5.64771 21.6333 5.54942C21.6333 5.48893 21.6333 5.43601 21.6333 5.37552C21.6333 5.33771 21.6333 5.29235 21.6333 5.25454C23.0699 4.30942 24 2.72161 24 2.72161Z" fill="#060531"/>
</svg>


  );
};

IconSocialMediaTwitter.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaTwitter.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaTwitter;
