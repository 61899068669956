import React, { Component } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';

import { propTypes } from '../../util/types';
import { isAnyFilterActive } from '../../util/search';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';

import {
  SearchResultsPanel,
  SearchFiltersMobile,
  SearchFiltersPrimary,
  SearchFiltersSecondary,
  LocationSearchBar,
  SortBy,
  Modal,
  IconCollection,
} from '../../components';
import FilterComponent from './FilterComponent';
import { validFilterParams } from './SearchPage.helpers';

import css from '../../containers/SearchPage/SearchPage.module.css';

const { LatLng, LatLngBounds } = sdkTypes;

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

const cleanSearchFromConflictingParams = (
  searchParams,
  sortConfig,
  filterConfig
) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

/**
 * MainPanel contains search results and filters.
 * There are 3 presentational container-components that show filters:
 * SearchfiltersMobile, SearchFiltersPrimary, and SearchFiltersSecondary.
 * The last 2 are for desktop layout.
 */
class MainPanelLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecondaryFiltersOpen: false,
      isLocationModalOpen: false,
      isOpenCalender: false,
      currentQueryParams: props.urlQueryParams,
      showList: 'list',
      refresh: false,
    };

    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);

    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);
    this.onToggle = this.onToggle.bind(this)

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, urlQueryParams, sortConfig, filterConfig } = this.props;
    const searchParams = {
      ...urlQueryParams,
      ...this.state.currentQueryParams,
    };
    const search = cleanSearchFromConflictingParams(
      searchParams,
      sortConfig,
      filterConfig
    );

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        search
      )
    );
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { urlQueryParams, history, filterConfig } = this.props;
    const filterQueryParamNames = filterConfig.map(f => f.queryParamNames);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        queryParams
      )
    );
  }

  initialValues(queryParamNames) {
    // Query parameters that are visible in the URL
    const urlQueryParams = this.props.urlQueryParams;
    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState
        ? currentQueryParam
        : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
        return { ...acc, [paramName]: getInitialValue(paramName) };
      }, {})
      : {};
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { urlQueryParams, history, sortConfig, filterConfig } = this.props;

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds } = urlQueryParams;
        const mergedQueryParams = {
          ...urlQueryParams,
          ...prevState.currentQueryParams,
        };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            address,
            bounds,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(
            searchParams,
            sortConfig,
            filterConfig
          );
          history.push(
            createResourceLocatorString(
              'SearchPage',
              routeConfiguration(),
              {},
              search
            )
          );
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, urlQueryParams } = this.props;
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        queryParams
      )
    );
  }

  onToggle() {
    this.setState({ isLocationModalOpen: false, isSecondaryFiltersOpen: false })
  }

  render() {
    const {
      className,
      rootClassName,
      urlQueryParams = {},
      listings,
      searchInProgress,
      searchListingsError,
      searchParamsAreInSync,
      // onActivateListing,
      onManageDisableScrolling,
      onOpenModal,
      onCloseModal,
      // onMapIconClick,
      pagination,
      searchParamsForPagination,
      showAsModalMaxWidth,
      filterConfig,
      history,
      location,
      values,
      sortConfig,
      isSearchMapOpenOnMobile,
      showMap,
      showListState,
      showLists,
      isLandingPage,
      currentUser,
    } = this.props;

    const { zipLocation, zipCodeLatLng } = currentUser && currentUser.attributes.profile.protectedData || {};
    const primaryFilters = filterConfig.filter(f => f.group === 'primary');
    const secondaryFilters = filterConfig.filter(f => f.group !== 'primary');
    const hasSecondaryFilters = !!(
      secondaryFilters && secondaryFilters.length > 0
    );
    // Selected aka active filters
    const selectedFilters = (urlQueryParams, filterConfig);
    const selectedFiltersCount = Object.keys(selectedFilters).length;

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(urlQueryParams, secondaryFilters)
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters)
      .length;

    const isSecondaryFiltersOpen =
      !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
        isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
        toggleSecondaryFiltersOpen: isOpen => {
          this.setState({ isSecondaryFiltersOpen: isOpen });
          this.setState({ isLocationModalOpen: false })
        },
        selectedSecondaryFiltersCount,
      }
      : {};

    const handleSubmit = values => {
      const currentSearchParams = this.state.currentQueryParams;
      const { search, selectedPlace } = values.location;
      const { history } = this.props;
      const { origin, bounds } = selectedPlace;
      const originMaybe = config.sortSearchByDistance ? { origin } : {};
      const searchParams = Object.assign(currentSearchParams, {
        bounds,
        address: search,
      });
      const fullAddress = searchParams?.address?.split(",");
      if (fullAddress && fullAddress?.length >= 4) {
        showLists()
      } else {
        showMap()
      }
      // this.props.onReload()

      // const searchParams = {
      //   ...currentSearchParams,
      //   ...originMaybe,
      //   address: search,
      //   bounds,
      // }
      history.push(
        createResourceLocatorString(
          'SearchPage',
          routeConfiguration(),
          {},
          searchParams
        )
      );
      this.setState({ isLocationModalOpen: false })
    };
    const onClose = () => {
      this.setState({ isLocationModalOpen: false })
    }

    // With time-based availability filtering, pagination is NOT
    // supported. In these cases we get the pagination support info in
    // the response meta object, and we can use the count of listings
    // as the result count.
    //
    // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering
    const hasPaginationInfo = !!pagination && !pagination.paginationUnsupported;
    const listingsLength = listings ? listings.length : 0;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo
        ? pagination.totalItems
        : listingsLength;

    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync;

    const sortBy = mode => {
      const conflictingFilterActive = isAnyFilterActive(
        sortConfig.conflictingFilters,
        urlQueryParams,
        filterConfig
      );

      const mobileClassesMaybe =
        mode === 'mobile'
          ? {
            rootClassName: css.sortBy,
            menuLabelRootClassName: css.sortByMenuLabel,
          }
          : {};
      return sortConfig.active ? (
        <SortBy
          {...mobileClassesMaybe}
          sort={urlQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          onSelect={this.handleSortBy}
          onToggle={this.onToggle}
          showAsPopup
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };

    const classes = classNames(
      rootClassName || showListState ? css.showListContainer : css.searchResultContainer,
      className
    );
    const isMobile = typeof window !== 'undefined' && window.innerWidth < 767;

    const defaultLocation = {
      predictions: [],
      search: (this.state.currentQueryParams.address
        ? this.state.currentQueryParams.address
        : zipLocation) || "Brooklyn, NY, USA",
      selectedPlace: {
        address: (this.state.currentQueryParams.address
          ? this.state.currentQueryParams.address
          : zipLocation) || "Brooklyn, NY, USA",
        bounds: (this.state.currentQueryParams.bounds
          ? this.state.currentQueryParams.bounds
          : zipCodeLatLng &&  new LatLngBounds(
            new LatLng(zipCodeLatLng[3], zipCodeLatLng[2]),
            new LatLng(zipCodeLatLng[1], zipCodeLatLng[0])
          )) || new LatLngBounds(
            new LatLng(41.40102755630302, -72.9909935130689),
            new LatLng(39.95532924369698, -74.89732228693109)
          ),
          // zipCodeLatLng
      }
    };

    return (
      <div className={classNames(classes, isSearchMapOpenOnMobile && css.mapView, isLandingPage && css.isLandingPage)}>
        <>
          <SearchFiltersPrimary
            showListState={showListState}
            showMap={showMap}
            showLists={showLists}
            isSearchMapOpenOnMobile={isSearchMapOpenOnMobile}
            className={css.searchFiltersPrimary}
            sortByComponent={sortBy('desktop')}
            listingsAreLoaded={listingsAreLoaded}
            resultsCount={totalItems}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            {...propsForSecondaryFiltersToggle}
            isLandingPage={isLandingPage}
          >
            {primaryFilters.map(config => {
              return (
                <FilterComponent
                  key={`SearchFiltersPrimary.${config.id}`}
                  idPrefix="SearchFiltersPrimary"
                  filterConfig={config}
                  urlQueryParams={urlQueryParams}
                  initialValues={this.initialValues}
                  getHandleChangedValueFn={this.getHandleChangedValueFn}
                  showAsPopup
                  contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                  onToggle={this.onToggle}
                />
              );
            })}
            <div className={css.filterMapButton} onClick={() => this.setState({ isLocationModalOpen: !this.state.isLocationModalOpen, isSecondaryFiltersOpen: false })}>
              <IconCollection name="ICON_LOCATION" />
            </div>
            {isSecondaryFiltersOpen && !isMobile ?
              <div className={classNames(css.searchFiltersPanel)}>
                <SearchFiltersSecondary
                  urlQueryParams={urlQueryParams}
                  listingsAreLoaded={listingsAreLoaded}
                  sortByComponent={sortBy('desktop')}
                  onToggle={this.onToggle}
                  applyFilters={this.applyFilters}
                  cancelFilters={this.cancelFilters}
                  resetAll={this.resetAll}
                  onClosePanel={() =>
                    this.setState({ isSecondaryFiltersOpen: false })
                  }
                >
                  {secondaryFilters.map(config => {
                    return (
                      <FilterComponent
                        key={`SearchFiltersSecondary.${config.id}`}
                        idPrefix="SearchFiltersSecondary"
                        filterConfig={config}
                        urlQueryParams={urlQueryParams}
                        initialValues={this.initialValues}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        showAsPopup={false}
                      />
                    );
                  })}
                </SearchFiltersSecondary>
              </div> : null}
            {isMobile && <Modal
              id="MainPanelLanding.FilterModalOpen"
              isOpen={isSecondaryFiltersOpen}
              onClose={() => this.setState({ isSecondaryFiltersOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
              containerClassName={css.modalContainer}
              searchFilters={true}
            >
              <div className={classNames(css.searchFiltersPanel)}>
                <SearchFiltersSecondary
                  urlQueryParams={urlQueryParams}
                  listingsAreLoaded={listingsAreLoaded}
                  sortByComponent={sortBy('mobile')}
                  applyFilters={this.applyFilters}
                  cancelFilters={this.cancelFilters}
                  resetAll={this.resetAll}
                  onClosePanel={() =>
                    this.setState({ isSecondaryFiltersOpen: false })
                  }
                >
                  {secondaryFilters.map(config => {
                    return (
                      <FilterComponent
                        key={`SearchFiltersSecondary.${config.id}`}
                        idPrefix="SearchFiltersSecondary"
                        filterConfig={config}
                        urlQueryParams={urlQueryParams}
                        initialValues={this.initialValues}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        showAsPopup={false}
                      />
                    );
                  })}
                </SearchFiltersSecondary>
              </div>
            </Modal>}
            {this.state.isLocationModalOpen && !isMobile ? <div className={css.locationDropdown}>
              <div className={css.locationContent}>
                <div className={css.searchContents}>
                  {/* <h4> <FormattedMessage id="SearchFiltersSecondary.selectLocation" /></h4> */}
                  <LocationSearchBar
                    onSubmit={handleSubmit}
                    onClose={onClose}
                    searchData={this.state.currentQueryParams?.address}
                    currentUser={currentUser}
                    initialValues={{
                      location: defaultLocation
                    }}
                  />
                </div>
              </div>
            </div> : null}
            {isMobile && <Modal
              id="MainPanelLanding.LocationModalOpen"
              isOpen={this.state.isLocationModalOpen}
              onClose={() => this.setState({ isLocationModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
              containerClassName={classNames(css.modalContainer, css.locationModal)}
              locationFilters={true}
            >
              <div className={css.searchContents}>
                {/* <h4> <FormattedMessage id="SearchFiltersSecondary.selectLocation" /></h4> */}
                <LocationSearchBar onSubmit={handleSubmit} onClose={onClose} searchData={this.state.currentQueryParams.address} currentUser={currentUser} initialValues={{ location: defaultLocation }} />
              </div>
            </Modal>}
          </SearchFiltersPrimary>

        </>
        <SearchFiltersMobile
          className={css.searchFiltersMobile}
          urlQueryParams={urlQueryParams}
          sortByComponent={sortBy('mobile')}
          listingsAreLoaded={listingsAreLoaded}
          showMap={showMap}
          showLists={showLists}
          isLandingPage={isLandingPage}

          resultsCount={totalItems}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          showAsModalMaxWidth={showAsModalMaxWidth}
          // onMapIconClick={onMapIconClick}
          onManageDisableScrolling={onManageDisableScrolling}
          // onOpenModal={onOpenModal}
          // onCloseModal={onCloseModal}
          resetAll={this.resetAll}
          selectedFiltersCount={selectedFiltersCount}
        >
          {filterConfig.map(config => {
            return (
              <FilterComponent
                key={`SearchFiltersMobile.${config.id}`}
                idPrefix="SearchFiltersMobile"
                filterConfig={config}
                urlQueryParams={urlQueryParams}
                initialValues={this.initialValues}
                getHandleChangedValueFn={this.getHandleChangedValueFn}
                liveEdit
                showAsPopup={false}
              />
            );
          })}
        </SearchFiltersMobile>


        <div
          className={classNames(css.listings, {
            [css.newSearchInProgress]: !listingsAreLoaded,
          })}
        >
          {searchListingsError ? (
            <h2 className={css.error}>
              <FormattedMessage id="SearchPage.searchError" />
            </h2>
          ) : null}
          <SearchResultsPanel
            showListState={showListState}
            refresh={this.state.refresh}
            className={css.searchListingsPanel}
            listings={listings}
            pagination={listingsAreLoaded ? pagination : null}
            search={searchParamsForPagination}
            urlQueryParams={this.state.currentQueryParams}
          // setActiveListing={onActivateListing}
            isLandingPage={isLandingPage}
          />
        </div>

      </div>
    );
  }
}

MainPanelLanding.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
};

MainPanelLanding.propTypes = {
  className: string,
  rootClassName: string,

  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  // onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  // onOpenModal: func.isRequired,
  // onCloseModal: func.isRequired,
  // onMapIconClick: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  showAsModalMaxWidth: number.isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  history: shape({
    push: func.isRequired,
  }).isRequired,
};


export default MainPanelLanding;
