import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaFacebook.module.css';

const IconSocialMediaFacebook = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.36389 21.6661L4.33331 12.3328H0.333313V8.33276H4.33331V5.6661C4.33331 2.06716 6.56199 0.332764 9.77249 0.332764C11.3103 0.332764 12.6321 0.447257 13.0172 0.49843V4.25952L10.7906 4.26054C9.04455 4.26054 8.70647 5.09023 8.70647 6.30775V8.33276H13.6666L12.3333 12.3328H8.70646V21.6661H4.36389Z" fill="#060531"/>
</svg>

  );
};

IconSocialMediaFacebook.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaFacebook.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSocialMediaFacebook;
