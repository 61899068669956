import React, { Component, useEffect } from 'react';
import { compose } from 'redux';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { bool, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';

import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
// import { ensureCurrentUser } from '../../util/data';

import {
  Form,
  Button,
  FieldTextInput,
  LocationAutocompleteInputField,
} from '../../components';

import css from './AddressForm.module.css';

const identity = v => v;

const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset
class AddressFormComponent extends Component {
  constructor(props) {
    super(props);
    this.uploadDelayTimeoutId = null;
    this.state = {
      uploadDelay: false,
      showError: false,
      value: "",
    };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            pristine,
            rootClassName,
            updateInProgress,
            uploadInProgress,
            form,
            values,
          } = fieldRenderProps;
          // city
          const cityLabel = intl.formatMessage({
            id: 'AddressForm.cityLabel',
          });

          // state
          const stateLabel = intl.formatMessage({
            id: 'AddressForm.stateLabel',
          });

          // zipCode
          const zipCodeValidError = intl.formatMessage({
            id: 'SignupForm.zipCodeRequiredError',
          });

          // loaction
          const loactionHeadingText = intl.formatMessage({
            id: "EditListingDescriptionForm.loactionTitle",
          });
          const addressPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.addressPlaceholder',
          });

          const zipCodeMinLengthMessage = intl.formatMessage(
            {
              id: 'SignupForm.zipCodeTooShort',
            },
            {
              minLength: validators.ZIPCODE_MIN_LENGTH
            }
          );
          const zipCodeMinLength = validators.minLength(
            zipCodeMinLengthMessage,
            validators.ZIPCODE_MIN_LENGTH
          );
          const zipValidError = validators.required(zipCodeValidError);
          const zipCodeLabel = intl.formatMessage({
            id: 'AddressForm.zipCodeLabel',
          });


          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit =
            submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            // this.state.showError ||
            invalid ||
            pristine ||
            pristineSinceLastSubmit ||
            uploadInProgress ||
            submitInProgress;

          const onAutocompleteSelection = async (selectedPlace) => {
            const { address, addressComponents, origin, bounds } = selectedPlace;
            let city, state, zipCode, streetAddress, streetNumber;
            addressComponents && addressComponents.forEach((component) => {
              if (component.types.includes("street_number")) {
                streetNumber = component.long_name;
              } else if (component.types.includes("sublocality")) {
                city = component.long_name;
              }
              else if (component.types.includes("locality")) {
                city = component.long_name;
              } else if (component.types.includes("administrative_area_level_1")) {
                state = component.long_name;
              } else if (component.types.includes("postal_code")) {
                zipCode = component.long_name;
              } else if (component.types.includes("route")) {
                streetAddress = component.long_name;
              }
            });
            const { sw, ne } = bounds;
            const latLngArray = [sw.lng, sw.lat, ne.lng, ne.lat];
            form.change('city', city);
            form.change("isZipCodeValue", false)
            form.change('state', state);
            form.change('zipCode', zipCode);
            form.change('zipLocation', address);
            form.change('zipCodeLatLng', latLngArray);
            form.change('origin', origin);
            form.change('location', { ...values.location, search: `${streetNumber} ${streetAddress}` });
          };

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.sectionContainer}>
                <div className={css.nameContainer}>
                  <LocationAutocompleteInputField
                    className={css.locationAddress}
                    inputClassName={css.locationAutocompleteInput}
                    iconClassName={css.locationAutocompleteInputIcon}
                    predictionsClassName={css.predictionsRoot}
                    validClassName={css.validLocation}
                    name="location"
                    label={loactionHeadingText}
                    placeholder={addressPlaceholderMessage}
                    useDefaultPredictions={false}
                    format={identity}
                    valueFromForm={values.location}
                    onAutocompleteSelection={onAutocompleteSelection}
                  />
                  <FieldTextInput
                    className={css.lastName}
                    type="text"
                    id="city"
                    name="city"
                    label={cityLabel}
                    disabled={true}
                  />
                </div>
              </div>
              <div
                className={classNames(css.sectionContainer, css.lastSection)}
              >
                <FieldTextInput
                  className={css.lastName}
                  type="text"
                  id="state"
                  name="state"
                  label={stateLabel}
                  disabled={true}
                />
                <div className={css.lastName}>
                  <FieldTextInput
                    type="text"
                    id="zipCode"
                    name="zipCode"
                    maxLength="5"
                    disabled={true}
                    // onKeyUp={(event) => handleChange(event)}
                    label={zipCodeLabel}
                    validate={validators.composeValidators(
                      zipCodeMinLength,
                      zipValidError,
                    )}
                  />
                  {
                    this.state.showError ?
                      <p className={css.errorText}><FormattedMessage id="SignupForm.zipcodeValidation" /></p>
                      : null
                  }
                </div>
              </div>

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                <FormattedMessage id="AddressForm.saveChanges" />
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

AddressFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

AddressFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const AddressForm = compose(injectIntl)(AddressFormComponent);

AddressForm.displayName = 'AddressForm';

export default AddressForm;
