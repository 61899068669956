import React from 'react';
import { bool, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { propTypes, LISTING_STATE_DRAFT } from '../../util/types';
import { getListingType, createSlug } from '../../util/urlHelpers';
import { NamedLink } from '../../components';

import css from './OwnListingLink.module.css';

const OwnListingLink = props => {
  const { className, listing, listingFetched, children } = props;

  if (!listingFetched) {
    return null;
  }

  if (!listing) {
    return (
      <NamedLink
        className={className ? className : css.defaultLinkStyle}
        name="NewListingPage"
      >
        {children ? (
          children
        ) : (
          <>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.9999 6.81178V20.3647C20.9999 20.5332 20.933 20.6948 20.8138 20.8139C20.6947 20.9331 20.5331 21 20.3646 21H6.81169C6.6432 21 6.48161 20.9331 6.36246 20.8139C6.24332 20.6948 6.17639 20.5332 6.17639 20.3647V6.81178C6.17639 6.64329 6.24332 6.4817 6.36246 6.36256C6.48161 6.24342 6.6432 6.17648 6.81169 6.17648H20.3646C20.5331 6.17648 20.6947 6.24342 20.8138 6.36256C20.933 6.4817 20.9999 6.64329 20.9999 6.81178V6.81178Z"
                stroke="#060531"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.8235 3H3.63529C3.4668 3 3.30521 3.06693 3.18607 3.18607C3.06693 3.30521 3 3.4668 3 3.63529V17.8235M6.17647 16.5529L11.9407 14.6471L21 17.8235M16.2353 12.5294C15.8141 12.5294 15.4101 12.3621 15.1122 12.0642C14.8144 11.7664 14.6471 11.3624 14.6471 10.9412C14.6471 10.52 14.8144 10.116 15.1122 9.81812C15.4101 9.52027 15.8141 9.35294 16.2353 9.35294C16.6565 9.35294 17.0605 9.52027 17.3583 9.81812C17.6562 10.116 17.8235 10.52 17.8235 10.9412C17.8235 11.3624 17.6562 11.7664 17.3583 12.0642C17.0605 12.3621 16.6565 12.5294 16.2353 12.5294Z"
                stroke="#060531"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <FormattedMessage id="OwnListingLink.addYourListingLink" />
          </>
        )}
      </NamedLink>
    );
  }

  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', state } = currentListing.attributes;
  const slug = createSlug(title);
  const isDraft = state === LISTING_STATE_DRAFT;

  return (
    <NamedLink
      className={className ? className : css.yourListingsLink}
      name="EditListingPage"
      params={{
        id,
        slug,
        type: getListingType(isDraft),
        tab: 'photos',
      }}
    >
      <span className={css.menuItemBorder} />
      {children ? (
        children
      ) : (
        <FormattedMessage id="OwnListingLink.editYourListingLink" />
      )}
    </NamedLink>
  );
};

OwnListingLink.defaultProps = {
  className: null,
  listing: null,
  listingFetched: false,
  children: null,
};

OwnListingLink.propTypes = {
  className: string,
  listing: propTypes.ownListing,
  listingFetched: bool,
  children: object,
};

export default OwnListingLink;
