import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '..';
import { bool, object, PropTypes } from 'prop-types';
import {
  Page,
  Button,
  Footer,
  NamedLink,
  IconCollection,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  SectionSearchbar,
} from '../../components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withViewport } from '../../util/contextHelpers';
import { displayCategories } from '../../marketplace-custom-config';

import MainPanelLanding from '../SearchPage/MainPanelLanding';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';

import css from './CategoryPage.module.css';

const BackIcon = () => {
  return (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.82998 11.9999C6.68059 12.0004 6.53298 11.9721 6.398 11.9172C6.26302 11.8624 6.1441 11.7823 6.04998 11.6828L1.21998 6.54046C1.0729 6.3871 0.992493 6.19474 0.992493 5.99622C0.992493 5.7977 1.0729 5.60534 1.21998 5.45199L6.21998 0.309616C6.38972 0.134589 6.63363 0.0245216 6.89806 0.00362666C7.16248 -0.0172683 7.42576 0.052721 7.62998 0.198197C7.8342 0.343674 7.96262 0.552721 7.987 0.779351C8.01138 1.00598 7.92972 1.23163 7.75998 1.40665L3.28998 6.00051L7.60998 10.5944C7.73226 10.7202 7.80994 10.8734 7.83382 11.0358C7.8577 11.1983 7.82678 11.3632 7.74473 11.5111C7.66267 11.6589 7.53291 11.7835 7.3708 11.8702C7.20869 11.9568 7.02101 12.0019 6.82998 11.9999Z" fill="#060531" />
    </svg>
  )
}

const CategoryPageComponent = (props) => {
  const {
    history,
    location,
    scrollingDisabled,
    onManageDisableScrolling,
  } = props;
  const { siteTwitterHandle, siteFacebookPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const categorySectionData =
    displayCategories && Array.isArray(displayCategories) && displayCategories.length && displayCategories.map((ele, i) => {
      return (
        <div key={ele + i}>
          <NamedLink className={css.helperLink} name="SearchPage" to={{
            search: ele.search,
          }}>
            <img alt={ele.value} src={ele.icon} />
            <div className={css.categoryText}>
              {ele.label ?
                <Button
                  className={`${ele.label == 'BETA'
                    ? css.betaText
                    : ele.label == 'NEW'
                      ? css.newText
                      : ele.label == 'COMING SOON'
                        ? css.commingText
                        : null
                    }`}
                >{ele.label}</Button>
                : null}
              <h2 className={css.categoryName}>{ele.value}</h2>
            </div>
          </NamedLink>
        </div>
      )
    })

  // prettier-ignore
  return (
    <Page
      title="Category Page"
      scrollingDisabled={scrollingDisabled}
    // schema={{
    //   '@context': 'http://schema.org',
    //   '@type': 'SearchPageCategorySection',
    //   description: 'About Yogatime',
    //   name: 'About page',
    // }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar  >
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <div className={css.routeLink}>
                <NamedLink name="LandingPage">
                  <FormattedMessage id="ProfileSettingsForm.homeLink" />
                </NamedLink>
                <IconCollection name="PROFILE_ARROW" />
                <NamedLink name="LandingPage" className={css.accountSetting}>
                  <FormattedMessage id="CategoryPage.categoriesTilte" />
                </NamedLink>
              </div>
              <div className={css.mobileHeadingBox}>
                <div className={css.backButton}>
                  <NamedLink name="LandingPage">
                    <span>
                      <BackIcon />
                    </span>
                  </NamedLink>
                </div>
                <div className={css.categoryTitleMobile}>
                  <FormattedMessage id="CategoryPage.categoriesTilte" />
                </div>
              </div>
              <SectionSearchbar
                categoryPage={true}
                history={history}
                location={location}
                onManageDisableScrolling={onManageDisableScrolling}
              />
              <h2 className={css.categoryTitle}>
                <FormattedMessage id="CategoryPage.categoriesTilte" />
              </h2>
              <div className={css.categoryGrid}>
                {categorySectionData}
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

CategoryPageComponent.propTypes = {
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched } = state.user;
  const { currentPageResultIds } = state.LandingPage;
  const { isAuthenticated } = state.Auth;

  const pageListings = getListingsById(state, currentPageResultIds);

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    listings: pageListings,
    currentUserListingFetched,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const CategoryPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CategoryPageComponent);

export default CategoryPage;