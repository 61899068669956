import { fetchCurrentUser, fetchCurrentUserListing } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';

// ================ Action types ================ //


export const SHOW_OWN_LISTING_REQUEST = 'app/SettingPage/SHOW_OWN_LISTING_REQUEST';
export const SHOW_OWN_LISTING_SUCCESS = 'app/SettingPage/SHOW_OWN_LISTING_SUCCESS';
export const SHOW_OWN_LISTING_ERROR = 'app/SettingPage/SHOW_OWN_LISTING_ERROR';

export const FETCH_USER_LISTINGS_SUCCESS = 'app/SettingPage/FETCH_USER_LISTINGS_SUCCESS'

// ================ Reducer ================ //

const initialState = {
  uploadImageError: null,
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
  vacationListing: []
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {

    case SHOW_OWN_LISTING_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateProfileError: null,
      };
    case SHOW_OWN_LISTING_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
        currentUserListing: payload
      };
      case FETCH_USER_LISTINGS_SUCCESS:
      return {
        ...state,
        vacationListing:payload
      };
    case SHOW_OWN_LISTING_ERROR:
      return {
        ...state,
        updateInProgress: false,
        updateProfileError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

// SDK method: images.upload
export const showOwnRequest = () => ({
  type: SHOW_OWN_LISTING_REQUEST,
});

export const showOwnRequestSuccess = result => ({
  type: SHOW_OWN_LISTING_SUCCESS,
  payload: result,
});
export const fetchUserListingSuccess = result => ({
  type: FETCH_USER_LISTINGS_SUCCESS,
  payload: result,
});
export const showOwnRequestError = error => ({
  type: SHOW_OWN_LISTING_ERROR,
  payload: error,
});



// ================ Thunk ================ //

export const vacationListing = params => async (dispatch, getState, sdk) => {
  try {
    let userListing = [];
    const queryNextTransaction = async (page, totalPages) => {
      const newPage = page + 1;
      const res = await sdk.ownListings.query({ page: newPage });
      const {
        data: { data = null },
      } = res;
      userListing.push(...data);
      const meta = resp && resp.data && resp.data.meta;
      const { totalItems = 0 } = meta;
      if (totalItems > 0 && totalPages !== newPage) {
        queryNextTransaction(newPage, totalPages);
      }
    };

    const resp = await sdk.ownListings.query({});

    const {
      data: { data = null },
    } = resp;
    userListing.push(...data);

    const meta = resp && resp.data && resp.data.meta;
    const page = meta && meta.page;
    const { totalPages = 0, totalItems = 0 } = meta;
    if (totalItems > 0 && totalPages !== page) {
      await queryNextTransaction(page, totalPages);
    }

    const promises = [];
    userListing.map((item) => promises.push(
      params == "Close" ?
        sdk.ownListings.open({ id: item.id })
        : sdk.ownListings.close({ id: item.id })));

    await Promise.all(promises);

    return sdk.currentUser.updateProfile({
      publicData: {
        isVacationMode: params == "Open"
      }
    }).then(() => {
      dispatch(fetchCurrentUser());
      dispatch(showOwnRequestSuccess(userListing));
      return userListing;
    });
  } catch (error) {
    dispatch(showOwnRequestError(storableError(error)));
  }

};
export const loadData = () => (dispatch, getState, sdk) => {
return dispatch(fetchCurrentUserListing()).then((r)=>{
  dispatch(fetchUserListingSuccess(r?.data?.data))
 })
};

