import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import config from '../../config';

import IconCollection from '../IconCollection/IconCollection';
import css from './SearchMapPriceLabel.module.css';

class SearchMapPriceLabel extends Component {
  shouldComponentUpdate(nextProps) {
    const currentListing = ensureListing(this.props.listing);
    const nextListing = ensureListing(nextProps.listing);
    const isSameListing = currentListing.id.uuid === nextListing.id.uuid;
    const hasSamePrice =
      currentListing.attributes.price === nextListing.attributes.price;
    const hasSameActiveStatus = this.props.isActive === nextProps.isActive;
    const hasSameRefreshToken =
      this.props.mapComponentRefreshToken ===
      nextProps.mapComponentRefreshToken;

    return !(
      isSameListing &&
      hasSamePrice &&
      hasSameActiveStatus &&
      hasSameRefreshToken
    );
  }

  render() {
    const {
      className,
      rootClassName,
      intl,
      type,
      listing,
      currentUser,
      onListingClicked,
      isActive,
    } = this.props;
    const { firstName } = (currentUser && currentUser.attributes.profile) || {};
    const currentListing = ensureListing(listing);
    const { price } = currentListing.attributes;

    // Create formatted price if currency is known or alternatively show just the unknown currency.
    const formattedPrice =
      price && price?.currency === config?.currency
        ? formatMoney(intl, price)
        : price?.currency;

    const classes = classNames(rootClassName || css.root, className);
    const priceLabelClasses = classNames(css.priceLabel, css.locationLabel, {
      [css.priceLabelActive]: isActive,
    });
    const caretClasses = classNames(css.caret, { [css.caretActive]: isActive });

    return type == 'user' ? (
      <div className={classes}>
        <div className={css.caretShadow} />
        <div className={priceLabelClasses}>
          {' '}
          <IconCollection name="MAP_LOCATION" />
        </div>
        <div className={caretClasses} />
      </div>
    ) : (
      <button
        className={classes}
        onClick={() => onListingClicked(currentListing)}
      >
        <div className={css.caretShadow} />
        <div className={priceLabelClasses}>{formattedPrice}</div>
        <div className={caretClasses} />
      </button>
    );
  }
}

SearchMapPriceLabel.defaultProps = {
  className: null,
  rootClassName: null,
};

const { func, string } = PropTypes;

SearchMapPriceLabel.propTypes = {
  className: string,
  rootClassName: string,
  // listing: propTypes.listing.isRequired,
  onListingClicked: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SearchMapPriceLabel);
