/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { bool, func, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  AvatarLarge,
  IconAmenities,
  IconCollection,
  InlineTextButton,
  Logo,
  NamedLink,
  NotificationBadge,
  OwnListingLink,
} from '../../components';

import css from './TopbarMobileMenu.module.css';
import { getDoLater } from '../../util/dataExtractor';
import { Link } from 'react-router-dom';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    notificationCount,
    onLogout,
    handleDashboardModal,
    handleAccountModal,
    open,
    handleMobileMenuClose,
  } = props;

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const uuid = currentUser && currentUser.id && currentUser.id.uuid;
  const user = ensureCurrentUser(currentUser);
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' &&
      ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage
      ? css.currentPage
      : null;
  };
  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage
          id="TopbarMobileMenu.signupOrLogin"
          values={{ signup, login }}
        />
      </span>
    );
    return (
      <div className={css.root}>
        <NamedLink name="LandingPage" className={css.logoLink}>
          <span className={css.logo}>
            <Logo format="desktop" />
          </span>
        </NamedLink>
        <div className={css.content}>
          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('LandingPage')
            )}
            name="LandingPage"
          >
            <FormattedMessage id="TopbarMobileMenu.home" />
          </NamedLink>
          {/* <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('LandingPage')
            )}
            name="LandingPage"
            to="/#how-it-works"
          >
            <FormattedMessage id="TopbarMobileMenu.howItWorks" />
          </NamedLink> */}
          <HashLink
            className={classNames(
              css.navigationLink,
              currentPageClass('LandingPage')
            )}
            to="/#how-it-works"
          >
            <FormattedMessage id="TopbarMobileMenu.howItWorks" />
          </HashLink>

          {/* Help center  */}
          <span
            className={classNames(
              css.navigationLink,
              currentPageClass('LandingPage')
            )}
            onClick={() => window.fcWidget.open()}
          >
            <FormattedMessage id="TopbarMobileMenu.helpCenter" />
          </span>
          {/* login  */}
          <NamedLink
            name="LoginPage"
            className={classNames(
              css.navigationLink,
              currentPageClass('LandingPage')
            )}
          >
            <FormattedMessage id="TopbarMobileMenu.loginLink" />
          </NamedLink>
          {/* signup */}
          <NamedLink
            name="SignupPage"
            className={classNames(
              css.navigationLink,
              currentPageClass('LandingPage')
            )}
          >
            <FormattedMessage id="TopbarMobileMenu.signupLink" />
          </NamedLink>
          {/* <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div> */}
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge
        className={css.notificationBadge}
        count={notificationCount}
      />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const didDoLater = getDoLater(currentUser);

  const doLater = isAuthenticatedOrJustHydrated ? (
    <NamedLink name="IdentityPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.identityPageLink" />
      </span>
    </NamedLink>
  ) : null;

  return (
    <div className={css.root}>
      <NamedLink name="LandingPage" className={css.logoLink}>
        <span className={css.logo}>
          <Logo format="desktop" />
        </span>
      </NamedLink>
      {/* <AvatarLarge className={css.avatar} user={currentUser} /> */}
      <div className={css.content}>
        {/* <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span> */}
        <div className={css.contentBorder}>
          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('LandingPage')
            )}
            name="LandingPage"
          >
            <FormattedMessage id="TopbarMobileMenu.home" />
          </NamedLink>
          {didDoLater && doLater}

          {/* <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('LandingPage')
            )}
            name="LandingPage"
          >
            <FormattedMessage id="TopbarMobileMenu.howItWorks" />
          </NamedLink> */}
          {/* faq  */}
          {/* <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('LandingPage')
            )}
            name="LandingPage"
          >
            <FormattedMessage id="TopbarMobileMenu.faq" />
          </NamedLink> */}
        </div>

        <div className={css.contentBorder}>
          {/* rentals  */}
          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('RentalPage')
            )}
            name="RentalPage"
          >
            <IconCollection name="MENU_RENTALS" />
            <FormattedMessage id="TopbarMobileMenu.rentals" />
          </NamedLink>

          {/* Message  */}

          {/* Saved item  */}
          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('InboxPage')
            )}
            name="SavedListingPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <IconCollection name="MENU_SAVED_ITEMS" />
            <FormattedMessage id="TopbarMobileMenu.savedItems" />
            {notificationCountBadge}
          </NamedLink>
        </div>

        <div className={css.contentBorder}>
          {/* Dashboard  */}
          <div
          // onClick={() => [handleDashboardModal(), handleMobileMenuClose()]}
          >
            <NamedLink
              className={classNames(
                css.navigationLink,
                currentPageClass('DashboardPage')
              )}
              name="DashboardPage"
            // params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
            >
              <IconCollection name="MENU_DASHBOARD" />
              <FormattedMessage id="TopbarMobileMenu.dashboard" />
              {notificationCountBadge}
            </NamedLink>
          </div>

          {/* My listing  */}
          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('ManageListingPage')
            )}
            name="ManageListingPage"
          // params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <IconCollection name="MENU_MY_LISTING" />
            <FormattedMessage id="TopbarMobileMenu.myListing" />
            {notificationCountBadge}
          </NamedLink>

          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('MessageListPage')
            )}
            name="MessageListPage"
          >
            <IconCollection name="DROPDOWN_MESSAGE" />
            <FormattedMessage id="TopbarDesktop.messageList" />
          </NamedLink>

          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('ReservationsPage')
            )}
            name="ReservationsPage"
          // params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <IconCollection name="MENU_MY_LISTING" />
            <FormattedMessage id="TopbarMobileMenu.reservations" />
            {notificationCountBadge}
          </NamedLink>

          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('BookingRequestPage')
            )}
            name="BookingRequestPage"
          // params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <IconCollection name="MENU_MY_LISTING" />
            <FormattedMessage id="TopbarMobileMenu.bookingRequest" />
            {notificationCountBadge}
          </NamedLink>

          {/* Listing  */}
          {/* <OwnListingLink
            listing={currentUserListing}
            listingFetched={currentUserListingFetched}
            className={css.navigationLink}
          /> */}
        </div>

        <div
          className={css.contentBorder}
        >
          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('PaymentMethodsPage')
            )}
            name="ProfileSettingsPage"
            params={{ id: uuid }}
          >
            <span>Personal Information</span>
          </NamedLink>
          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('PaymentMethodsPage')
            )}
            name="PaymentMethodsPage"
            params={{ tab: 'payment-methods' }}
          >
            <span>Payments & payouts</span>
          </NamedLink>
          <NamedLink
            className={classNames(
              css.navigationLink,
              currentPageClass('PaymentMethodsPage')
            )}
            name="AddressPage"
            params={{ id: uuid }}
          >
            <span>Address</span>
          </NamedLink>
          <a className={classNames(
            css.navigationLink,
            currentPageClass('PaymentMethodsPage')
          )}>
            <span style={{ cursor: 'pointer' }}
              onClick={() => window.fcWidget.open()}>Help Center</span>
          </a>

        </div>

        <div className={css.contentBorder}>
          {/* Profile  */}
          {uuid && (!!currentUser.attributes.profile.publicData?.businessName ? (
            <NamedLink
              className={classNames(
                css.navigationLink,
                currentPageClass('ProfileSettingsPage')
              )}
              params={{ id: uuid }}
              name="BusinessProfileSelfPage"
            >
              <IconAmenities type="userProfile" />
              <span>Business Profile</span>
            </NamedLink>
          ): (
            <NamedLink
              className={classNames(
                css.navigationLink,
                currentPageClass('ProfileSettingsPage')
              )}
              params={{ id: uuid }}
              name="ProfilePage"
            >
              <IconAmenities type="userProfile" />
              <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
            </NamedLink>)
          )}

        </div>

        {/* <NamedLink
          className={classNames(css.navigationLink, currentPageClass('LandingPage'))}
          name="LandingPage"
        >
          <FormattedMessage id="TopbarMobileMenu.support" />
        </NamedLink> */}
        {/* LogOut  */}
        <InlineTextButton rootClassName={css.navigationLink} onClick={onLogout}>
          <IconAmenities type="logout" />
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
