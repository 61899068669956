import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks } from '../../components';
import css from './SearchResultsPanel.module.css';
import { useSelector } from 'react-redux';
import { getDistanceFromListingToLocation } from '../../containers/SearchPage/SearchPage.duck';

import { Modal } from 'react-responsive-modal';

const ListIcon = props => (
  <svg
    width={27}
    height={21}
    viewBox="0 0 27 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.341 3.528a4.989 4.989 0 017.056 0 .277.277 0 010 .392L3.92 15.397a.277.277 0 01-.392 0 4.989 4.989 0 010-7.055L8.34 3.528z"
      fill="#BAE6FD"
    />
    <path
      d="M8.341 3.92a.277.277 0 010-.392 4.989 4.989 0 017.056 0l3.687 3.687a.33.33 0 010 .465l-1.862 1.862c-.9.9-2.359.9-3.258 0L8.34 3.92z"
      fill="#7DD3FC"
    />
    <path
      d="M18.659 16.951a4.989 4.989 0 01-7.056 0 .277.277 0 010-.392L23.08 5.082a.277.277 0 01.392 0 4.989 4.989 0 010 7.055l-4.814 4.814z"
      fill="#BAE6FD"
    />
    <path
      d="M18.659 16.56a.277.277 0 010 .391 4.989 4.989 0 01-7.056 0l-3.687-3.687a.33.33 0 010-.465l2.095-2.095a1.975 1.975 0 012.793 0l5.854 5.855z"
      fill="#7DD3FC"
    />
  </svg>
);

const Banner = () => {
  const [open, setOpen] = useState(false);
  // const { currentUser } = useSelector(state => state.user);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <div className={css.bannerWrapper} onClick={handleOpen}>
        <div className={css.banner}>
          <div className={css.bannerImage}>
            <svg
              width={50}
              height={50}
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width={50} height={50} rx={25} fill="#81CDBC" />
              <g
                clipPath="url(#clip0_16_493)"
                fill="#fff"
                stroke="#fff"
                strokeWidth={0.4}
              >
                <path d="M25 28.336a.821.821 0 01-.32-1.576c.368-.156.807-.023 1.207.364a.82.82 0 001.141-1.178 3.22 3.22 0 00-1.208-.756v-.956a.82.82 0 10-1.64 0v.961a2.458 2.458 0 00-1.64 2.32 2.464 2.464 0 002.46 2.462.821.821 0 01.28 1.591c-.42.153-.911-.055-1.345-.571a.82.82 0 10-1.256 1.055c.443.528.96.89 1.5 1.072v.954a.82.82 0 101.641 0v-.96c.008-.004.015-.006.022-.008a2.47 2.47 0 001.619-2.313A2.464 2.464 0 0025 28.336z" />
                <path d="M32.597 23.319c-.975-1.2-2.09-2.155-3.313-2.842.2-.238.359-.515.46-.825.401-1.22-.184-2.67-1.677-3.142l2.093-3.65a.82.82 0 00-.482-1.197A16.748 16.748 0 0025 11c-1.59 0-3.164.223-4.678.663a.82.82 0 00-.482 1.196l2.093 3.651c-1.487.47-2.08 1.917-1.677 3.142.101.31.26.587.46.824-1.223.688-2.338 1.643-3.313 2.843-1.95 2.398-3.067 5.422-3.067 8.298C14.336 37.583 19.096 39 25 39c5.908 0 10.664-1.42 10.664-7.383 0-2.876-1.118-5.9-3.067-8.298zM21.801 12.98A15.16 15.16 0 0125 12.64c1.08 0 2.153.114 3.2.34l-1.807 3.15c-.925-.13-1.86-.13-2.786 0l-1.806-3.15zm.536 5.123a8.48 8.48 0 015.325 0c.43.141.665.606.524 1.035a.818.818 0 01-.69.56A8.32 8.32 0 0025 19.312c-.841 0-1.674.13-2.49.385a.816.816 0 01-.696-.557.822.822 0 01.523-1.036zM25 37.37c-6.41 0-9.023-1.674-9.023-5.753 0-2.469 1.009-5.184 2.699-7.263 1.783-2.193 4.028-3.4 6.324-3.4 2.527 0 4.737 1.447 6.324 3.4 1.69 2.08 2.7 4.794 2.7 7.263 0 4.079-2.615 5.753-9.024 5.753z" />
              </g>
              <defs>
                <clipPath id="clip0_16_493">
                  <path
                    fill="#fff"
                    transform="translate(11 11)"
                    d="M0 0H28V28H0z"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className={css.bannerContent}>
            <p className={css.bannerTitle}>
              Own an item like this, other electronics or tools?{' '}
              <span className={css.underlineText}>Safely</span> list it and earn extra
              cash.
            </p>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        classNames={{
          modalContainer: css.modalContainer,
          modal: css.modal,
          modalAnimationIn: css.modalAnimationIn,
          modalAnimationOut: css.modalAnimationOut,
          overlay: css.overlay,
        }}
        showCloseIcon={false}
      >
        <div>
          {/* <h2 className="h5 fw-bold text-center mb-3">Safety List</h2> */}
          <div className="d-flex mb-3">
            <div>
              <ListIcon />
            </div>
            <div className="ms-2">
              Every Runtize user goes through a rigorous identity verification
              and background check process.
            </div>
          </div>
          <div className="d-flex mb-3">
            <div>
              <ListIcon />
            </div>
            <div className="ms-2">
              All your items are protected by our Covize© protection guarantee
              of up to $20,000 per listing.
            </div>
          </div>
          <div className="d-flex mb-3">
            <div>
              <ListIcon />
            </div>
            <div className="ms-2">
              Our highly rated customer support has a swift response rate should
              you need assistance mid rental.
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    showListState,
    urlQueryParams,
    refresh,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentUser = useSelector(state => state.user.currentUser);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const bounds = {
    southwest: {
      lat: urlQueryParams?.bounds?.sw?.lat,
      lng: urlQueryParams?.bounds?.sw?.lng,
    },
    northeast: {
      lat: urlQueryParams?.bounds?.ne?.lat,
      lng: urlQueryParams?.bounds?.ne?.lng,
    },
  };

  const searchLat = (bounds.southwest.lat + bounds.northeast.lat) / 2;
  const searchLng = (bounds.southwest.lng + bounds.northeast.lng) / 2;
  const searchLatLng = urlQueryParams?.bounds
    ? [searchLat, searchLng]
    : [
        currentUser?.attributes?.profile?.protectedData?.origin?.lat,
        currentUser?.attributes?.profile?.protectedData?.origin?.lng,
      ];

  const first4Listings = [...listings]
    .sort((a, b) =>
      getDistanceFromListingToLocation(a, searchLatLng) <
      getDistanceFromListingToLocation(b, searchLatLng)
        ? -1
        : 1
    )
    .slice(0, 4);
  const otherListings = [...listings]
    .sort((a, b) =>
      getDistanceFromListingToLocation(a, searchLatLng) <
      getDistanceFromListingToLocation(b, searchLatLng)
        ? -1
        : 1
    )
    .slice(4);

  return (
    <div className={classes}>
      <div className={classNames(!showListState ? css.listingCards : css.listingCollapse, props.isLandingPage ? css.landinPageListing : "")}>
        {first4Listings.map(l => (
          <ListingCard
            className={css.listingCard}
            refresh={refresh}
            key={l.id.uuid}
            listing={l}
            urlQueryParams={urlQueryParams}
            renderSizes={cardRenderSizes}
            setActiveListing={setActiveListing}
          />
        ))}
        {!props.isLandingPage &&<div
          className="w-100"
          style={{
            gridColumn: props.isLandingPage ? 'span 4 / span 4' : 'span 2 / span 2',
          }}
        >
          <Banner />
        </div>}
        {otherListings.map(l => (
          <ListingCard
            className={css.listingCard}
            refresh={refresh}
            key={l.id.uuid}
            listing={l}
            urlQueryParams={urlQueryParams}
            renderSizes={cardRenderSizes}
            setActiveListing={setActiveListing}
          />
        ))}
        {props.children}
      </div>
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
