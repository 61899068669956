import React from 'react';
import { FormattedMessage } from 'react-intl';
import NamedLink from '../NamedLink/NamedLink';
import Slider from 'react-slick';
import css from './SectionTrendSearch.module.css';
import classNames from 'classnames';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <svg width="36" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="-0.5" width="25" height="25" rx="12.5" transform="matrix(1 0 0 -1 0 25)" fill="white" stroke="#060531" />
        <path d="M14.688 16.1253C14.6571 16.1623 14.6189 16.1925 14.5757 16.2139C14.5326 16.2353 14.4854 16.2475 14.4373 16.2496C14.3892 16.2518 14.3411 16.2439 14.2962 16.2265C14.2513 16.2091 14.2105 16.1825 14.1764 16.1484C14.1424 16.1144 14.1158 16.0736 14.0983 16.0286C14.0809 15.9837 14.073 15.9357 14.0752 15.8875C14.0774 15.8394 14.0896 15.7923 14.111 15.7491C14.1324 15.7059 14.1625 15.6677 14.1995 15.6369L16.6916 13.1418H8.45435C8.36543 13.1371 8.28171 13.0985 8.22043 13.0339C8.15916 12.9693 8.125 12.8836 8.125 12.7946C8.125 12.7055 8.15916 12.6198 8.22043 12.5552C8.28171 12.4906 8.36543 12.452 8.45435 12.4473H16.6916L14.1956 9.95518C14.1328 9.88939 14.0977 9.80192 14.0977 9.71095C14.0977 9.61998 14.1328 9.53251 14.1956 9.46672C14.2277 9.43459 14.2657 9.40909 14.3076 9.39169C14.3495 9.37429 14.3945 9.36534 14.4399 9.36534C14.4852 9.36534 14.5302 9.37429 14.5721 9.39169C14.614 9.40909 14.652 9.43459 14.6841 9.46672L17.7711 12.5537C17.804 12.5854 17.8301 12.6233 17.8479 12.6653C17.8658 12.7072 17.875 12.7524 17.875 12.798C17.875 12.8436 17.8658 12.8887 17.8479 12.9307C17.8301 12.9726 17.804 13.0106 17.7711 13.0422L14.688 16.1253Z" fill="#060531" stroke="#060531" />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <svg width="36" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="-0.5" width="25" height="25" rx="12.5" transform="matrix(1 0 0 -1 0 25)" fill="white" stroke="#060531" />
        <path d="M14.688 16.1253C14.6571 16.1623 14.6189 16.1925 14.5757 16.2139C14.5326 16.2353 14.4854 16.2475 14.4373 16.2496C14.3892 16.2518 14.3411 16.2439 14.2962 16.2265C14.2513 16.2091 14.2105 16.1825 14.1764 16.1484C14.1424 16.1144 14.1158 16.0736 14.0983 16.0286C14.0809 15.9837 14.073 15.9357 14.0752 15.8875C14.0774 15.8394 14.0896 15.7923 14.111 15.7491C14.1324 15.7059 14.1625 15.6677 14.1995 15.6369L16.6916 13.1418H8.45435C8.36543 13.1371 8.28171 13.0985 8.22043 13.0339C8.15916 12.9693 8.125 12.8836 8.125 12.7946C8.125 12.7055 8.15916 12.6198 8.22043 12.5552C8.28171 12.4906 8.36543 12.452 8.45435 12.4473H16.6916L14.1956 9.95518C14.1328 9.88939 14.0977 9.80192 14.0977 9.71095C14.0977 9.61998 14.1328 9.53251 14.1956 9.46672C14.2277 9.43459 14.2657 9.40909 14.3076 9.39169C14.3495 9.37429 14.3945 9.36534 14.4399 9.36534C14.4852 9.36534 14.5302 9.37429 14.5721 9.39169C14.614 9.40909 14.652 9.43459 14.6841 9.46672L17.7711 12.5537C17.804 12.5854 17.8301 12.6233 17.8479 12.6653C17.8658 12.7072 17.875 12.7524 17.875 12.798C17.875 12.8436 17.8658 12.8887 17.8479 12.9307C17.8301 12.9726 17.804 13.0106 17.7711 13.0422L14.688 16.1253Z" fill="#060531" stroke="#060531" />
      </svg>
    </div>
  );
}

const SectionTrendSearch = props => {
  const { displayCategories, shopByBrands, viewport } = props;

  const isMobile = viewport && viewport.width < 767;

  const settings = {
    dots: false,
    infinite: true,
    variableWidth: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          // arrows: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          variableWidth: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          arrows: true,
          slidesToScroll: 1,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        },
      },
    ],
  };
  return (
    <div>
      <div className={css.sectionContainer}>
        <h1>
          <FormattedMessage id="SectionTrendSearch.title" />
        </h1>


        <div className={css.sliderWrapper}>
          <div className={css.imageContent}>
            <Slider {...settings}>
              {shopByBrands &&
                shopByBrands.length &&
                shopByBrands.map((ele, i) => {
                  return (
                    <div
                      style={{ width: isMobile ? '157px' : '25%' }}
                      key={ele + i}
                    >
                      <NamedLink  name="SearchPage" to={{
                      search: ele.search,
                    }}>
                        <div className={css.productContent}>
                          <img alt={ele.value} src={ele.icon} />
                          <h3>{ele.name}</h3>
                        </div>
                      </NamedLink>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTrendSearch;
