import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import {
  TRANSITION_ACCEPT,
  TRANSITION_COMPLETE,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER
} from '../../util/transaction';
import ListingCardInbox from '../ListingCardInbox/ListingCardInbox';
import css from './ActiveListItem.module.css';

const ActiveListItem = props => {
  const { transaction, params, Booking, currentUser, reservationsPage, isFilter } = props;
  const today = new Date();
  const currentDate = new Date();
  const lastWeekDate = new Date(currentDate.getTime() - 6 * 24 * 60 * 60 * 1000);
  const filteredData = transaction && transaction.filter(item => {
    const itemDate = new Date(item.attributes.lastTransitionedAt
    );
    return itemDate >= lastWeekDate && itemDate <= currentDate;
  });
  const currentDates = moment(today).format("DD MMM YYYY");

  return (
    <div className={classNames(css.sectionContainer, reservationsPage && css.reservationsPage)}>
      <div className={css.container}>
        <div className={css.titleWrapper}>
        </div>
        <div className={css.imageContent}>
          {isFilter == "inProgress" ?
            <>
              {transaction &&
                transaction?.length > 0 &&
                transaction?.filter((e) => e.attributes.lastTransition == TRANSITION_ACCEPT &&
                  moment(e?.booking?.attributes?.start).startOf('day').diff(moment(currentDates).startOf('day'), 'day') <= 0
                  ||
                  e.attributes.lastTransition == TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING &&
                  moment(e?.booking?.attributes?.start).startOf('day').diff(moment(currentDates).startOf('day'), 'day') <= 0 && 
                  e?.booking?.attributes?.state == 'accepted').map((l, i) => (
                    <div className={css.productContent}>
                      <ListingCardInbox
                        key={(l.id.uuid) + i}
                        transaction={l}
                        params={params}
                        Booking={Booking}
                        currentUser={currentUser}
                      />
                    </div>
                  ))}
            </>
            : isFilter == "upcoming" ?
              <>
                {transaction &&
                  transaction?.length > 0 &&
                  transaction?.filter((e) => (e.attributes.lastTransition == TRANSITION_ACCEPT &&
                    moment(e?.booking?.attributes?.start).startOf('day').diff(moment(currentDates).startOf('day'), 'day') > 0) || e.attributes.lastTransition == TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING &&
                    moment(e?.booking?.attributes?.start).startOf('day').diff(moment(currentDates).startOf('day'), 'day') > 0 && e?.booking?.attributes?.state == 'accepted').map((l, i) => (
                      <div className={css.productContent}>
                        <ListingCardInbox
                          key={(l.id.uuid) + i}
                          transaction={l}
                          params={params}
                          Booking={Booking}
                          currentUser={currentUser}
                        />
                      </div>
                    ))}

              </>
              : isFilter == "completed" ?
                <>
                  {transaction &&
                    transaction?.length > 0 &&
                    transaction?.filter((e) => e.booking.attributes.state == "accepted" &&
                      e.attributes.lastTransition == TRANSITION_COMPLETE ||
                      e.attributes.lastTransition == TRANSITION_REVIEW_1_BY_CUSTOMER ||
                      e.attributes.lastTransition == TRANSITION_REVIEW_1_BY_PROVIDER ||
                      e.attributes.lastTransition == TRANSITION_REVIEW_2_BY_CUSTOMER ||
                      e.attributes.lastTransition == TRANSITION_REVIEW_2_BY_PROVIDER ||
                      e.attributes.lastTransition == TRANSITION_EXPIRE_REVIEW_PERIOD ||
                      e.attributes.lastTransition == TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD ||
                      e.attributes.lastTransition == TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD).map((l, i) => (
                        <div className={css.productContent}>
                          <ListingCardInbox
                            key={(l.id.uuid) + i}
                            transaction={l}
                            params={params}
                            Booking={Booking}
                            currentUser={currentUser}
                          />
                        </div>
                      ))}
                </>
                : isFilter == "last7Days" ?
                  <>
                    {filteredData &&
                      filteredData.map((l, i) => (
                        <div className={css.productContent}>
                          <ListingCardInbox
                            key={(l.id.uuid) + i}
                            transaction={l}
                            params={params}
                            Booking={Booking}
                            currentUser={currentUser}
                          />
                        </div>
                      ))}
                  </>
                  :
                  <>
                    {transaction &&
                      transaction?.length > 0 &&
                      transaction?.map((l, i) => (
                        <div className={css.productContent}>
                          <ListingCardInbox
                            key={(l.id.uuid) + i}
                            transaction={l}
                            params={params}
                            Booking={Booking}
                            currentUser={currentUser}
                          />
                        </div>
                      ))}
                  </>
          }
        </div>
      </div>
    </div>
  );
};

export default ActiveListItem;
