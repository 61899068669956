import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p><em><strong>EFFECTIVE DATE OF THESE TERMS AND CONDITIONS: FEBRUARY 15, 2024</strong></em></p>
      <p><em><strong>LATEST UPDATE TO THESE TERMS AND CONDITIONS: FEBRUARY 20, 2024</strong></em></p>
      <p>Runtize LLC, a limited liability company organized under the laws of the State of New York, owns and operates
        the website runtize.com and all its corresponding mobile and web applications (collectively, the &ldquo;
        <strong>Platform</strong>&rdquo;). The Platform serves as an online marketplace, enabling users to enter into
        rental arrangements whereby lenders rent products to renters.</p>
      <p>The present Terms and Conditions set forth the terms upon which Runtize LLC offers and provides the Platform,
        rendering it available to renters and lenders of Products (as further defined). The present Terms and Conditions
        govern the terms upon which lenders may offer Products for rent and upon which renters may rent such Products
        from lenders.</p>
      <p>Prior to using the Platform, users must agree to the present Terms and Conditions, in full. If any user of the
        Platform does not agree with these Terms and Conditions, even in part, the user must refrain from using the
        Platform.</p>
      <ol>
        <li>
          <p><strong>INTRODUCTION AND DEFINITIONS</strong></p>
        </li>
      </ol>
      <p>The following terms, when used in these Terms and Conditions have the meanings set forth below, unless the
        context requires otherwise:</p>
      <p>&ldquo;<strong>Features</strong>&rdquo; refers to any and all features offered on the Platform by Runtize.
        Features include but are not limited to an in-app chat feature and an instant booking feature.</p>
      <p>&ldquo;<strong>Lender</strong>&rdquo; refers to a user of the Platform publishing Listings to rent or offer to
        rent one (1) or more Products through the Platform.</p>
      <p>&ldquo;<strong>Listings</strong>&rdquo; refer to offers to rent Products published or otherwise posted by
        Lenders on the Platform.</p>
      <p>&ldquo;<strong>Platform</strong>&rdquo; refers to the web application, mobile application and/or application on
        other platforms and devices named <em>Runtize</em> and operated by Runtize as well as the website <a
          href='https://runtize.com/'><u>https://runtize.com</u></a>.</p>
      <p>&ldquo;<strong>Products</strong>&rdquo; refer to any and all products and items featured on the Platform as
        part of Listings or otherwise featured on the Platform as available for rent. Such Products include but are not
        limited to tools, equipment, electronics and other similar items.</p>
      <p>&ldquo;<strong>Renter</strong>&rdquo; refers to a user of the Platform renting or offering to rent Products
        listed on the Platform.</p>
      <p>&ldquo;<strong>Runtize</strong>&rdquo; refers to the company managing and operating the Platform. Runtize may
        also be referred to as &ldquo;<strong>we</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo;, &ldquo;
        <strong>our</strong>&rdquo; and other similar expressions in these Terms and Conditions.</p>
      <p>&ldquo;<strong>Terms and Conditions</strong>&rdquo; or &ldquo;<strong>Terms</strong>&rdquo; refer to the latest
        version of the present <em>Terms and Conditions</em> and includes any amendments and updates made thereto. For
        reference purposes, the published <em>Terms and Conditions</em> will indicate the date of the latest update made
        thereto.</p>
      <p>&ldquo;<strong>Users</strong>&rdquo; refer to all users of the Platform. Users may also be referred to
        as &ldquo;<strong>you</strong>&rdquo;, &ldquo;<strong>your</strong>&rdquo; and other similar expressions under
        these Terms.</p>
      <ol start='2'>
        <li>
          <p><strong>ACCEPTANCE OF THESE TERMS AND CONDITIONS </strong></p>
        </li>
      </ol>
      <p>The present Terms and Conditions govern the terms upon which you may use the Platform and the Features provided
        thereon. The present Terms and Conditions apply to all Users, both Lenders and Renters, who access and/or
        otherwise use the Platform. The present Terms and Conditions further set forth our limited liability,
        disclaimers and limited warranties.</p>
      <p>The present Terms are applicable and binding to you as of your initial access and usage of the Platform. Upon
        accessing, visiting, downloading or using the Platform in any way, you agree to be bound and governed by these
        Terms, in their entirety. You agree to be bound by these Terms and Conditions, in full, as though you manually
        signed them</p>
      <p>Your usage of the Platform will be construed as your acceptance of these Terms. If you do not agree with these
        Terms, even partially, please refrain from accessing, visiting or using the Platform.</p>
      <p>These Terms and Conditions form a binding agreement between you and us. We are not bound by any terms or
        policies, except for the present Terms and Conditions and any policies explicitly referenced herein. These Terms
        and Conditions encompass any other policies established by us, such as our Privacy Policy, which is deemed to be
        incorporated in these Terms by reference.</p>
      <ol start='3'>
        <li>
          <p><strong>UPDATES TO THESE TERMS</strong></p>
        </li>
      </ol>
      <p>You acknowledge that we may update these Terms and other related policies from time to time, at our discretion.
        The most recent version of these Terms will be published on the Platform. The updated version of the Terms will
        specify the date of the latest update made thereto.</p>
      <p>All updates to these Terms will be binding as of the date specified in the header. Your usage of the Platform
        following the publication of the updated Terms constitutes your agreement to be bound by the updated Terms.</p>
      <p>We will try to notify you prior to making any significant changes to these Terms but we cannot guarantee that
        you will be informed of updates to these Terms prior to their publication on the Platform. For this reason, you
        are encouraged to periodically review these Terms to ensure you are aware of and compliant with the latest
        version thereof.</p>
      <ol start='4'>
        <li>
          <p><strong>ELIGIBILITY TO USE PLATFORM</strong></p>
        </li>
      </ol>
      <p>To be eligible to use the Platform, you confirm and represent the following to us:</p>
      <ol type='i'>
        <li>
          <p>You are at least eighteen (18) years of age.</p>
        </li>
        <li>
          <p>You agree to use the Platform in accordance with all applicable laws, regulations and orders.</p>
        </li>
        <li>
          <p>You have the capacity and ability to enter into legally binding contracts.</p>
        </li>
        <li>
          <p>You have not been barred from accessing or using the Platform under any applicable law or pursuant to an
            administrative decision.</p>
        </li>
        <li>
          <p>You are not subject to any limitations or restrictions that would prevent, restrict or otherwise impair
            your right to use the Platform.</p>
        </li>
        <li>
          <p>You do not have a criminal record, nor have you been convicted of a crime.</p>
        </li>
        <li>
          <p>You warrant that you do not reside in and that you are not accessing the Platform from any country subject
            to applicable U.S. trade sanctions or embargoes. You also confirm that you have not been named on any
            national or denied-party list issued by the U.S. government.</p>
        </li>
      </ol>
      <ol start='5'>
        <li>
          <p><strong>ACCOUNTS ON THE PLATFORM</strong></p>
        </li>
      </ol>
      <p><u>Account Creation</u></p>
      <p>You may visit or access the Platform without creating an account on the Platform, however, some portions of the
        Platform and its Features may be inaccessible to you without a valid account. Users may not offer for rent or
        rent Products via the Platform without having an account on the Platform.</p>
      <p>To create an account on the Platform, you must be at least eighteen (18) years old. If we discover an account
        was created by a User who is under the age of eighteen (18), we will terminate such account, without
        liability.</p>
      <p>In furtherance of the creation of your account, you will be asked to provide personal or private information,
        such as your first name, last name, email address and location. It is optional for you to provide your phone
        number and date of birth as part of your account on the Platform.</p>
      <p><u>Provision of Personal Data </u></p>
      <p>You confirm that all personal data you provided in connection with your account is accurate, true, complete and
        current to the best of your knowledge. You are responsible for updating any personal data submitted on the
        Platform which becomes outdated, expired or no longer valid.</p>
      <p><u>Account Security</u></p>
      <p>Your account on the Platform will be secured by a password. You are responsible for ensuring the password to
        your account remains confidential and secret. In the event of a security breach, such as unauthorized usage of
        your password or a compromised password, you agree to notify us at your earliest convenience by sending an
        email.</p>
      <p>Your account is personal and may only be used by you. You may not use an account that does not belong to you.
        You will therefore refrain from sharing your login details with any third party. As such, you may not transfer
        your account to any third party. Any usage or access of another user&rsquo;s account on the Platform is strictly
        prohibited.</p>
      <p>You are responsible for all acts, content, and omissions arising out of or relating to your account. You agree
        not to engage in unacceptable use of your account and to strictly comply with the obligations contained in these
        Terms.</p>
      <p>We decline full liability for losses or damages caused by unauthorized usage of your account. You are liable
        for any purchases and charges incurred by or using your account on the Platform.</p>
      <p><u>Identity Verification</u></p>
      <p>Upon creating an account on the Platform, it is optional to proceed with the verification of your identity,
        however, to rent or offer to rent a Product, you will be required to undergo an identity verification
        procedure.</p>
      <p>You agree to comply with our third party provider&rsquo;s requirements regarding identification verification.
        As such, you agree to promptly submit any requested information to the third party provider, which shall likely
        consist of a government issued identification (ID) or your passport.</p>
      <p>If you are an individual, such proof of identity may consist of a passport, bank statement, reference letters,
        invoices clearly displaying your name and address, or any other proof of identification approved by us.</p>
      <p>You acknowledge that we may, at our discretion, conduct screenings to verify your identity.</p>
      <p>By submitting the proof of identity to us, you confirm that such proof of identity is accurate, complete and
        valid. Any false or incorrect information or identification submitted constitutes a breach of contract, for
        which we may institute legal proceedings, and may constitute a felony under applicable law.</p>
      <p><u>No Guarantees for Identity Verifications</u></p>
      <p>Runtize is a marketplace, allowing its Users to rent and offer to rent Products and publish Listings in
        connection therewith. We implemented verification procedures to validate the identities of our Users, but we
        cannot guarantee that such verification procedures will ensure rentals will be completed without issues, and/or
        that Listings will be truthful and accurate, and/or that Products will be returned in the same state as
        received. We disclaim all such warranties. We recommend Users exercise caution when engaging with other Users on
        the Platform and proceeding with a rental order.</p>
      <p><u>Termination of Accounts</u></p>
      <p>You may close or otherwise terminate your account at any time by following the instructions on the Platform. We
        reserve the right to terminate or suspend a User&rsquo;s account on the Platform for reasons of convenience at
        our discretion. Further, we may terminate or suspend a User&rsquo;s account on the Platform if the User breached
        these Terms and Conditions. Users agree that if we terminate a User&rsquo;s account on the Platform, the User
        waives any recourses or claims it may have or had against us. The User will also release us from all liability
        in connection with the termination of the User&rsquo;s account.</p>
      <p>We further reserve the right to investigate any potential violations of these Terms and Conditions, applicable
        laws, regulations or binding terms. Users will be required to cooperate with us during any such investigations
        by submitting all requested documentation and information.</p>
      <ol start='6'>
        <li>
          <p><strong>RUNTIZE&rsquo;S ROLE</strong></p>
        </li>
      </ol>
      <p>The Platform serves as an online marketplace that allows for the rental and offer to rent of Products between
        Users. The Platform and Runtize do not own any of the Products listed for rent on the Platform, or otherwise
        featured on the Platform.</p>
      <p>With respect to the Products, the Platform&rsquo;s role is limited to acting as an intermediary to transactions
        involving Renters and Lenders of the Products. More specifically, the Platform facilitates the rental of
        Products between Users by facilitating communications between Users, and offering an online platform whereby
        Lenders can post Listings, content and enter into rental orders regarding the Products. Please note that we do
        not act as an agent for any party to the transaction.</p>
      <p>The Platform is not affiliated with the Products&rsquo; agents, manufacturers, developers, designers,
        resellers, and distributors. The Platform acts as an independent entity with respect to the Products and should
        not be construed as an affiliate to the Products&rsquo; agents, manufacturers, designers, distributors,
        resellers, or other entity associated with such Products.</p>
      <p>For the avoidance of doubt, the Platform is not affiliated to any other websites or services that feature the
        Products for sale or purchase.</p>
      <ol start='7'>
        <li>
          <p><strong>RENTERS</strong></p>
        </li>
      </ol>
      <p>To rent or offer to offer Products listed on the Platform, Renters hereby provide the following warranties and
        representations to us:</p>
      <ul>
        <li>
          <p>You have the full authority to enter into these Terms and to perform the obligations contained herein.</p>
        </li>
        <li>
          <p>You are not party to any contract, arrangement, legal obligation or other duty that would prevent you from
            renting the Products you intend to rent.</p>
        </li>
        <li>
          <p>The information you provided in your account as well as the payment information you provided is accurate,
            true and complete to the best of your knowledge.</p>
        </li>
        <li>
          <p>You have immediately available and sufficient funds to pay for the rental of the Products subject to any
            order you place.</p>
        </li>
        <li>
          <p>You acknowledge that the Platform does not offer any warranties with respect to the Products, except for
            those explicitly set forth in these Terms.</p>
        </li>
        <li>
          <p>You agree that we are not liable for any breach or violation caused by your rental of the Products.</p>
        </li>
      </ul>
      <p>You acknowledge that we are relying upon the above representations and warranties to allow you to enter into
        transactions and rent Products from Lenders.</p>
      <ol start='8'>
        <li>
          <p><strong>LENDERS</strong></p>
        </li>
      </ol>
      <p>To publish Listings to rent and offer to rent one (1) or more Products on the Platform, you hereby warrant and
        represent to us as follows:</p>
      <ul>
        <li>
          <p>You have the full authority to enter into these Terms and to perform the obligations contained herein.</p>
        </li>
        <li>
          <p>You are not party to any contract, arrangement, legal obligation or other duty that would prevent you from
            listing, renting, transferring or in any other way disposing of the Products you publish in one (1) or more
            Listings on the Platform.</p>
        </li>
        <li>
          <p>The information you provided in your account as well as the information you provide with respect to the
            Products is accurate, true and complete to the best of your knowledge.</p>
        </li>
        <li>
          <p>You are the lawful owner of the Products you intend to rent via the Platform, and you possess all right,
            title and interests to such Products.</p>
        </li>
        <li>
          <p>The rental of the Products to a Renter will not breach or violate any third party right.</p>
        </li>
        <li>
          <p>The Products subject to your Listings on the Platform are not damaged, defective or otherwise unusable.</p>
        </li>
      </ul>
      <p>You acknowledge that we are relying upon the above representations and warranties to feature your Listings on
        the Platform and allow you to rent such Products using the Platform.</p>
      <p>Pursuant to publishing a Listing, you agree to set up your payout details using your Stripe account. As such,
        you will need to enter your bank account information and other information required by Stripe.</p>
      <p><u>Lender&rsquo;s Acknowledgments</u></p>
      <p>You acknowledge that as an intermediary between Users, we will not be responsible if the rental of your
        Products breaches any third party right or legal obligation to which you are bound. You acknowledge that we will
        not be responsible for any damages, defects or other issues affecting the Products you feature in a Listing on
        the Platform.</p>
      <p><u>Creation of Listings</u></p>
      <p>Lenders must create Listings on the Platform to describe the Products available for rent. The Listings and
        Product descriptions must be accurate and complete as to allow us to determine exactly which Product(s) a Renter
        has rented. Lenders may upload recent photos of the Products available for rent on the Platform.</p>
      <p>The Listings must further specify the replacement value of the Product. The Lender will be bound by the
        replacement value listed in the Listing, and may not adjust the replacement value after a rental order has been
        placed for such Product. We reserve the right to alter the replacement value listed in the Listing if we believe
        the replacement value is incorrect. The Lender will be bound by any replacement value changed by us.</p>
      <ol start='9'>
        <li>
          <p><strong>ASSUMPTION OF RISKS</strong></p>
        </li>
      </ol>
      <p>To the fullest extent permitted under applicable law, Users knowingly and voluntarily agree to assume all risks
        associated with offering for rent and renting Product(s) via the Platform, even if the Users were not aware of
        such risks in advance. Such risks include risks of negligence, misuse, carelessness, injury and loss, without
        limitation. Each User assumes full responsibility for its involvement in the rental of the Products.</p>
      <p>Each User further agrees that:(1) it has assessed the risks involved in the rental of the Product(s) and chosen
        to participate in the rental thereof; (2) it is legally capable and fit to participate in the Product rental or;
        (3) it understands and is aware of the risks associated with proceeding with such a rental and has nonetheless
        chosen to participate in the rental thereof; and (4) it will comply with all applicable laws and these Terms and
        Conditions with respect to the rental.</p>
      <ol start='10'>
        <li>
          <p><strong>DISPUTES BETWEEN LENDERS AND RENTERS</strong></p>
        </li>
      </ol>
      <p>In case of a dispute between a Lender and Renter, we encourage you to attempt to resolve the dispute amicably.
        At our discretion, we may, but are under no obligation to attempt to help you reach a mutually acceptable
        settlement of the dispute. If we elect to intervene, we do not guarantee that our intervention will resolve the
        dispute. Further, we provide no guarantees or warranties as to the outcome of our intervention, and the
        resolution of the dispute.</p>
      <p>As a result, you agree to fully release and discharge us from all claims, losses, damages, liabilities,
        obligations, rights, remedies and causes of action of every nature, that you have, may have or had against us
        arising out of the dispute. You further agree not to institute legal action in such a dispute unless we state
        otherwise. You agree to be bound and subject to our recommendations, and that any decisions we render with
        respect to the resolution of such dispute will be binding and enforceable.</p>
      <ol start='11'>
        <li>
          <p><strong>DESCRIPTIONS OF FEATURES</strong></p>
        </li>
      </ol>
      <p><u>In-App Chat Feature</u></p>
      <p>Renters and Lenders may interact and chat with each other freely on the Platform using the in-app chat Feature.
        All interactions between Users using the in-app chat Feature must be respectful, and compliant with applicable
        laws and these Terms.</p>
      <p>We may access communications between Users to verify that such communications comply with these Terms. Users
        are not permitted to share contact information, such as phone numbers and email addresses, during such chats and
        exchanges on the Platform.</p>
      <p><u>Instant Booking</u></p>
      <p>Lenders have the option of selecting instant booking when Listing a Product for rent on the Platform. The
        instant booking feature allows Renters to automatically book the rental of the Product, subject to such feature,
        without requiring the Lender&rsquo;s prior approval.</p>
      <p>If the instant booking feature is not selected, the Lender will need to approve or decline the Renter&rsquo;s
        request to book the rental of the Product within twelve (12) hours of the Lender&rsquo;s receipt of such
        request.</p>
      <ol start='12'>
        <li>
          <p><strong>RENTAL ORDERS </strong></p>
        </li>
      </ol>
      <p>All Product rentals booked via our Platform are governed by and subject to these Terms and Conditions, our
        Privacy Policy and any policies we implement. Such Product rentals will be confirmed in an email sent to both
        the Renter and the Lender. The Product rental confirmation is referred to as the rental order, and includes all
        relevant terms, including but not limited to the description of the Product subject to the rental, the duration
        of the rental, the delivery or pick up thereof, courier costs and other relevant considerations. The Product
        rental confirmation further confirms the cost of the Product rental.</p>
      <p>When checking out, the Renter may select to pick up the Product subject to the rental, or have it delivered by
        a courier, at the Renter&rsquo;s sole cost.</p>
      <p>If, at the time of the meeting or upon the Renter&rsquo;s receipt of the Product, the Product subject to the
        rental does not meet the Renter&rsquo;s expectations or does not comply with the Lender&rsquo;s description, the
        Renter would be responsible for contacting us, and providing us with necessary details and clarification. We
        will then determine the appropriate course of action, which may consist of canceling the rental.</p>
      <ol start='13'>
        <li>
          <p><strong>RENTAL PERIODS AND LIABILITY</strong></p>
        </li>
      </ol>
      <p><u>Rental Periods</u></p>
      <p>The rental period refers to the time frame during which the Renter is provided with the possession, custody,
        control and care of the Product subject to the rental. The rental period will commence on the delivery date of
        the Product or the provision of the Product to the Renter by the Lender. The rental period will end as of the
        pick up of the Product by the Lender or the return thereof to the Lender.</p>
      <p>All initial rental orders must specify a start date and end date, as well as whether the Product will be picked
        up by or delivered to the Renter, and the return thereof.</p>
      <p>The Renter is responsible for returning or otherwise shipping the Product back to the Lender in accordance with
        the terms of the rental order on or prior to the end of the rental period. The Renter may request an extension
        of the rental period, acknowledging that the acceptance thereof will be at the discretion of the Lender, and
        Runtize if applicable. If the Lender does not agree to the extension of the rental period, the Renter will be
        required to return the Product on the original end date. Any failure by the Renter to do so may result in legal
        action taken against the Renter.</p>
      <p><u>Renter&rsquo;s Usage of the Product</u></p>
      <p>The Renter is responsible for using the product in accordance with its intended purpose and the Lender&rsquo;s
        instructions. The Renter may not use the Product for any other purposes, except with the Lender and
        Runtize&rsquo;s prior written consent.</p>
      <p>During the rental period, the Renter will benefit from the care, custody and control of the Product. The Renter
        will use the Product with proper care and caution, and may be held responsible for any misuse, or damage to the
        Product during the rental period and any applicable extension.</p>
      <p>As such, if the Product is damaged, lost, stolen, transferred, converted or misplaced, or otherwise not
        returned in the condition originally provided to the Renter, the Renter will be responsible for the repair
        and/or replacement value of the Product. We will determine the replacement value of the Product. The Renter is
        responsible for paying for such damages and/or the replacement value immediately upon request.</p>
      <p>Upon return, if the Product is locked with a password created by the Renter, the Product will be deemed
        dysfunctional. The Renter will then be responsible for the repair and/or replacement value of the Product.</p>
      <p>We may at our discretion charge the Renter&rsquo;s payment information for any such damages and/or the
        replacement value of the Product. We are not responsible for any losses, damages or other issues affecting the
        Product and/or the return thereof.</p>
      <ol start='14'>
        <li>
          <p><strong>PAYMENTS, CHARGES, REFUNDS AND OTHER RELEVANT TERMS</strong></p>
        </li>
      </ol>
      <p><u>Renter&rsquo;s Payment Information</u></p>
      <p>Once a rental order is placed, the Renter&rsquo;s payment information will be automatically charged. The
        Renter&rsquo;s payment information refers to the credit card on file, account information, Paypal account or
        other form of payment as authorized by us. You acknowledge that we may use a third party payment processing
        company to process your payments. You further agree to comply with the terms of such third party payment
        processing company.</p>
      <p>By providing your payment information to us, you acknowledge and agree that any third party payment processing
        company we retain may store your payment information and billing information.</p>
      <p>If you choose to make payments using an Automated Clearing House (ACH), you hereby authorize us to
        electronically debit your account to satisfy any payments due to pursuant to a rental order and/or these
        Terms.</p>
      <p>You confirm that you are the sole owner of the payment information you provided. If you are not the sole owner
        of the payment information provided, you confirm that you secured all necessary rights and consents to use the
        payment information provided.</p>
      <p>We reserve the right to refuse to process a rental order if we determine that your payment information is
        invalid, unusable or otherwise unacceptable.</p>
      <p>You agree to update all your payment information and all details pertaining to payment as needed during the
        term of your usage of the Platform.</p>
      <p><u>Outstanding Balances</u></p>
      <p>Any unpaid balances may be sent to collection agencies, and we may institute legal action to collect any
        outstanding balances due. You will be responsible for all interest charged, and collection costs, as well as
        applicable legal costs, including attorney fees and court costs.</p>
      <p>You confirm having examined, to your satisfaction, all relevant documentation and information pertaining to the
        Platform and agree to be bound by the payment obligations contained herein. You agree to not, directly or
        indirectly, initiate any chargeback with your financial institution with respect to any payments made in
        connection with the Platform.</p>
      <p><u>Release to Lender, Commission and Service Fee</u></p>
      <p>At the end of the rental, we will release the funds to the Lender, less any applicable fees and commissions.
        Please note we retain an eighteen percent (18%) commission on the total amount paid by the Rental for the
        booking. Further, we charge a fifteen percent (15%) service fee charged to the Renter upon the end of the rental
        period. Such commission and service fee may be subject to change, with prior notice.</p>
      <p><u>Refunds</u></p>
      <p>Please note that we do not offer refunds for unused or otherwise unsatisfactory rentals, unless otherwise
        specified. All rental orders are deemed final and may not be refunded to the Renter.</p>
      <p>Notwithstanding the foregoing, we may, at our discretion, issue refunds if the Product: (i) is not received by
        the Renter, (ii) delivered unreasonably late, (iii) not functional or unusable, or (iv) does not comply with the
        description in the Listing. We will not be under any obligation to issue a refund under any circumstances, and
        even in case of default by the Lender. In all cases, we will, at our discretion, determine the amount of the
        refund. Any past refund will not entitle a Renter to subsequent or future refunds.</p>
      <p><u>Taxes</u></p>
      <p>Renters are responsible for verifying the price of the rental order prior to making the corresponding payment.
        Unless otherwise indicated, the payments for rental orders are exclusive of any taxes and duties. Renters are
        responsible for paying taxes charged in connection with their purchase of the rental order.</p>
      <p>We may collect applicable sales tax if we determine that we have an obligation to collect such sales tax.</p>
      <p>Lenders may be required to report their earnings from rentals conducted through or as a result of the Platform.
        As such, we may also be required to report Lenders&rsquo; earnings to appropriate tax authorities if the Lenders
        generate a minimum amount of income from rentals conducted through or as a result of the Platform. We may then
        provide Lenders&rsquo; personal information to the appropriate authorities for tax purposes. We are not
        responsible for paying on income earned by Lenders relating to Product rentals.</p>
      <ol start='15'>
        <li>
          <p><strong>INSURANCE </strong></p>
        </li>
      </ol>
      <p>We do not operate as an insurance provider, nor do we provide insurance coverage. If Products subject to
        rentals are required to be insured by a licensed and reputable insurance carrier, Lenders are required to secure
        such insurance coverage.</p>
      <p>Lenders are responsible for ensuring they comply with insurance requirements as set out in applicable laws and
        regulations. Lenders are further responsible for complying with all requirements set out in insurance policies
        in place protecting the Products. Upon request, Lenders agree to provide us with copies of their insurance
        policies and coverage, and to notify us in advance of any changes to such policies and coverage.</p>
      <p>Renters may secure appropriate insurance coverage to protect their activities and the Products while under
        their care and possession.</p>
      <p>Lenders acknowledge that by renting Products, there is a risk of loss, damage and other adverse effects.
        Lenders acknowledge proceeding with the rental of Products to Renters via the Platform at their sole risk,
        acknowledging that we will not be held liable for any damages, issues, late returns, losses or other adverse
        effects affecting the Products.</p>
      <ol start='16'>
        <li>
          <p><strong>PROTECTION GUARANTEE</strong></p>
        </li>
      </ol>
      <p>We offer a protection guarantee, whereby we guarantee coverage to Lenders for damage, loss or theft for a
        maximum value of twenty thousand dollars ($ 20,000) per rental Order, which damage cannot be recovered by the
        Renter. The protection guarantee does not constitute insurance and is not an insurance policy.</p>
      <p>The protection guarantee is automatically provided to Lenders on the Plartform. Renters who rent Products via
        the Platform are not protected by such protection guarantee. Renters acknowledge that any loss, damage and/or
        theft of the Products during the rental period is the responsibility of the Renter.</p>
      <p>The protection guarantee may potentially waive the Lender&rsquo;s responsibility for the cost to repair or
        replace any accidental or unintentional damage to the Products, subject to exclusions set forth in these Terms
        and Conditions, subject to a maximum value of $ 20,000.00 USD, provided all conditions set forth herein are
        met.</p>
      <p><u>Conditions to Benefit from Protection Guarantee</u></p>
      <p>To benefit from such protection guarantee, the Lender and the Renter will need to have verified their
        identities using the Platform, in advance, prior to the commencement of the rental.</p>
      <p>Lenders acknowledge that the protection guarantee will apply strictly to one (1) Product, subject to one rental
        Order.</p>
      <p>To benefit from the protection guarantee, the Lender must comply with all obligations set forth in these Terms.
        Any failure by the Lender to comply will be deemed to void the protection guarantee.</p>
      <p><u>Exclusions</u></p>
      <p>The protection guarantee will not apply to any of the following: (1) Damages, losses and/or theft occurring
        outside of the State of New York; (2) Any damages and/or losses caused by: (i) extreme temperatures, (ii)
        exposure to toxins, unsafe conditions, or other unsuitable environments, (iii) a latent defect or hidden defect,
        (iv) rust, oxidation or discoloration, (v) transportation, shipping and/or the delivery of the Product, (vi)
        work, updates or changes made to the Product by the Renter, the Lender or a third party, (vii) a Force Majeure
        or Act of God such as a natural disaster. Any circumstances or conduct by the Lender made not in good faith
        would void the protection guarantee. Moreover, the protection guarantee will not apply to:</p>
      <ul>
        <li>
          <p>Regular wear and tear;</p>
        </li>
        <li>
          <p>Lack of proper maintenance;</p>
        </li>
        <li>
          <p>Cosmetic damages;</p>
        </li>
        <li>
          <p>Damages, loss or defects covered by another warranty program, including the warranty offered by the
            manufacturer;</p>
        </li>
        <li>
          <p>Manufacturer defects;</p>
        </li>
        <li>
          <p>In connection to a recall issued by the manufacturer, and whereby the manufacturer is responsible for the
            necessary replacement;</p>
        </li>
        <li>
          <p>Any damage that is not reported to us within twenty-four (24) hours of its occurrence and/or the incident
            giving rise to the damage.</p>
        </li>
      </ul>
      <p>Lenders are responsible for initiating an insurance claim in connection with the Product(s) for any loss and/or
        theft thereof, acknowledging that the protection guarantee will not apply in such cases. Further, the protection
        guarantee will not apply in case the Lender benefits from insurance coverage or is eligible for such coverage
        and does not benefit therefrom for any reason in connection with the Product. If the Lender benefits from
        insurance coverage or is eligible for such coverage for the Product in question, the Lender is responsible for
        initiating an insurance claim in connection therewith.</p>
      <p>Any accessory items, such as batteries, cases, and hoods, to the Product are covered by the protection
        guarantee as long as they are written in the description of the Product(s) rented.</p>
      <p><u>Notification</u></p>
      <p>Lenders wishing to benefit from the protection guarantee must notify us within twenty-four (24) hours after the
        occurrence of the damage or the incident giving rise to the damage. We reserve the right to conduct an
        investigation. The determination of whether the protection guarantee applies is at our sole discretion based on
        our findings and any investigation we conduct.</p>
      <p>If a Lender refuses to provide documentation or information requested as part of our investigation, the
        protection guarantee will be voided. We do not guarantee that the protection guarantee will apply, nor that the
        Lender will be reimbursed, credited or otherwise compensated for any costs in connection with the Product
        subject to the protection guarantee.</p>
      <ol start='17'>
        <li>
          <p><strong>INTELLECTUAL PROPERTY </strong></p>
        </li>
      </ol>
      <p>We own or have secured the appropriate permission to use the content published on the Platform. Our
        intellectual property, trademarks and logos may not be reproduced or shared without our explicit prior written
        consent. All intellectual property directly relating to the Platform and Runtize remains our sole property and
        we are the exclusive owners of such intellectual property throughout the world, without limitations of time or
        space.</p>
      <p>Any content that has been licensed to us remains the property of its respective owners. Please note that the
        reproduction, transmission, sharing and distribution of all or part of any the content on the Platform is
        expressly prohibited, except as provided under these Terms and Conditions.</p>
      <p>Users are permitted to copy and print portions of the Platform solely for purposes of rentals and reference
        purposes. We do not authorize the reproduction, modification, or transmission of the intellectual property on
        the Platform to any third party without our prior specific written consent.</p>
      <ol start='18'>
        <li>
          <p><strong>TRADEMARKS</strong></p>
        </li>
      </ol>
      <p>Trademarks, logos and intellectual property relating to Runtize and the Platform are proprietary to us. Our
        trademarks and logos are protected by copyright laws. Accordingly, our trademarks and logos may not be
        reproduced or shared without our prior written consent. You may not use our trademarks and logos for any purpose
        except to carry out transactions in accordance with the present Terms and Conditions.</p>
      <p><u>Third Party Trademarks</u></p>
      <p>The Platform may include links to third party websites and apps. To properly identify the third party website
        or app, images of the third party&rsquo;s trademarks and intellectual property may be published on the Platform.
        We may publish the logos of the third party website or app, while not having any affiliation to such third
        party, to allow you to identify the products associated with such mark. In accordance with applicable trademark
        laws, the publication of the logos of third party websites and apps constitutes nominative fair use.</p>
      <ol start='19'>
        <li>
          <p><strong>PERMITTED AND PROHIBITED CONDUCT </strong></p>
        </li>
      </ol>
      <p>You are responsible for your conduct, actions and usage of the Platform. You must agree to not engage in
        unacceptable use of the Platform, which consists of using the Platform to:</p>
      <ul>
        <li>
          <p>Breach any applicable law, government order, regulation or policy;</p>
        </li>
        <li>
          <p>Interfere with or damage the Platform and/or its servers;</p>
        </li>
        <li>
          <p>Send harmful components, viruses or damaging files;</p>
        </li>
        <li>
          <p>Use automated means, including spiders, robots, crawlers, data mining tools, to download data from the
            Platform;</p>
        </li>
        <li>
          <p>Engage in unlawful or illegal, fraudulent or otherwise discriminatory conduct, or encourage others to use
            the Platform for such purposes;</p>
        </li>
        <li>
          <p>Intimidate, insult, harass, threaten or otherwise bully Users of the Platform;</p>
        </li>
        <li>
          <p>Declare known false or illicit statements or remarks;</p>
        </li>
        <li>
          <p>Send spam, chain letters and unsolicited materials;</p>
        </li>
        <li>
          <p>Impersonate an individual or intentionally mislead others about your true identity;</p>
        </li>
        <li>
          <p>Collect personal information or data regarding Users of the Platform, without obtaining the necessary
            consents;</p>
        </li>
        <li>
          <p>Decompile, reverse engineer or hack any portion of the Platform or attempt to do so;</p>
        </li>
        <li>
          <p>Publish or share content or information that violates a third party&rsquo;s intellectual property
            rights;</p>
        </li>
        <li>
          <p>Sell, lease, assign or transfer your account on the Platform, without our prior written approval;</p>
        </li>
        <li>
          <p>Act in a way that conflicts with the spirit of these Terms and Conditions.</p>
        </li>
      </ul>
      <p>You may be held personally responsible for your failure to comply with these Terms and Conditions, and our
        applicable standards. Further, you agree that we may, without liability and without penalty, revoke your right
        to use the Platform if you violated or attempted to violate any obligation in these Terms and Conditions.</p>
      <ol start='20'>
        <li>
          <p><strong>USER CONTRIBUTIONS AND CONTENT</strong></p>
        </li>
      </ol>
      <p>Lenders are able to post and publish Listings and other content on the Platform. Content posted or published by
        Lenders as part of Listings or otherwise on the Platform is referred to as &ldquo;<strong>User
          Content</strong>&rdquo;.</p>
      <p><u>Liability for User Content</u></p>
      <p>You are solely responsible for any User Content you publish on the Platform. You are responsible for taking all
        necessary precautions to ensure the User Content you publish does not violate any third party rights or any
        intellectual property laws. You may be held personally liable if the User Content you publish violates these
        Terms and Conditions or any third party right.</p>
      <p><u>License to User Content </u></p>
      <p>You hereby grant us and our affiliates a nonexclusive, worldwide, perpetual, irrevocable, royalty-free and
        transferable license to use, publish, distribute, modify and commercialize any User Content you publish on the
        Platform, in any and all media or form of communication, whether now existing or hereafter developed.</p>
      <p>We reserve the right to edit, delete, or remove User Content for any reason we deem proper or appropriate, at
        our sole discretion.</p>
      <p>Considering User Content is user-generated, we are unable to guarantee the quality, suitability and integrity
        of any such user-generated content. We will not be liable for any errors, issues or losses caused by User
        Content published on the Platform. Further, we disclaim all warranties and guarantees in connection with any
        User Content published on the Platform. We further do not guarantee that the User Content will be appropriate,
        accurate and without error.</p>
      <ol start='21'>
        <li>
          <p><strong>PLATFORM AVAILABILITY AND CHANGES</strong></p>
        </li>
      </ol>
      <p>We instituted strict processes to ensure the Platform remains available and accessible to Users at all times.
        Despite this, we cannot guarantee that the Platform will be available on a continuous basis without interruption
        or error.</p>
      <p>Certain factors beyond our reasonable control may affect the Platform&rsquo;s operations and availability. You
        therefore acknowledge that we will not be responsible for any interruptions of service or issues affecting the
        Platform. Further, you acknowledge that we will not be responsible for any service interruptions, including
        power outages, system failures or other interruptions beyond our control.</p>
      <p><u>Changes to the Platform</u></p>
      <p>We reserve the right to make changes to the Platform, including, without limitation, updating content, removing
        content and editing, disabling, removing, condensing or otherwise changing the Features available on the
        Platform.</p>
      <p>You acknowledge that information may quickly become outdated. We are unable to warrant or guarantee that the
        content available on the Platform will be accurate, valid, current and reliable. If you find any information
        published by the Platform to be outdated, erroneous or false, you may send us a notice by email.</p>
      <ol start='22'>
        <li>
          <p><strong>CONTENT MONITORING AND STANDARDS</strong></p>
        </li>
      </ol>
      <p>We cannot review all Listings prior to their publication on the Platform. As such, we provide no guarantees as
        to the Listings and User content published on the Platform. You are solely responsible for any content you
        upload, share, send or otherwise transmit on the Platform, and you acknowledge that we are not liable for any
        infringing, objectionable or otherwise problematic content.</p>
      <p>If you determine that content published on the Platform violates these Terms and Conditions, you may send us a
        written notice by email. Please ensure the notice sent contains a link to the content in question, the reasons
        for which you deem the content to be infringing or violating, and a written confirmation that the information
        contained in the notice is true and correct to the best of your knowledge.</p>
      <p><u>Content Removal</u></p>
      <p>We are under no obligation to remove any content from the Platform, however, we may, at our discretion, remove
        content that infringes these Terms and Conditions or an applicable law or regulation. Accordingly, we may
        remove, delete, suspend or otherwise edit any content, communications, messages or exchanges that are obscene,
        defamatory, obsessive or otherwise illicit, as determined by us at our discretion.</p>
      <p><u>Infringement and Legal Recourses</u></p>
      <p>We reserve the right to apply a wide range of sanctions against the infringing User. Such sanctions may include
        the removal of the infringing User&rsquo;s account from the Platform, the suspension or termination of a
        User&rsquo;s access to the Platform, and the reporting of such conduct to the appropriate authorities, without
        limitation. You waive any rights you may have or have against us, and fully release us, for applying any such
        sanctions, including cooperating with authorities, performing investigations or terminating your access to the
        Platform, without limitation.</p>
      <p>We may disclose your identity or data to any third party to comply with a legal obligation or a court
        order.</p>
      <ol start='23'>
        <li>
          <p><strong>RATINGS AND REVIEWS</strong></p>
        </li>
      </ol>
      <p>Users may submit a rating and a review pursuant to entering into a transaction with another User on the
        Platform. Such ratings and reviews may be submitted upon the completion of a rental, its decline or its
        cancelation. The reviews submitted by a User must comply with these Terms. Reviews consist of adding a comment
        to the rating provided. When writing a comment, you should ensure it is the truthful, accurate and fair.</p>
      <p>Accordingly, the reviews submitted must be truthful, accurate and should be a proper reflection of the
        communication, service and/or Product rented.</p>
      <p>Ratings and reviews will only be displayed on a User&rsquo;s account once both the Renter and the Lender have
        reviewed each other.</p>
      <p>We reserve the right to remove, delete or withdraw ratings or ratings which are problematic, not genuine or
        unreasonable, as determined by us at our sole discretion. We may also remove, delete or withdraw ratings and/or
        reviews that violate these Terms.</p>
      <ol start='24'>
        <li>
          <p><strong>COPYRIGHT POLICY</strong></p>
        </li>
      </ol>
      <p>We comply with all applicable intellectual property and copyright laws. We are committed to preventing
        intellectual property and copyright infringement on the Platform.</p>
      <p>In compliance with the terms of the Digital Millennium Copyright Act (the &ldquo;<strong>DMCA</strong>&rdquo;),
        we encourage Users to promptly report any potential copyright infringement to our attention. If you own or
        possess rights to a trademark or intellectual property and you have reason to believe your intellectual property
        or trademark has been published on the Platform in violation of your rights, please send us a written notice to
        this effect.</p>
      <ol start='25'>
        <li>
          <p><strong>PERSONAL INFORMATION</strong></p>
        </li>
      </ol>
      <p>Our Privacy Policy is expressly incorporated in these Terms and Conditions by reference. All personal
        information provided on the Platform will be governed by the terms of the Privacy Policy.</p>
      <ol start='26'>
        <li>
          <p><strong>LINKS TO OTHER SITES</strong></p>
        </li>
      </ol>
      <p>Users acknowledge any links to third party website, sites, or apps provided on the Platform are for reference
        purposes only. The third party website, sites, or apps referenced on the Platform are independent third parties
        to us.</p>
      <p>You acknowledge that the inclusion of any links on the Platform does not imply our endorsement or any
        association with such third party links. These constitute independent third parties to us. The publication of
        the links, images, logos, and referrals on the Platform must not be interpreted as our affiliation with the
        same.</p>
      <p>You agree that we will not be responsible for the accuracy or the third party website, sites, or apps, the
        availability of the same or any content provided under or as part of the same. Your use of the third party links
        will be subject to the third party&rsquo;s policies and specific terms, and not the present Terms. You agree to
        use the third party links at your sole risk.</p>
      <p><u>Links to Social Media Platforms</u></p>
      <p>The Platform may include links to third party social media platforms (the &ldquo;<strong>Social Media
        Platforms</strong>&rdquo;), and may further allow Users to login to their accounts on the Platform via such
        Social Media Platforms. You acknowledge that we are not affiliated with the Social Media Platforms. The links to
        Social Media Platforms and the ability to login to your account on the Platform via Social Media Platforms are
        offered for convenience purposes only.</p>
      <p>We do not endorse any of the Social Media Platform, unless explicitly stated otherwise. The inclusion of any
        links to Social Media Platforms on the Platform does not imply our endorsement or association with such Social
        Media Platforms. We make no representations and provide no guarantees with the respect to (i) the availability
        of the Social Media Platforms and (ii) the functionality of the Social Media Platforms.</p>
      <ol start='27'>
        <li>
          <p><strong>GEOGRAPHICAL RESTRICTIONS</strong></p>
        </li>
      </ol>
      <p>The Platform is intended for Users located in the United States. We do not represent or guarantee that the
        Platform will be appropriate or available to Users located outside of the United States. Users of the Platform
        who do not reside in the United States recognize that all or part of the information on the Platform may not be
        available or suitable in regions outside the United States.</p>
      <p>If a User chooses to access the Platform from outside the United States, the User will be responsible for
        complying with all laws applicable in the User&rsquo;s country of residence.</p>
      <ol start='28'>
        <li>
          <p><strong>INDEMNITY</strong></p>
        </li>
      </ol>
      <p>You agree to indemnify and hold us, our affiliates, members, employees, contractors, agents, officers,
        directors and consultants, harmless from and against all losses, damages, liabilities, costs and expenses in
        connection with any claims, actions, proceedings, investigations, loss, fines, penalties, fees, charges,
        damages, or suits brought by a third party, arising out of (i) your use of the Platform; (ii) your gross
        negligence, recklessness or intentionally wrongful act(s), (iii) your violation or breach of any covenant,
        agreement or obligation under these Terms and Conditions; (iv) any incorrectness in any representation or
        warranty made in connection with these Terms and Conditions; or and/or (v) your execution or non-performance of
        your obligations under these Terms and Conditions.</p>
      <ol start='29'>
        <li>
          <p><strong>LIMITED LIABILITY</strong></p>
        </li>
      </ol>
      <p><u>Technical Issues</u></p>
      <p>You recognize that technical issues may arise when using the Platform. Such issues may delay, suspend,
        terminate or completely prevent access to the Platform. We are not liable for any loss, damage or other adverse
        effects caused by the Platform. Further, we disclaim all liability for: (i) issues affecting our servers,
        including unauthorized access to personal information and data; (ii) service interruptions or other issues
        affecting the Platform, its Features or its servers; and (iii) a virus or other harmful component impairing the
        Platform or a device used to access the Platform. For purposes of these Terms and Conditions, harmful material
        include viruses, spyware, adware or other programs and codes intended to harm computer software or hardware.</p>
      <p><u>Release</u></p>
      <p>You hereby release, discharge, and hold us, our affiliates, members, employees, contractors, agents, officers,
        directors and consultants, harmless from any and all liability, claims, demands, actions, and causes of action
        whatsoever arising out of or related to any loss, damage, or injury, disputes with a third party or other claim
        that may be caused by or arising out of the use of the Platform, any Products provided as part of a rental or
        due to use or misuse of the Platform and/or any Products.</p>
      <p><u>Exclusion of Damages</u></p>
      <p>To the fullest extent permitted by applicable law, we exclude full liability for any losses and damages arising
        out of or in connection with the Platform and the Features, including but not limited to indirect, general,
        special, punitive, incidental damages. This exclusion of liability applies even if we were aware of the
        possibility of such damages arising.</p>
      <p>Further, we are not liable for any indirect, special or consequential loss or damages arising from your access
        and/or usage of the Platform, including but not limited to loss of profits, loss of revenue, loss of business or
        anticipated earnings, loss of goodwill, loss of privacy, emotional distress, pain and suffering and personal
        injury.</p>
      <p><u>Permitted Scope</u></p>
      <p>The exclusions of warranty, limited liabilities, disclaimers and releases set forth in these Terms and
        Conditions are subject to the limitations and extent permitted by applicable laws. In the event of a conflict
        between the exclusion, limited liability, disclaimer or release and applicable laws, the applicable laws will
        prevail and control. The exclusion, limited liability, disclaimer or release will then be drafted to preserve
        the original intent while considering the legal limitations and obligations.</p>
      <ol start='30'>
        <li>
          <p><strong>DISCLAIMER OF WARRANTIES</strong></p>
        </li>
      </ol>
      <p>The Platform and the Features are provided on an &ldquo;as is&rdquo; basis and &ldquo;as available&rdquo;,
        without any warranties or guarantees of any kind. Without limiting the generality of the foregoing, we do not
        guarantee that the Platform will continuously be accessible and available for usage without error, omissions or
        interruption.</p>
      <p>To the fullest extent permitted by law, we disclaim all warranties, including those that are express, statutory
        or implied, such as implied warranties of title, non-infringement, merchantability and fitness for a particular
        purpose, and any warranties implied by any course of performance or usage of trade.</p>
      <p>We do not provide any warranties regarding the accuracy of the descriptions of the Products and the quality of
        such Products listed and provided for rental purposes.</p>
      <p>We cannot guarantee that issues or defects will be corrected in a timely manner or even corrected at all. Being
        aware of the risks associated with online activity, we cannot guarantee that the Platform will be free of
        harmful components. In consideration thereof, you agree to use the Platform at your sole risk.</p>
      <ol start='31'>
        <li>
          <p><strong>GOVERNING LAW</strong></p>
        </li>
      </ol>
      <p>These Terms and Conditions are governed by and construed in accordance with the laws of the State of New York
        (excluding any conflict of laws rule or principle, which might refer to the laws of another jurisdiction).</p>
      <ol start='32'>
        <li>
          <p><strong>DISPUTE RESOLUTION AND MANDATORY ARBITRATION</strong></p>
        </li>
      </ol>
      <p>All disputes arising out of or relating to these Terms and Conditions (each, a &ldquo;
        <strong>Dispute</strong>&rdquo;) will be governed and handled according to this Section.</p>
      <p><u>Jurisdiction of the Courts</u></p>
      <p>The following Disputes may be brought before the competent courts located in the State of New York: (i) a
        Dispute seeking an injunction or other equitable relief, or (ii) a Dispute to enforce any rights and obligations
        set forth in these Terms and Conditions.</p>
      <p>Any Dispute will be resolved exclusively by mandatory arbitration, except for those subject to the jurisdiction
        of the courts, and unless you opted out of the arbitration process in accordance with these Terms.</p>
      <p><u>Opting Out</u></p>
      <p>To opt out of the arbitration process, you must send us a written request prior to creating an account on the
        Platform. We will review opting out requests, and we will approve those which comply with the requirements set
        forth in these Terms. Any approved opting out requests will result in Disputes being transferred to and handled
        by the competent court in the State of New York, rather than arbitration. Save and except for the foregoing, all
        Disputes will be submitted to mandatory arbitration.</p>
      <p><u>Good Faith Negotiations</u></p>
      <p>Prior to undertaking any arbitration proceeding, you agree to use your best efforts to resolve the Dispute
        informally by good faith negotiations.</p>
      <p><u>Arbitration Proceedings</u></p>
      <p>If the Dispute cannot be resolved amicably within a reasonable delay, the Dispute will be referred to
        arbitration. The arbitration proceedings will be administered in accordance with the standards set forth by
        the <em>American Arbitration Association</em>. The arbitration proceedings will be presided by one (1)
        arbitrator, unless the nature of the Dispute requires otherwise. The language used during the arbitral
        proceedings will be English. The arbitration award will be confirmed in writing.</p>
      <p>The arbitration award may award damages and relief, similarly to a court of law. The arbitration award will be
        final and binding, not subject to appeal.</p>
      <p>Nothing in this section will prevent us from seeking relief before a court of competent jurisdiction to protect
        our interests.</p>
      <p><u>Remedies and Injunctions</u></p>
      <p>You acknowledge that any breach of these Terms and Conditions may cause us significant harm and damages,
        especially if such breach relates to data security, intellectual property or unauthorized usage of the Platform.
        Further, monetary damages may not be a sufficient remedy for a breach of these Terms and Conditions. We will be
        entitled, without waiving any other rights or remedies, to such injunctive or equitable relief as may be deemed
        proper by a court of competent jurisdiction.</p>
      <p><u>Cost Recovery</u></p>
      <p>If any litigation or arbitration proceeding is commenced in connection with these Terms and Conditions, the
        prevailing party, as determined by the court or arbitrator, will be entitled to claim all reasonable attorney
        fees, legal costs and necessary disbursements incurred in such action or proceeding.</p>
      <p><u>Waivers to Jury Trials Class Actions</u></p>
      <p>All arbitration proceedings in connection with Disputes will be individual and not class arbitration.
        Accordingly, Disputes will be brought in your individual capacity, and not as part of a class action or
        consolidated as part of another party&rsquo;s Dispute. You may not enter into any class action or consolidating
        claims with other users of the Platform. You further waive your right to a jury trial.</p>
      <p><u>Time Frame</u></p>
      <p>Any Dispute relating to these Terms and Conditions must be introduced within one (1) year of the event giving
        rise to the claim. Any Dispute introduced after the one (1) year delay will be barred.</p>
      <ol start='33'>
        <li>
          <p><strong>ASSIGNMENT AND WAIVERS</strong></p>
        </li>
      </ol>
      <p>We may assign all or part of our rights and obligations hereunder to any person, entity, business or company.
        You may not assign all or part of your rights and obligations hereunder to any person, without our prior written
        consent. Any assignment in violation of the foregoing will be null and void.</p>
      <p>No waiver of any of the provisions of these Terms and Conditions will be deemed or constitute a waiver of any
        other provision nor will such waiver constitute a continuing waiver unless otherwise expressly provided.</p>
      <ol start='34'>
        <li>
          <p><strong>NOTICES</strong></p>
        </li>
      </ol>
      <p>Any notice, consent, authorization, direction or other communication required or permitted to be given under
        these Terms and Conditions will be provided in writing and delivered using any delivery method with proof of
        receipt to our headquarters or by email.</p>
      <p>Runtize LLC</p>
      <p>418 Broadway</p>
      <p>Albany, NY</p>
      <p>12207</p>
      <p>Email:</p>
      <p>The following are acceptable delivery methods: (i) U.S. mail with postage prepaid, (ii) certified mail, return
        receipt requested, or (iii) email with acknowledgement of receipt.</p>
      <p>As we may communicate with you by email, you are responsible for providing your current and valid email
        address. If the email address you provided is invalid, any notice we sent to this email address will nonetheless
        be deemed effective as per these Terms.</p>
      <ol start='35'>
        <li>
          <p><strong>SEVERABILITY</strong></p>
        </li>
      </ol>
      <p>If any provision of these Terms and Conditions is held to be invalid, unlawful or unenforceable by law or by a
        competent authority, the provision in question will be severed from the Terms and Conditions. The remaining
        provisions of these Terms will remain unaffected, valid and enforceable.</p>
      <ol start='36'>
        <li>
          <p><strong>ENTIRE AGREEMENT</strong></p>
        </li>
      </ol>
      <p>These Terms and Conditions, together with any document referred to herein, constitutes the entire agreement
        between you and us with respect to the subject matter hereof. These Terms and Conditions supersede all prior
        negotiations and agreements between you and us regarding the subject matter hereof.</p>
      <ol start='37'>
        <li>
          <p><strong>QUESTIONS AND COMMENTS</strong></p>
        </li>
      </ol>
      <p>You may submit questions and comments regarding these Terms and Conditions to us by sending an email. Any
        reported breaches of these Terms and Conditions should also be submitted to us by email.</p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
