import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaInstagram.module.css';

const IconSocialMediaInstagram = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2320_4663)">
    <path d="M23.92 7.04C23.88 5.76 23.64 4.88 23.36 4.12C23.04 3.32 22.6 2.6 21.96 2C21.36 1.4 20.64 0.92 19.84 0.6C19.08 0.32 18.2 0.12 16.92 0.04C15.68 0 15.24 0 12 0C8.76 0 8.32 0 7.04 0.08C5.76 0.12 4.88 0.36 4.12 0.64C3.32 0.92 2.6 1.4 2 2C1.4 2.6 0.92 3.32 0.6 4.12C0.32 4.88 0.12 5.76 0.04 7.04C0 8.32 0 8.76 0 12C0 15.24 0 15.68 0.08 16.96C0.12 18.24 0.36 19.12 0.64 19.88C0.96 20.68 1.4 21.4 2.04 22C2.64 22.6 3.36 23.08 4.16 23.4C4.92 23.68 5.8 23.88 7.08 23.96C8.32 24 8.76 24 12 24C15.24 24 15.68 24 16.96 23.92C18.24 23.88 19.12 23.64 19.88 23.36C21.48 22.72 22.76 21.48 23.4 19.84C23.68 19.08 23.88 18.2 23.96 16.92C24 15.68 24 15.24 24 12C24 8.76 24 8.32 23.92 7.04ZM21.76 16.84C21.72 18 21.52 18.64 21.36 19.08C20.96 20.12 20.12 20.96 19.08 21.36C18.64 21.52 18.04 21.72 16.84 21.76C15.6 21.84 15.2 21.84 12 21.84C8.8 21.84 8.4 21.84 7.16 21.76C6 21.72 5.36 21.52 4.92 21.36C4.4 21.16 3.92 20.88 3.56 20.48C3.16 20.08 2.84 19.64 2.68 19.12C2.52 18.68 2.32 18.08 2.28 16.88C2.16 15.6 2.16 15.2 2.16 12C2.16 8.8 2.16 8.4 2.24 7.16C2.28 6 2.48 5.36 2.64 4.92C2.84 4.4 3.16 3.92 3.56 3.56C3.96 3.16 4.4 2.84 4.92 2.68C5.36 2.52 5.96 2.32 7.16 2.28C8.4 2.16 8.8 2.16 12 2.16C15.2 2.16 15.6 2.16 16.84 2.24C18 2.28 18.64 2.48 19.08 2.64C19.6 2.84 20.08 3.12 20.44 3.52C20.84 3.92 21.16 4.36 21.32 4.88C21.48 5.32 21.68 5.92 21.72 7.12C21.84 8.4 21.84 8.8 21.84 12C21.84 15.2 21.84 15.6 21.76 16.84Z" fill="#060531"/>
    <path d="M12 5.84009C8.60003 5.84009 5.84003 8.60009 5.84003 12.0001C5.84003 15.4001 8.60003 18.1601 12 18.1601C15.4 18.1601 18.16 15.4001 18.16 12.0001C18.16 8.60009 15.4 5.84009 12 5.84009ZM12 16.0001C9.80003 16.0001 8.00003 14.2001 8.00003 12.0001C8.00003 9.80009 9.80003 8.00009 12 8.00009C14.2 8.00009 16 9.80009 16 12.0001C16 14.2001 14.2 16.0001 12 16.0001Z" fill="#060531"/>
    <path d="M19.84 5.59991C19.84 6.39991 19.2 7.03991 18.4 7.03991C17.6 7.03991 16.96 6.39991 16.96 5.59991C16.96 4.79991 17.6 4.15991 18.4 4.15991C19.2 4.15991 19.84 4.79991 19.84 5.59991Z" fill="#060531"/>
    </g>
    <defs>
    <clipPath id="clip0_2320_4663">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  );
};

IconSocialMediaInstagram.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSocialMediaInstagram.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSocialMediaInstagram;
