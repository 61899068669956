import React from 'react';
import { TopbarSearchForm } from '../../forms';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';

const SectionSearchbar = props => {
  const { history, location, categoryPage } = props;

  const handleSubmit = values => {
    const keywords = values;
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        keywords
      )
    );
  };
  const search = <TopbarSearchForm categoryPage={categoryPage} onSubmit={handleSubmit} />;

  return <div>{search}</div>;
};

export default SectionSearchbar;
