import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import * as validators from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelect, IconCollection, LocationAutocompleteInputField } from '../../components';


import css from './EditListingDescriptionForm.module.css';
import { category, instantBooking, subCategory, subSubCategory } from '../../marketplace-custom-config';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    keepDirtyOnReinitialize={true}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        fieldId,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        handleModel,
        geolocationForListings,
        locationForListings,
        zipLocation,
        values,
        streetAddress,
        form
      } = formRenderProps;

      const titleMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.title',
      });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );


      // loaction
      const loactionHeadingText = intl.formatMessage({
        id: "EditListingDescriptionForm.loactionTitle",
      });

      // storeLink
      const storeLinkMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.storeLink' });

      // location 

      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressPlaceholder',
      });

      const linkInvalidMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.linkInvalid',
      });
      const linkValid = validators.linkValid(linkInvalidMessage);


      // categoryLabel
      const categoryLabel = intl.formatMessage({ id: 'EditListingDescriptionForm.categoryLabel' });
      const categoryPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.categoryPlaceholder',
      });
      const isMobile = typeof window !== 'undefined' && window.innerWidth < 767;

      // subCategoryLabel
      const subCategoryLabel = intl.formatMessage({ id: isMobile ? 'EditListingDescriptionForm.subCategory' : 'EditListingDescriptionForm.subCategoryLabel' });
      const subCategoryPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.subCategoryPlaceholder',
      });
      const subSubCategoryLabel = intl.formatMessage({ id: 'EditListingDescriptionForm.subSubCategory' });
      const subSubCategoryPlaceholder = intl.formatMessage({ id: 'EditListingDescriptionForm.subSubCategoryPlaceholder' });
      //instantBooking
      const instantBookingLabel = intl.formatMessage({ id: 'EditListingDescriptionForm.instantBookingLabel' });



      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } =
        fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = !values?.location?.selectedPlace || invalid || disabled || submitInProgress || !values.category || !values.subCategory || !values.description;
      const identity = v => v;

      const handleChange = location => {

        if (location.selectedPlace) {
          // Note that we use `onSubmit` instead of the conventional
          // `handleSubmit` prop for submitting. We want to autosubmit
          // when a place is selected, and don't require any extra
          // validations for the form.
          props.onSubmit({ location });
        }
      };

      const filteredSubCategory = subCategory.filter(e => e.parentId == values.category);
      const filteredSubSubCategory = subSubCategory.filter(e => e.parentId == values.subCategory);

      const onAutocompleteSelection = async (selectedPlace) => {
        const { address, addressComponents, origin, bounds } = selectedPlace;
        let city, state, zipCode, streetAddress, streetNumber;
        addressComponents && addressComponents.forEach((component) => {
          if (component.types.includes("street_number")) {
            streetNumber = component.long_name;
          } else if (component.types.includes("sublocality")) {
            city = component.long_name;
          }
          else if (component.types.includes("locality")) {
            city = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          } else if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          } else if (component.types.includes("route")) {
            streetAddress = component.long_name;
          }
          const { sw, ne } = bounds;
          const latLngArray = [sw.lng, sw.lat, ne.lng, ne.lat];
          form.change('city', city);
          form.change("isZipCodeValue", false)
          form.change('state', state);
          form.change('zipCode', zipCode);
          form.change('zipLocation', address);
          form.change('zipCodeLatLng', latLngArray);
          form.change('locations', { ...values.location, search: `${streetNumber} ${streetAddress}` });

        });

      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}


          <div className={css.fieldSelectCategory}>
            <FieldSelect
              id={`${fieldId}.category`}
              name="category"
              className={css.field}
              label={categoryLabel}
            >
              <option disabled value="" >
                {categoryPlaceholder}
              </option>
              {category.map(item => {
                return (
                  <option key={item.key} value={item.key}>
                    {item.value}
                  </option>
                );
              })}
            </FieldSelect>
            <FieldSelect
              id={`${fieldId}.subCategory`}
              name="subCategory"
              className={css.field}
              label={subCategoryLabel}
            >
              <option disabled value="" >
                {subCategoryPlaceholder}
              </option>
              {filteredSubCategory.map(item => {
                return (
                  <option key={item.key} value={item.key}>
                    {item.value}
                  </option>
                );
              })}
            </FieldSelect>
            {filteredSubSubCategory.length == 0 ? null : 
              <FieldSelect
              id={`${fieldId}.subSubCategory`}
              name="subSubCategory"
              className={css.field}
              label={subSubCategoryLabel}>
              <option disabled value="" >
                {subSubCategoryPlaceholder}
              </option>
              {filteredSubSubCategory.map(item => {
                return (
                  <option key={item.key} value={item.key}>
                    {item.value}
                  </option>
                );
              })}
            </FieldSelect>}
          </div>

          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          />
          <div className={css.brandStoreInput}>
            <div className={css.brandStoreIcon} onClick={() => handleModel("storeLink")}>
              <IconCollection name="BRAND_STORE_INFO" />
            </div>
            <FieldTextInput
              id="storeLink"
              name="storeLink"
              className={css.field}
              defaultValue="https://www.runtize.com"
              type="text"
              label={storeLinkMessage}
              validate={updateInProgress == true ? null : linkValid}
            />
          </div>

          <div className={classNames(css.descriptionLocation, css.instantBookInput)}>
            <div className={classNames(css.brandStoreIcon, css.descriptionIcon)} onClick={() => handleModel("location")}>
              <IconCollection name="BRAND_STORE_INFO" />
            </div>
            <LocationAutocompleteInputField
              className={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              name="location"
              label={loactionHeadingText}
              placeholder={addressPlaceholderMessage}
              useDefaultPredictions={false}
              geolocationForListings={geolocationForListings}
              locationForListings={locationForListings}
              format={identity}
              valueFromForm={values.location}
              zipLocation={streetAddress}
              onAutocompleteSelection={onAutocompleteSelection}
            />
          </div>

          <div className={css.instantBookInput}>
            <div className={classNames(css.brandStoreIcon, css.descriptionIcon)} onClick={() => handleModel("instantBooking")}>
              <IconCollection name="BRAND_STORE_INFO" />
            </div>
            <FieldSelect
              id={`${fieldId}.instantBooking`}
              name="instantBooking"
              className={css.field}
              label={instantBookingLabel}
            >
              <option>
                Select
              </option>

              {instantBooking.map(item => {
                return (
                  <option key={item.key} value={item.key}>
                    {item.label}
                  </option>
                );
              })}
            </FieldSelect>
          </div>

          <div className={css.brandStoreInput}>
            <div className={classNames(css.brandStoreIcon, css.descriptionIcon)} onClick={() => handleModel("description")}>
              <IconCollection name="BRAND_STORE_INFO" />
            </div>
            <FieldTextInput
              id="description"
              name="description"
              className={css.description}
              type="textarea"
              label={descriptionMessage}
              placeholder={descriptionPlaceholderMessage}
              validate={composeValidators(required(descriptionRequiredMessage))}
            />
          </div>

          <div className={css.buttonContent}>
            <Button
              className={css.submitButton}
              type="button"
            >
              Back
            </Button>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
};

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);