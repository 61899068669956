import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { TRANSITIONS } from '../../util/transaction';
import { parse } from '../../util/urlHelpers';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { queryUserReviews } from '../ProfilePage/ProfilePage.duck';
import { fetchCurrentUserListing } from '../../ducks/user.duck';
import { fetchUserListingSuccess } from '../SettingPage/SettingPage.duck';
// import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
// import { TransactionComplete } from '../../marketplace-custom-config';

// ================ Action types ================ //

export const COMPLETE_TRANSACTION_REQUEST = "COMPLETE_TRANSACTION_REQUEST"
export const COMPLETE_TRANSACTION_SUCCESS = "COMPLETE_TRANSACTION_SUCCESS"
export const COMPLETE_TRANSACTION_FAILURE = "COMPLETE_TRANSACTION_FAILURE"

export const FETCH_ORDERS_OR_SALES_REQUEST =
  'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS =
  'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR =
  'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';



// ================ Reducer ================ //
const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchTransaction: null,
  fetchTransactionProgress: false,
  allTransaction: [],
  transactionRefs: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case COMPLETE_TRANSACTION_REQUEST:
      return {
        ...state,
        fetchTransactionProgress: true
      }

    case COMPLETE_TRANSACTION_SUCCESS:

      return {
        ...state,
        fetchTransactionProgress: false,
        allTransaction: payload
      }

    case COMPLETE_TRANSACTION_FAILURE:
      return {
        ...state,
        fetchTransactionProgress: false,
        fetchTransaction: payload.error,
      }

    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchInProgress: false,
        fetchOrdersOrSalesError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //
export const fetchCompleteTransactionRequest = () => ({
  type: COMPLETE_TRANSACTION_REQUEST
})

export const fetchCompleteTransactionSuccess = (result) => ({
  type: COMPLETE_TRANSACTION_SUCCESS,
  payload: result
})

export const fetchCompleteTransactionFailure = (error) => ({
  type: COMPLETE_TRANSACTION_FAILURE,
  payload: error,

})

const fetchOrdersOrSalesRequest = () => ({
  type: FETCH_ORDERS_OR_SALES_REQUEST,
});
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// export const loadData = params => async (dispatch, getState, sdk) => {

//   try {
//     let transactions = [];
//     const queryNextTransaction = async (page, totalPages) => {
//       const newPage = page + 1;
//       const res = await sdk.transactions.query({ page: newPage });
//       const {
//         data: { data = null },
//       } = res;
//       transactions.push(data);
//       const meta = resp && resp.data && resp.data.meta;
//       const { totalItems = 0 } = meta;
//       if (totalItems > 0 && totalPages !== newPage) {
//         queryNextTransaction(newPage, totalPages);
//       }
//     };

//     const resp = await sdk.transactions.query({});
//     const {
//       data: { data = null },
//     } = resp;
//     transactions.push(data);

//     const meta = resp && resp.data && resp.data.meta;
//     const page = meta && meta.page;
//     const { totalPages = 0, totalItems = 0 } = meta;
//     if (totalItems > 0 && totalPages !== page) {
//       await queryNextTransaction(page, totalPages);
//     }

//     const transactionData = transactions && transactions.length && transactions.flat()
//     // dispatch(addMarketplaceEntities(txData));
//     dispatch(fetchCompleteTransactionSuccess(transactionData));
//     // return transactions;
//   } catch (error) {
//     dispatch(fetchCompleteTransactionFailure(storableError(error)));
//   }
// }
const INBOX_PAGE_SIZE = 100;
export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  const apiQueryParams = {
    lastTransitions: TRANSITIONS,
    include: [
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'listing',
      'listing.images'
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'metadata',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': [
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.square-small',
      'variants.square-small2x',
    ], page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const currentUser = getState().user.currentUser;
      dispatch(queryUserReviews(currentUser.id))
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      dispatch(fetchCurrentUserListing()).then((r)=>{
        dispatch(fetchUserListingSuccess(r?.data?.data))
       })
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};

