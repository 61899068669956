import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { IconAmenities, NamedLink } from '../../components';
import css from './SectionLocations.module.css';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink
      name="SearchPage"
      to={{ search: searchQuery }}
      className={css.location}
    >
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.locations}>
        <div className={css.textContent}>
          <IconAmenities type="pigWithDollar" />
          <div>
            <h2>
              <FormattedMessage id="SectionLocations.rentAndSave" />
            </h2>
            <p>
              <FormattedMessage id="SectionLocations.fromEssentialsToNew" />
            </p>
          </div>
        </div>
        <div className={css.textContent}>
          <IconAmenities type="dollarImage" />
          <div>
            <h2>
              <FormattedMessage id="SectionLocations.monetizeAndEarn" />
            </h2>
            <p>
              <FormattedMessage id="SectionLocations.snapSomePictures" />
            </p>
          </div>
        </div>

        <div className={css.textContent}>
          <IconAmenities type="vectorImage" />
          <div>
            <h2>
              <FormattedMessage id="SectionLocations.protectTheEnviroment" />
            </h2>
            <p>
              <FormattedMessage id="SectionLocations.thoughWereNotGonna" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
