import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { Modal } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.module.css';
import IconPopUp from '../IconPopUp/IconPopUp';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onPrevious,
    onManageDisableScrolling,

  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { sixHoursRental, multiDayRental } = currentListing && currentListing.attributes.publicData || {};
  const [isSixHours, setisSixHours] = useState(sixHoursRental);
  const [isMoreDays, setisMoreDays] = useState(multiDayRental);
  const [isEditPlanModalOpen,setisEditPlanModalOpen]=useState(false)
  const [isEditPlanModalClose,setisEditPlanModalClose]=useState(true)
  const { price } = currentListing.attributes;
  const rentalOptions = ["singleDayRental"];

  // Check if isSixHours is true and add it to the array
  if (isSixHours) {
    rentalOptions.push("sixHoursRental");
  }
  // Check if isMoreDays is true and add it to the array
  if (isMoreDays) {
    rentalOptions.push("multiDayRental");
  }
  
  
  const isPublished =
    currentListing.id &&
    currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = <FormattedMessage id="EditListingPricingPanel.createListingTitle" />

  const handleToggleState = () => {
    if (isSixHours) {
      setisSixHours(false);
    } else {
      setisSixHours(true)
    }
  }

  const handleToggle2State = () => {
    if (isMoreDays) {
      setisMoreDays(false);
    } else {
      setisMoreDays(true)
    }
  }
 
  useEffect(() => {
    if (isEditPlanModalClose) {
      setTimeout(() => {
        setisEditPlanModalOpen(true);
      }, 5000);
    }
  }, [isEditPlanModalClose]);


  const priceCurrencyValid =
    price instanceof Money ? price.currency === config.currency : true;
  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={{ price }}
      onSubmit={values => {
        const { price } = values;
        const updateValues = {
          price,
          publicData: {
            sixHoursRental: isSixHours,
            multiDayRental: isMoreDays,
            rentalOptions
          }
        };
        onSubmit(updateValues);
      }}
      onChange={onChange}
      onPrevious={onPrevious}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      handleToggleState={handleToggleState}
      handleToggle2State={handleToggle2State}
      isSixHours={isSixHours}
      isMoreDays={isMoreDays}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <div className={css.informationContents}>
        <div className={css.contentWrapper}>
          <h1 className={css.title}>{panelTitle}</h1>
          <div className={css.dividerLine}></div>
          {form}
          <Modal
            id="EditListingPricingPlan"
            isOpen={isEditPlanModalOpen}
            onClose={() => { setisEditPlanModalOpen(false), setisEditPlanModalClose(false) }}
            onManageDisableScrolling={onManageDisableScrolling}
            containerClassName={css.modalContainer}
            usePortal
            editListingPricingForm={true}
          >
            <div className={css.modalContent}><span><IconPopUp /></span>
              <p><FormattedMessage id="EditListingPricingPanel.popUpText" /></p>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  onPrevious: func.onPrevious,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
