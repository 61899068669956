import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './SectionFaq.module.css';
import Collapsible from 'react-collapsible';
import { FormattedMessage } from '../../util/reactIntl';
import { IconAmenities } from '../../components/';

const SectionFaq = props => {
  const { rootClassName, className, viewport } = props;
  const classes = classNames(rootClassName || css.root, className);
  const randomText = <FormattedMessage id="SectionFaq.randomText" />;

  const downArrow = <IconAmenities type="arrowPointingDown" />;
  const isMobile = viewport && viewport.width < 767;
  return (
    <div className={classNames(classes, css.faqSection)}>
      <div className={css.sectionMain}>
        <div className={css.sectionContainer}>
          <h1>
            <FormattedMessage
              id="SectionFaq.frequentlyAskedQuestions"
              values={{
                Questions: <span className={css.textColor}>Questions</span>,
              }}
            />
          </h1>

         {isMobile ? <div className={css.faqContent}>
            
              <div className={css.faqText}>
                <Collapsible
                  trigger={
                    <FormattedMessage id="SectionFaq.whatIsRuntizeMarketplace" />
                  }
                >
                  {randomText}
                </Collapsible>
                {downArrow}
              </div>
              <div className={css.faqText}>
                <Collapsible
                  trigger={
                    <FormattedMessage id="SectionFaq.howDoExtensionsWork" />
                    
                  }
                >
                  {randomText}
                </Collapsible>
                {downArrow}
              </div>
              <div className={css.faqText}>
                <Collapsible
                  trigger={
                    <FormattedMessage id="SectionFaq.whatAreTheDifferentTypes" />
                 
                  }
                >
                  {randomText}
                </Collapsible>
                {downArrow}
              </div>
      
              <div className={css.faqText}>
                <Collapsible
                  trigger={
                    <FormattedMessage id="SectionFaq.whoCanUseMarketplaceExtensions" />
                  }
                >
                  {randomText}
                </Collapsible>
                {downArrow}
              </div>
              <div className={css.faqText}>
                <Collapsible
                  trigger={
                    <FormattedMessage id="SectionFaq.willAnyoneInMyOrganization" />
                 
                }
                >
                  {randomText}
                </Collapsible>
                {downArrow}
              </div>
              <div className={css.faqText}>
                <Collapsible
                  trigger={
                    <FormattedMessage id="SectionFaq.howDoIfindFree" />
                  }
                >
                  {randomText}
                </Collapsible>
                {downArrow}
              </div>
          </div> :
          <div className={css.faqContent}>
            
              <div className={css.faqText}>
                <Collapsible
                  trigger={
                    <FormattedMessage id="SectionFaq.whatIsRuntizeMarketplace" />
                  }
                >
                  {randomText}
                </Collapsible>
                {downArrow}
              </div>
              <div className={css.faqText}>
                <Collapsible
                  trigger={
                    <FormattedMessage id="SectionFaq.howDoExtensionsWork" />
                    
                  }
                >
                  {randomText}
                </Collapsible>
                {downArrow}
              </div>
              <div className={css.faqText}>
                <Collapsible
                  trigger={
                    <FormattedMessage id="SectionFaq.whoCanUseMarketplaceExtensions" />
                  }
                >
                  {randomText}
                </Collapsible>
                {downArrow}
              </div>
      
              <div className={css.faqText}>
                <Collapsible
                  trigger={
                    <FormattedMessage id="SectionFaq.howDoIfindFree" />
                  }
                >
                  {randomText}
                </Collapsible>
                {downArrow}
              </div>
              <div className={css.faqText}>
                <Collapsible
                  trigger={
                    <FormattedMessage id="SectionFaq.willAnyoneInMyOrganization" />
                 
                }
                >
                  {randomText}
                </Collapsible>
                {downArrow}
              </div>
              <div className={css.faqText}>
                <Collapsible
                  trigger={
                    <FormattedMessage id="SectionFaq.whatAreTheDifferentTypes" />
                  }
                >
                  {randomText}
                </Collapsible>
                {downArrow}
              </div>
          </div> }
        </div>
      </div>
    </div>
  );
};

SectionFaq.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionFaq.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFaq;
