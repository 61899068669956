import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    ExternalLink,
    IconAmenities,
    Button,
    NamedLink,
    IconCollection,
} from '../../components';
import { useHistory } from "react-router-dom";
import css from './ListingDonePage.module.css';
import { FormattedMessage } from 'react-intl';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
const CloseIcon = () => {
    return (
        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.768 1L1 15.2019M1 1L13.768 15.2019" stroke="#FD4E50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
const ListingDonePage = () => {
    const history = useHistory();
    const { siteTwitterHandle, siteFacebookPage } = config;
    const siteTwitterPage = twitterPageURL(siteTwitterHandle);
    // prettier-ignore
    return (
        <StaticPage
            title="Congratulations!"
            schema={{
                '@context': 'http://schema.org',
                '@type': 'ListingDonePage',
                description: 'About Yogatime',
                name: 'About page',
            }}
        >
            <LayoutWrapperTopbar>
                <TopbarContainer active="faq" />
            </LayoutWrapperTopbar>
            <div className={css.contentWrapper}>
                <div className={css.mainWrapper}>
                    <NamedLink name="LandingPage" className={css.closeButton}>
                       <IconCollection name="DONE_CROSS_ICON"/>
                    </NamedLink>
                    <div className={css.contentMain}>
                        <IconAmenities type="congratulations" />
                        <div>
                            <h2>
                                <FormattedMessage id="ListingDonePage.congratulation" />
                            </h2>
                            <p>
                                <FormattedMessage id="ListingDonePage.yourNewSourceOfIncome" />
                            </p>
                        </div>
                        <Button onClick={() => {
                            history.push(
                                createResourceLocatorString(
                                    'ManageListingPage',
                                    routeConfiguration(),
                                    {},
                                    {}
                                )
                            );
                        }}>
                            <FormattedMessage id="ListingDonePage.continue" />
                        </Button>
                    </div>
                </div>
            </div>

            <LayoutWrapperFooter>
            </LayoutWrapperFooter>
        </StaticPage>
    );
};

export default ListingDonePage;
