import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionHero.module.css';
import { TopbarSearchForm } from '../../forms';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import IconAmenities from '../IconAmenities/IconAmenities';
import ItemCard from '../ItemCard/ItemCard';
import IconCollection from '../IconCollection/IconCollection';
import heroGiter from '../../assets/sectionHero/heroImage1.png'
import heroBike from '../../assets/sectionHero/heroImage2.png'
import heroOven from '../../assets/sectionHero/heroImage3.png'

const SectionHero = props => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const { rootClassName, className, history, location } = props;

  const handleSubmit = values => {
    const keywords = values;
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        keywords
      )
    );
  };

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={handleSubmit}
      landingHero={true}
    />
  );

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.sectionContainer}>
        <div className={css.heroContentLeft}>
          <h1
            className={classNames(css.heroMainTitle, {
              [css.heroMainTitleFEDelay]: mounted,
            })}
          >
            <FormattedMessage id="SectionHero.title" />
          </h1>
          <h1
            className={classNames(css.heroMainTitle, css.textColor, {
              [css.heroMainTitleFEDelay]: mounted,
            })}
          >
            <FormattedMessage id="SectionHero.titleAddOn" />
          </h1>
          <h2
            className={classNames(css.heroSubTitle, {
              [css.heroSubTitleFEDelay]: mounted,
            })}
          >
            <FormattedMessage id="SectionHero.subTitle" />
          </h2>
          <div className={css.searchInput}>
            <div className={css.searchIcon}>
              <IconAmenities type="searchLens" />
            </div>
            {search}
          </div>
        </div>
        <div className={css.heroContentRight}>
          <div className={css.heroBackground}>
            <IconCollection name="HERO_BACKGROUND" />
          </div>
          <div className={css.twoCard}>
            <div className={css.firstCard}>
              <ItemCard
                img={heroBike}
                miles="2.1 Miles away"
                title="Bronx m, New York"
                days="$7 day"
              />
              <div className={css.starReview}>
                <IconCollection name="STAR_REVIEW" />
                <p>REVIEWS</p>
              </div>
            </div>
            <div className={css.secondCard}>
              <ItemCard
                img={heroOven}
                miles="0.4 Miles away"
                title="New York, NY"
                days="$18 Six hours"
              />
            </div>
          </div>
          <div>
            <div className={css.thirdCard}>
              <ItemCard
                img={heroGiter}
                miles="1.3 Miles away"
                title="Brooklyn, New York"
                days="$16 day"
              />
              <div className={css.identityVerified}>
                <div className={css.identityVerifiedIcon}>
                <IconCollection name="IDENTITY_VERIFIED" />
                </div>
                <p>IDENTITY VERIFIED</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
