import React from 'react';
import PhoneInput from 'react-phone-number-input';
import css from './PhoneNumberInput.module.css';

export default function PhoneNumberInput(props) {
  const {
    changePhoneValue,
    formId,
    phoneValue,
    setPhoneValue,
    invalidPhoneNumber,
    phonePlaceholder,
    phoneLabel,
    phoneNumber,
    phoneValidError,
  } = props;
  return (
    <div className={css.formContent}>
      <span>{phoneLabel}</span>
      <PhoneInput
        defaultCountry="US"
        // placeholder={phonePlaceholder}
        id={formId ? `${formId}.phone` : 'phone'}
        value={phoneValue || phoneNumber}
        onChange={phone => setPhoneValue(phone)}
        onMouseOut={changePhoneValue}
      />
      {invalidPhoneNumber && <p className={css.redText}>{phoneValidError}</p>}
    </div>
  );
}
