import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactImageGallery from 'react-image-gallery';

import { propTypes } from '../../util/types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { IconArrowHead, ResponsiveImage } from '../../components';

// Copied directly from
// `node_modules/react-image-gallery/styles/css/image-gallery.css`. The
// copied file is left unedited, and all the overrides are defined in
// the component CSS file below.
import './image-gallery.css';

import css from './ImageCarousel.module.css';

const ListingImageGallery = props => {
  const { intl, rootClassName, viewport, className, images, imageVariants } = props;
  const isMobile = viewport && viewport.width > 767;
  
  class MyGallery extends React.Component {
    render() {
      return <ImageGallery items={images} />;
    }
  }

  const items = images.map((img, i) => { 
    return {
      // We will only use the image resource, but react-image-gallery
      // requires the `original` key from each item.
      thumbnail:img?.attributes?.variants?.['default']?.url,
      original: img?.attributes?.variants?.['default']?.url,
      alt: intl.formatMessage(
        { id: 'ImageCarousel.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      image: img,
    };
  });

  // If no image is given, rendere empty image.
  if (items.length === 0) {
    const classes = classNames(rootClassName || css.noImage, className);
    return (
      <ResponsiveImage className={classes} image={null} variants={[]} alt="" />
    );
  }

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <ReactImageGallery
        additionalClass={classes}
        items={items}
        showBullets={true}
        thumbnailPosition= 'left' 
        showThumbnails= {isMobile ? true :  false}
      />
    </>
  );
};

ListingImageGallery.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string, arrayOf } = PropTypes;

ListingImageGallery.propTypes = {
  rootClassName: string,
  className: string,
  images: arrayOf(propTypes.image).isRequired,
  imageVariants: arrayOf(string).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ListingImageGallery);
