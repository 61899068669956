/**
 * Export loadData calls from ducks modules of different containers
 */
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as DashboardListingsPageLoader } from "./DashboardListingsPage/DashboardListingsPage.duck"
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { loadData as SupportPageLoader } from './SupportPage/SupportPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as SavedListingPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as DashboardPageLoader } from './DashboardPage/DashboardPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as BookingRequestPageLoader } from "./BookingRequestPage/BookingRequestPage.duck"
import {loadData  as ReservationsPageLoader} from "./ReservationsPage/ReservationsPage.duck"
import {loadData as SettingPageLoader} from "./SettingPage/SettingPage.duck"
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';
import { loadData as RentalPageLoader } from './RentalPage/RentalPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    RentalPage: {
      loadData: RentalPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    SupportPage: {
      loadData: SupportPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    DashboardListingsPage: {
      loadData: DashboardListingsPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    DashboardPage: {
      loadData: DashboardPageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    SavedListingPage: {
      loadData: SavedListingPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    BookingRequestPage: {
      loadData: BookingRequestPageLoader
    },
    ReservationsPage: {
      loadData: ReservationsPageLoader
    },
    SettingPage: {
      loadData: SettingPageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
  };
};

export default getPageDataLoadingAPI;
