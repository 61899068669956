import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPopUp.module.css';

const IconPopUp = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M52.0048 99.4558C55.6434 99.4558 58.593 97.7958 58.593 95.7482C58.593 93.7005 55.6434 92.0406 52.0048 92.0406C48.3663 92.0406 45.4166 93.7005 45.4166 95.7482C45.4166 97.7958 48.3663 99.4558 52.0048 99.4558Z" fill="#424242" />
      <path d="M52.0055 37.0558C40.5183 37.0558 31.2 45.2042 31.2 55.2506C31.2 57.8714 32.0283 60.6742 33.2435 63.1442C34.9491 66.6178 36.8073 69.1866 37.3523 70.133C38.8672 72.7642 38.6492 75.5358 39.0688 77.2726C39.859 80.5174 42.224 81.6926 52 81.6926C61.7761 81.6926 63.8795 80.5746 64.7568 77.5846C65.3563 75.5514 64.7732 73.331 66.2826 70.705C66.8276 69.7586 69.0454 66.623 70.7565 63.1442C71.9717 60.6742 72.8 57.8714 72.8 55.2506C72.8109 45.2042 63.4926 37.0558 52.0055 37.0558Z" fill="#FFD600" />
      <path d="M52.0042 82.0826C58.6072 82.0826 63.96 81.0442 63.96 79.7634C63.96 78.4825 58.6072 77.4442 52.0042 77.4442C45.4012 77.4442 40.0485 78.4825 40.0485 79.7634C40.0485 81.0442 45.4012 82.0826 52.0042 82.0826Z" fill="#B26500" />
      <path d="M52.0042 82.0826C58.6072 82.0826 63.96 81.0442 63.96 79.7634C63.96 78.4825 58.6072 77.4442 52.0042 77.4442C45.4012 77.4442 40.0485 78.4825 40.0485 79.7634C40.0485 81.0442 45.4012 82.0826 52.0042 82.0826Z" fill="#B26500" />
      <path d="M52.0054 80.8346C56.8177 80.8346 60.7188 80.355 60.7188 79.7634C60.7188 79.1718 56.8177 78.6922 52.0054 78.6922C47.1931 78.6922 43.2919 79.1718 43.2919 79.7634C43.2919 80.355 47.1931 80.8346 52.0054 80.8346Z" fill="#FFA000" />
      <path d="M46.175 64.4962C45.8372 65.3074 44.9598 66.9766 45.4176 67.7254C46.4802 69.4674 49.0141 70.0914 49.0141 71.693C49.0141 74.137 47.1505 82.0566 47.1505 82.0566M57.4714 64.205C57.4714 64.205 58.6921 66.701 58.1744 67.819C57.7875 68.651 55.4606 69.9926 55.1609 70.2162C53.7714 71.2562 55.6187 82.0618 55.6187 82.0618" stroke="#B26500" strokeWidth="2" stroke-miterlimit="10" />
      <path d="M46.1748 64.4962C48.0494 60.9602 49.0139 52.8898 46.6325 53.3006C42.6164 53.9922 47.5644 67.2522 50.0002 65.7286C52.436 64.205 55.455 53.3266 51.7494 53.285C48.0439 53.2434 52.0001 67.1482 54.5177 65.6038C56.5067 64.3818 61.362 53.3006 57.3731 53.3006C53.9782 53.3006 57.4767 64.205 57.4767 64.205" stroke="white" strokeWidth="2" stroke-miterlimit="10" />
      <path d="M63.9334 80.247C63.9334 80.247 60.7946 81.6926 52.0049 81.6926C43.2151 81.6926 40.0763 80.247 40.0763 80.247C40.0763 80.247 39.8802 82.9198 40.5286 84.1314C41.3079 85.5874 41.9073 86.0918 41.9073 86.0918L42.2343 87.5738L42.1035 87.9638C41.9346 88.4734 42.0544 89.035 42.4304 89.4354L42.7138 89.737L43.0299 91.1618L42.9209 91.4478C42.7138 91.9938 42.8555 92.6022 43.2805 93.0182L43.4876 93.221L43.7437 94.3858C43.7437 94.3858 45.0407 97.0274 52.0049 97.0274C58.9691 97.0274 60.266 94.3858 60.266 94.3858L60.2878 94.287L60.4295 94.1518C60.7129 93.8866 60.8055 93.4914 60.6693 93.1378L60.5875 92.935L60.9254 91.3958L61.5194 90.7978C61.8136 90.5014 61.879 90.0646 61.6883 89.7006L61.4158 89.1858L61.7591 87.6414L61.9771 87.4802C62.2986 87.1422 62.3041 86.6326 62.1624 86.2842C62.0534 86.0086 62.1406 85.6966 62.364 85.4938C62.7073 85.1818 63.1487 84.7086 63.4757 84.121C64.1459 82.9302 63.9334 80.247 63.9334 80.247Z" fill="#82AEC0" />
      <path d="M41.9083 86.0918L42.2026 87.5842C45.3741 87.5686 49.6082 87.7194 57.7331 86.487C59.199 86.1698 60.9591 85.5406 59.0137 85.8006C59.0137 85.7954 51.5536 86.4766 41.9083 86.0918ZM42.9982 91.1722C46.5075 91.115 53.6788 90.7822 61.3841 89.1858L61.7274 87.6414C53.6679 89.3886 46.0879 89.7006 42.6821 89.7422L42.9982 91.1722ZM60.8937 91.3906C53.7606 92.8674 47.0852 93.247 43.4723 93.3302L43.7066 94.391C43.7066 94.391 43.821 97.6826 51.9677 97.6826C60.1145 97.6826 60.2289 94.391 60.2289 94.391C60.2289 94.391 56.7522 95.3374 52.4745 94.8746C52.4294 94.875 52.3847 94.8668 52.3429 94.8507C52.3012 94.8345 52.2631 94.8107 52.231 94.7805C52.1661 94.7195 52.1292 94.6365 52.1285 94.5496C52.1278 94.4627 52.1633 94.3791 52.2271 94.3172C52.291 94.2553 52.378 94.2201 52.4691 94.2194C55.0248 93.897 58.0764 93.4186 60.5558 92.9298L60.8937 91.3906Z" fill="#2F7889" />
      <path d="M40.1143 80.247C40.1143 80.247 43.6346 81.6926 51.972 81.6926C60.3094 81.6926 63.8951 80.247 63.8951 80.247" stroke="#82AEC0" strokeWidth="3.997" stroke-miterlimit="10" strokeLinecap="round" />
      <path d="M44.31 40.3422C46.4407 39.843 48.6694 40.0926 50.1953 41.8606C50.6257 42.3598 50.9582 42.9526 51.0344 43.5974C51.187 44.8402 50.3805 46.0154 49.4214 46.8786C46.6859 49.3434 42.8005 50.5394 41.035 53.9402C40.6208 54.7358 40.2939 55.5938 39.6617 56.2438C39.0296 56.8938 37.9779 57.289 37.1551 56.873C36.7191 56.6546 36.4194 56.2438 36.1742 55.833C34.6266 53.2018 34.741 49.8478 36.1033 47.123C37.6019 44.1382 40.8878 41.143 44.31 40.3422Z" fill="#FFFF8D" />
      <path d="M42.4418 82.7794C41.9623 82.5714 42.153 81.885 42.6761 81.9214C44.4308 82.0514 47.417 82.2126 51.3678 82.2126C55.531 82.2126 59.193 81.9162 61.3291 81.6978C61.8577 81.6458 62.0593 82.3374 61.5743 82.5506C59.9286 83.289 56.9151 84.1314 51.7492 84.1314C46.7086 84.1314 43.9295 83.419 42.4418 82.7794Z" fill="#FFD600" />
      <path d="M45.433 88.0314C45.0679 88.1562 44.6919 88.3278 44.5066 88.6502C44.4412 88.7698 44.4031 88.905 44.4303 89.0402C44.4739 89.2378 44.6647 89.3782 44.8554 89.4666C45.2368 89.6434 45.6673 89.6902 46.0869 89.6954C46.9534 89.7058 47.8144 89.5498 48.6699 89.3938C48.926 89.347 49.1876 89.3002 49.4165 89.1806C49.6453 89.061 49.8415 88.8582 49.8796 88.6138C49.9341 88.2862 49.6889 87.969 49.3892 87.813C48.3538 87.267 46.4793 87.6726 45.433 88.0314ZM46.245 91.4582C45.8799 91.583 45.5039 91.7546 45.3186 92.077C45.2532 92.1966 45.215 92.3318 45.2423 92.467C45.2859 92.6646 45.4766 92.805 45.6673 92.8934C46.0488 93.0702 46.4793 93.117 46.8989 93.1222C47.7653 93.1326 48.6263 92.9766 49.4818 92.8206C49.738 92.7738 49.9995 92.727 50.2284 92.6074C50.4573 92.4878 50.6534 92.285 50.6916 92.0406C50.7461 91.713 50.5009 91.3958 50.2012 91.2398C49.1658 90.699 47.2858 91.1046 46.245 91.4582Z" fill="#94D1E0" />
      <path d="M44.3819 78.7598C44.8778 78.8066 45.4009 78.765 45.8205 78.5102C46.2401 78.2554 46.5071 77.7354 46.3382 77.2882C46.251 77.0542 46.0603 76.867 45.875 76.6902C45.4663 76.3054 45.0576 75.9206 44.6543 75.5358C44.2021 75.1042 43.7498 74.6778 43.2593 74.2878C42.5019 73.6846 41.4829 73.1178 40.4856 73.5598C39.6355 73.9342 39.4339 74.6726 39.8099 75.4474C40.611 77.075 42.4746 78.5726 44.3819 78.7598Z" fill="#FFFF8D" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M85.4253 55.9C85.1645 55.6547 84.9632 55.4769 84.7742 55.2862C82.939 53.4347 81.1311 51.5529 79.2563 49.7444C78.6606 49.1699 78.7479 48.845 79.2631 48.3118C84.9268 42.4515 90.5786 36.5785 96.2118 30.6871C96.7376 30.1374 97.0577 30.0594 97.6168 30.6497C99.4475 32.5831 101.318 34.4785 103.209 36.3498C103.678 36.8132 103.63 37.082 103.204 37.5223C97.4645 43.4606 91.7395 49.4142 86.0091 55.3622C85.834 55.5437 85.6385 55.7047 85.4253 55.9Z" fill="#FD4E50" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M24.6994 48.8105C24.7152 49.2994 24.3918 49.465 24.1749 49.6868C22.3536 51.5505 20.4976 53.3788 18.7101 55.2755C18.1459 55.8741 17.8563 55.7505 17.3526 55.2219C11.7303 49.3275 6.0923 43.449 0.434006 37.5916C-0.0876895 37.0514 -0.189584 36.7691 0.408614 36.1887C2.27714 34.3763 4.09391 32.506 5.88819 30.6156C6.43367 30.0407 6.76025 30.0774 7.29448 30.6367C12.883 36.4882 18.4963 42.3145 24.1016 48.1491C24.323 48.3792 24.5307 48.6228 24.6994 48.8105Z" fill="#FD4E50" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M52.0527 3.97275C52.0544 12.3404 52.0564 20.708 52.0584 29.0756C50.4661 29.07 48.453 29.6452 47.4023 28.8922C46.3901 28.1673 47.1028 26.0332 47.0935 24.5232C47.0549 18.1046 47.1028 11.6849 47.0519 5.26621C47.0436 4.19356 47.3232 3.82103 48.4151 3.91358C49.6205 4.01573 50.8398 3.95953 52.0527 3.97275Z" fill="#FD4E50" />
    </svg>

  );
};

const { string } = PropTypes;

IconPopUp.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPopUp.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPopUp;
