import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    acceptInProgress,
    declineInProgress,
    acceptSaleError,
    declineSaleError,
    onAcceptSale,
    onDeclineSale,
    isCompleteState,
    reviewByCusmoter,
    reviewByProvider,
    onOpenReviewModal,
    isMobile,
    modalHandle,
  } = props;

  const buttonsDisabled = acceptInProgress || declineInProgress;

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
      </div>
      <div className={classNames(css.actionButtonWrapper, isCompleteState ||reviewByCusmoter||reviewByProvider ? css.reviewButton : css.reviewButton)}>
        <PrimaryButton
          inProgress={acceptInProgress}
          disabled={buttonsDisabled}
          onClick={isCompleteState || reviewByCusmoter || reviewByProvider ? onOpenReviewModal:onAcceptSale}
        >
         {isCompleteState ||reviewByCusmoter||reviewByProvider ? "Leave review":  <FormattedMessage id="TransactionPanel.acceptButton" /> }
        </PrimaryButton>
        {
          isCompleteState || reviewByCusmoter ||reviewByProvider? null :
            <SecondaryButton
              inProgress={isMobile ? false : declineInProgress}
              disabled={buttonsDisabled}
              onClick={isMobile ? () => modalHandle() : onDeclineSale}
            >
          <FormattedMessage id="TransactionPanel.declineButton" />
        </SecondaryButton>
        }
        
      </div>
    </div>
  ) : null;
};

export default SaleActionButtonsMaybe;
