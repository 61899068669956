import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './SectionDiscount.module.css';
import Slider from 'react-slick';
import classNames from 'classnames';

const SectionDiscount = () => {
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 920;

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={css.discountSectionWrapper}>
      {/* {isMobile ?
        <div className={css.mobileSlider}>
          <div className={css.discountContentsFirstSlide}>
            <h3>
              <FormattedMessage id="LandingPage.discountPercentage" />
            </h3>
            <p>
              <FormattedMessage id="LandingPage.discountDate" />
            </p>
          </div>
          <div className={css.discountContentsSecondSlide}>
            <h3>
              <FormattedMessage id="LandingPage.discountPercentage2" />
            </h3>
            <p>
              <FormattedMessage id="LandingPage.discountDate2" />
            </p>
          </div>
          <div className={css.discountContentsThirdSlide}>
            <h3>
              <FormattedMessage id="LandingPage.discountPercentage3" />
            </h3>
            <p>
              <FormattedMessage id="LandingPage.discountDate3" />
            </p>
          </div>
        </div> : */}
      <Slider {...settings}>
        <div className={css.discountContentsSecondSlide}>
          <h3>
            <FormattedMessage id="LandingPage.discountPercentage2" />
            <p className={classNames(css.statusUpcoming, css.comingSoon)}>
              <span className={css['innerStatus']}>Coming Soon</span>
            </p>
          </h3>
          <p>
            <FormattedMessage id="LandingPage.discountDate2" />
          </p>
        </div>
        <div className={css.discountContentsFirstSlide}>
          <h3>
            <FormattedMessage id="LandingPage.discountPercentage" />
          </h3>
          <p>
            <FormattedMessage id="LandingPage.discountDate" />
          </p>
        </div>
        <div className={css.discountContentsThirdSlide}>
          <h3>
            <FormattedMessage id="LandingPage.discountPercentage3" />
          </h3>
          <p>
            <FormattedMessage id="LandingPage.discountDate3" />
          </p>
        </div>
      </Slider>
      {/* } */}
    </div>
  );
};

export default SectionDiscount;
