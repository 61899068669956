import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './SectionRentYourStuff.module.css';
import rentYourStuff from '../../assets/rentYourStuff/rentYourStuff.png';
import rentYourMobile from '../../assets/rentYourStuff/rentYourMobile.png';
import { FormattedMessage } from '../../util/reactIntl';
import IconAmenities from '../IconAmenities/IconAmenities';

const SectionRentYourStuff = props => {
  const { rootClassName, className, viewport } = props;
  const classes = classNames(rootClassName || css.root, className);
  const arrowImg = <IconAmenities type="arrowImg" />;
  const isMobile = viewport && viewport.width < 767;
  return (
    <div className={classes}>
      <div className={css.sectionContainer}>
        <div>
          {isMobile ? (
            <img alt="girl with keys" src={rentYourMobile} />
          ) : (
            <img alt="girl with keys" src={rentYourStuff} />
          )}
        </div>
        <div>
          <h1>
            <FormattedMessage id="SectionRentYourStuff.rentYourStuff" />
          </h1>
          <h1 className={css.textColor}>
            <FormattedMessage id="SectionRentYourStuff.earnAnEffortessSide" />
          </h1>
          <div className={css.listText}>
            <div>
              {arrowImg}
              <h5>
                <FormattedMessage id="SectionRentYourStuff.earnMoneyDoingNothing" />
              </h5>
            </div>
            <div>
              {arrowImg}
              <h5>
                <FormattedMessage id="SectionRentYourStuff.feelConfidentVerifiedUsers" />
              </h5>
            </div>
            <div>
              {arrowImg}
              <h5>
                <FormattedMessage id="SectionRentYourStuff.smoothProcessTakesLess" />
              </h5>
            </div>
            <div>
              {arrowImg}
              <h5>
                <FormattedMessage id="SectionRentYourStuff.noWorriesKeeping" />
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionRentYourStuff.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionRentYourStuff.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionRentYourStuff;
