import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Button,
  FieldCheckboxGroup,
  FieldSelect,
  Form,
} from '../../components';

import css from './EditListingFeaturesForm.module.css';
import {
  deliveryDistance,
  deliveryPrice,
} from '../../marketplace-custom-config';

const EditListingFeaturesFormComponent = (props) => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={(formRenderProps) => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        handleToggleState,
        isDelivery,
        onPrevious,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const options = findOptionsForSelectFilter('yogaStyles', filterConfig);
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <div className={css.hourRentals}>
            <h4 className={css.rentalsText}>
              {' '}
              <FormattedMessage id="EditListingFeatureForm.deliveryText" />
            </h4>
            <div
              className={css.checkboxWrapper}
              onClick={() => {
                handleToggleState();
              }}
            >
              <div
                className={classNames(css.ball, {
                  [css.toggled]: isDelivery,
                })}
              >
                {' '}
              </div>
            </div>
          </div>
          <div>
            <p className={css.description}>
              <FormattedMessage id="EditListingFeatureForm.deliveryBottomText" />
            </p>
          </div>
          <div className={css.noteContents}>
            <span className={css.noteText}>
              <FormattedMessage id="EditListingFeatureForm.deliveryNote" />
            </span>
            <span>
              <FormattedMessage id="EditListingFeatureForm.deliveryNoteText" />
            </span>
          </div>
          <div className={css.inputsContents}>
            <FieldSelect
              id="deliveryDistance"
              name="deliveryDistance"
              label="Distance"
            >
              {deliveryDistance.map((e) => {
                return (
                  <option key={e.key} value={e.key}>
                    {e.value}
                  </option>
                );
              })}
            </FieldSelect>
            <FieldSelect id="deliveryPrice" name="deliveryPrice" label="Price">
              {deliveryPrice.map((e) => {
                return (
                  <option key={e.key} value={e.key}>
                    {e.value}
                  </option>
                );
              })}
            </FieldSelect>
          </div>
          <div className={classNames(css.bottomButtons, css.buttonContent)}>
            <Button
              className={css.submitButton}
              type="button"
              onClick={onPrevious}
            >
              <FormattedMessage id="EditListingDescriptionForm.backButtonText" />
            </Button>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
