import React, { Component, useState } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  Form,
  Avatar,
  Button,
  ImageFromFile,
  IconSpinner,
  FieldTextInput,
  FieldPhoneNumberInput,
  FieldSelect,
  UserNavBar,
} from '../../components';
import css from './ProfileSettingsForm.module.css';
import { Country, State, City } from 'country-state-city';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input';
import PhoneNumberInput from '../../components/PhoneNumber/PhoneNumberInput';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.uploadDelayTimeoutId = null;
    this.state = {
      uploadDelay: false,
    };
    this.submittedValues = {};
    this.phoneNumber = {},
      this.validNumber = false
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            formId,
            pristine,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);
          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(
            firstNameRequiredMessage
          );

          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // Bio
          const bioLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.bioLabel',
          });
          const bioPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.bioPlaceholder',
          });
          const bioRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.bioRequired',
          });
          const bioRequired = validators.required(bioRequiredMessage);

          // email
          const email = intl.formatMessage({
            id: 'ProfileSettingsForm.email',
          });

          // phone :
          const phonePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.phonePlaceholder',
          });
          const phoneLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.phoneLabel',
          });
          const phoneValidError = intl.formatMessage({
            id: 'SignupForm.phoneError',
          });

          // Date OF Birth

          const dateOfBirthPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.dateOfBirthPlaceholder',
          });
          const dateOfBirthLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.dateOfBirthLabel',
          });

          const phoneCodePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.phoneCodePlaceholder',
          });
          const phoneNumberRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.phoneNumberRequired',
          });
          const phoneNumberRequired = validators.required(
            phoneNumberRequiredMessage
          );

          const phoneCode = Country.getAllCountries();
          const phoneCodeArray = [];

          phoneCode &&
            phoneCode.length &&
            phoneCode.map((e, i) => {
              i <= phoneCode.length + 1
                ? phoneCodeArray.push(phoneCode[i].phonecode)
                : phoneCodeArray;
            });

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({
            [css.avatarUploadError]: hasUploadError,
          });
          const transientUserProfileImage =
            profileImage.uploadedImage || user.profileImage;
          const transientUser = {
            ...user,
            profileImage: transientUserProfileImage,
          };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload =
            profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>
                  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_888_22077)">
                      <circle cx="24" cy="24" r="16" fill="#FBFBFB" />
                    </g>
                    <path d="M31 20.8062L27.1933 17L17 27.1938V31H20.8067L31 20.8062ZM27.1942 18.9886L29.0122 20.8063L28.2492 21.5701L26.4312 19.7524L27.1942 18.9886ZM25.4364 20.7461L27.2544 22.5639L21.2175 28.5995L19.3997 26.781L25.4364 20.7461ZM18.4058 29.5934V27.7756L20.2238 29.5934H18.4058Z" fill="#1FD1BC" />
                    <defs>
                      <filter id="filter0_d_888_22077" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset />
                        <feGaussianBlur stdDeviation="4" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.121569 0 0 0 0 0.819608 0 0 0 0 0.737255 0 0 0 0.16 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_888_22077" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_888_22077" result="shape" />
                      </filter>
                    </defs>
                  </svg>


                  {/* <FormattedMessage id="ProfileSettingsForm.changeAvatar" /> */}
                </div>
              </div>
            ) : (
              <div className={css.avatarPlaceholder}>
                <div className={css.avatarPlaceholderText}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePicture" />
                </div>
                <div className={css.avatarPlaceholderTextMobile}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePictureMobile" />
                </div>
              </div>
            );

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit =
            submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid ||
            pristine ||
            pristineSinceLastSubmit ||
            uploadInProgress ||
            submitInProgress;

          const { phoneNumber } = values;


          const [phoneValue, setPhoneValue] = useState();
          const [invalidPhoneNumber, setInvalidPhoneValue] = useState(false);

          // phone
          const protectedData = user.attributes?.profile.protectedData || {};
          const currentPhoneNumber = protectedData.phoneNumber;

          // has the phone number changed
          const phoneNumberChanged = currentPhoneNumber !== phoneNumber;

          const changePhoneValue = () => {
            if (phoneValue) {
              form.change('phoneNumber', phoneValue);
              const formattedNumber = formatPhoneNumberIntl(phoneValue);
              if (isValidPhoneNumber(formattedNumber)) {
                setInvalidPhoneValue(false);
              } else {
                setInvalidPhoneValue(true);
              }
            }
          };

          return (
            <div className={css.navMainContainer}>
              <Form
                className={classNames(classes)}
                onSubmit={e => {
                  this.submittedValues = values;
                  handleSubmit(e);
                }}
              >
                <div>
                  <div className={css.sectionContainer}>
                    <Field
                      accept={ACCEPT_IMAGES}
                      id="profileImage"
                      name="profileImage"
                      label={chooseAvatarLabel}
                      type="file"
                      form={null}
                      uploadImageError={uploadImageError}
                      disabled={uploadInProgress}
                    >
                      {fieldProps => {
                        const {
                          accept,
                          id,
                          input,
                          label,
                          disabled,
                          uploadImageError,
                        } = fieldProps;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          form.change(`profileImage`, file);
                          form.blur(`profileImage`);
                          if (file != null) {
                            const tempId = `${file.name}_${Date.now()}`;
                            onImageUpload({ id: tempId, file });
                          }
                        };

                        let error = null;

                        if (isUploadImageOverLimitError(uploadImageError)) {
                          error = (
                            <div className={css.error}>
                              <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                            </div>
                          );
                        } else if (uploadImageError) {
                          error = (
                            <div className={css.error}>
                              <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                            </div>
                          );
                        }

                        return (
                          <div className={css.uploadAvatarWrapper}>
                            <label className={css.label} htmlFor={id}>
                              {label}
                            </label>
                            <input
                              accept={accept}
                              id={id}
                              name={name}
                              className={css.uploadAvatarInput}
                              disabled={disabled}
                              onChange={onChange}
                              type={type}
                            />
                            {error}
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <div className={css.sectionContainer}>
                    <div className={css.nameContainer}>
                      <FieldTextInput
                        className={css.firstName}
                        type="text"
                        id="firstName"
                        name="firstName"
                        label={firstNameLabel}
                        placeholder={firstNamePlaceholder}
                        validate={firstNameRequired}
                      />
                      <FieldTextInput
                        className={css.lastName}
                        type="text"
                        id="lastName"
                        name="lastName"
                        label={lastNameLabel}
                        placeholder={lastNamePlaceholder}
                        // validate={lastNameRequired}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames(
                      css.sectionContainer,
                      css.lastSection
                    )}
                  >
                    <FieldTextInput
                      className={css.lastName}
                      type="text"
                      id="email"
                      name="email"
                      label={email}
                      disabled
                    />

                    <FieldTextInput
                      className={classNames(css.password, css.dateInput)}
                      type={"date"}
                      id={formId ? `${formId}.dateOfBirth` : 'dateOfBirth'}
                      name="dateOfBirth"
                      label={"Date Of Birth (optional)"}
                      placeholder={dateOfBirthPlaceholder}
                    // maxLength={10}
                    />
                    {/* <FieldTextInput
                      className={css.lastName}
                      type="text"
                      id="dateOfBirth"
                      name="dateOfBirth"
                      placeholder={dateOfBirthPlaceholder}
                      label={dateOfBirthLabel}
                    /> */}
                    <div className={css.numberInputBox}>
                      <PhoneNumberInput
                        changePhoneValue={changePhoneValue}
                        phoneValue={phoneValue}
                        phoneNumber={phoneNumber}
                        setPhoneValue={setPhoneValue}
                        invalidPhoneNumber={invalidPhoneNumber}
                        phonePlaceholder={phonePlaceholder}
                        phoneLabel={phoneLabel}
                        phoneValidError={phoneValidError}
                      />
                    </div>
                  </div>

                  <div className={css.bioContent}>
                    <FieldTextInput
                      type="textarea"
                      id="bio"
                      name="bio"
                      label={bioLabel}
                      validate={bioRequired}
                      placeholder={bioPlaceholder}
                    />
                  </div>
                  {submitError}
                  <Button
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={pristineSinceLastSubmit}
                  >
                    <FormattedMessage id="ProfileSettingsForm.saveChanges" />
                  </Button>
                </div>
              </Form>
            </div>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
