import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { array, string, func, shape, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes, PROVIDER_COMMISSION_PERCENTAGE } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '..';
import { Button, Modal } from '../../components';
import css from './BookingRequestCard.module.css';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);

    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, {
  loadAfterInitialRendering: 3000,
});

export const BookingRequestCardInbox = (props) => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    setActiveListing,
    params,
    Booking,
    currentUser,
    onAcceptSale,
    onDeclineSale,
    acceptInProgress,
    declineInProgress,
    declineSaleError,
    acceptSaleError,
    index,
    onManageDisableScrolling,
  } = props;
  const [newIndex, setnewIndex] = useState(-1)
  const [isModalOpen, setisModalOpen] = useState(false);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { selectedTime, selectedPrice, diffBetweenDays } = currentListing && currentListing.attributes?.metadata?.bookingData || {};
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing?.listing?.attributes;
  const firstImage =
    currentListing?.listing?.images &&
      currentListing?.listing?.images.length > 0
      ? currentListing?.listing?.images[0]
      : null;
  const payinTotal = currentListing?.attributes?.payinTotal;
  const payoutTotal = currentListing?.attributes?.payoutTotal;
  const { formattedPrice: formattedPayinTotal } = priceData(payinTotal, intl);
  const { formattedPrice: formattedPayoutTotal } = priceData(payoutTotal, intl);
  const { formattedPrice, priceTitle } = priceData(price, intl);


  useEffect(() => {
    const { bookingStart, bookingEnd } = currentListing?.attributes?.protectedData?.bookingData || {};
    Object.assign(currentListing.booking.attributes, {
      start: bookingStart ? moment(bookingStart).toDate() : currentListing.booking.attributes.start,
      end: bookingEnd ? moment(bookingEnd).toDate() : currentListing.booking.attributes.end,
      displayEnd: bookingEnd ? moment(bookingEnd).toDate() : currentListing.booking.attributes.end,
      displayStart: bookingStart ? moment(bookingStart).toDate() : currentListing.booking.attributes.start,
    })
  }, []);

  const declineModal = (
    <Modal
      id="BookingRequestCrad.declineModal"
      isOpen={isModalOpen}
      onClose={(e) => { e.preventDefault(), setisModalOpen(false) }}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      bookingRequest={true}
    >
      <div className={css.modalContainer}>
        <h1>
          <FormattedMessage id="BookingRequestCardInbox.declineHeading" />
        </h1>
        <div>
          <p>
            <FormattedMessage id="BookingRequestCardInbox.declineText" />
          </p>
        </div>
        <div className={css.modalButtons}>
          <Button
            className={css.noButton}
            onClick={(e) => {
              e.preventDefault(), setisModalOpen(false);
            }}
          >
            NO
          </Button>
          <Button
            inProgress={declineInProgress}
            className={css.decline}
            onClick={(e) => {
              e.preventDefault(),
                onDeclineSale(currentListing.id).then(() =>
                  setisModalOpen(false)
                );
            }}
          >
            <span className={css.desktopDecline}>DECLINE</span>
            <span className={css.mobileDecline}>YES</span>
          </Button>
        </div>
      </div>
    </Modal>
  );

  const userRole = currentListing?.customer?.id?.uuid === currentUser?.id?.uuid;
  return (
    <NamedLink
      className={classes}
      name={userRole ? 'OrderDetailsPage' : 'SaleDetailsPage'}
      params={{ id }}
    >
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.productDetails}>
        <div className={css.info}>
          <div className={css.mainInfo}>
            <h4 className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </h4>
            <p>
              Requested on :{' '}
              <span className={css.dateText}>{moment(currentListing && currentListing.attributes?.createdAt).format('MMM DD, YYYY hh:mm A')}</span>
            </p>
          </div>
          <div className={css.price}>
            <div className={css.priceContent}>
              <h6 className={css.totalEarning}>Total:</h6>
              <h5 className={css.priceValue} title={priceTitle}>
                {userRole ? formattedPayinTotal : formattedPayoutTotal}
              </h5>
            </div>
          </div>
        </div>
        {declineModal}
        <div className={css.buttons}>
          <Button
            type="button"
            className={css.accept}
            inProgress={newIndex > -1 ? acceptInProgress : false}
            onClick={(e) => {
              onAcceptSale(currentListing.id), e.preventDefault(); setnewIndex(index)
            }}
          >
            Accept
          </Button>
          <Button
            className={css.decline}
            onClick={(e) => {
              setisModalOpen(true), e.preventDefault();
            }}
          >
            Decline
          </Button>
        </div>
      </div>
    </NamedLink>
  );
};

BookingRequestCardInbox.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

BookingRequestCardInbox.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,
  isOrder: bool.isRequired,
  setActiveListing: func,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  return {
    currentUser,
    isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  bookmarkedData: (id) => dispatch(updateProfile(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  injectIntl
)(BookingRequestCardInbox);
