import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  Form,
  Avatar,
  Button,
  ImageFromFile,
  IconSpinner,
  FieldTextInput,
  IconAmenities,
  IconCollection,
} from '../../components';

import css from './ContactUsForm.module.css';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

class ContactUsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            pristine,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadInProgress,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ContactUsForm.NameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ContactUsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ContactUsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(
            firstNameRequiredMessage
          );

          // email
          const email = intl.formatMessage({
            id: 'ContactUsForm.email',
          });

          // Message
          const WriteYourMessage = intl.formatMessage({
            id: 'ContactUsForm.WriteYourMessage',
          });
          const messagePlaceholder = intl.formatMessage({
            id: 'ContactUsForm.bioPlaceholder',
          });

          const messageRequiredMessage = intl.formatMessage({
            id: 'ContactUsForm.firstNameRequired',
          });
          const messageRequired = validators.required(messageRequiredMessage);

          // email
          const emailPlaceholder = intl.formatMessage({
            id: 'ContactUsForm.emailPlaceholder',
          });
          const emailRequiredMessage = intl.formatMessage({
            id: 'ContactUsForm.emailRequired',
          });
          const emailRequired = validators.required(emailRequiredMessage);
          const emailInvalidMessage = intl.formatMessage({
            id: 'ContactUsForm.emailInvalid',
          });
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ContactUsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit =
            submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid ||
            pristine ||
            pristineSinceLastSubmit ||
            uploadInProgress ||
            submitInProgress;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.contentWrapper}>
              <h1 className={css.modalTitleMobile}>
        <IconCollection name="leftArrow" />
        <FormattedMessage
          id="ContactUsForm.contactUs"
          values={{ Password: <span className={css.colortext}>Password</span> }}
        />
      </h1>
                <div className={css.contactUsImage}>
                  <IconAmenities type="contactUs" />
                </div>
                <div className={css.rightContent}>
                  <div className={css.sectionContainer}>
                    <h2>
                      <FormattedMessage id="ContactUsForm.contactUs" />
                    </h2>
                    <h3>
                      <FormattedMessage id="ContactUsForm.yourProfilePicture" />
                    </h3>
                  </div>
                  <div className={css.sectionContainer}>
                    <div className={css.nameContainer}>
                      <FieldTextInput
                        className={css.firstName}
                        type="text"
                        id="firstName"
                        name="firstName"
                        label={firstNameLabel}
                        placeholder={firstNamePlaceholder}
                        validate={firstNameRequired}
                      />
                      <FieldTextInput
                        className={css.lastName}
                        type="text"
                        id="email"
                        name="emails"
                        label={email}
                        placeholder={emailPlaceholder}
                        validate={validators.composeValidators(
                          emailRequired,
                          emailValid
                        )}
                      />
                    </div>
                  </div>
                  <div
                    className={classNames(
                      css.sectionContainer,
                      css.lastSection
                    )}
                  >
                    <FieldTextInput
                      type="textarea"
                      id="bio"
                      name="bio"
                      label={WriteYourMessage}
                      placeholder={messagePlaceholder}
                      validate={messageRequired}
                    />
                  </div>
                  {submitError}
                  <Button
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={pristineSinceLastSubmit}
                  >
                    <FormattedMessage id="ContactUsForm.saveChanges" />
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ContactUsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ContactUsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ContactUsForm = compose(injectIntl)(ContactUsFormComponent);

ContactUsForm.displayName = 'ContactUsForm';

export default ContactUsForm;
