import React from 'react'
import { ReactComponent as PassiveIncome } from "../../assets/earnPage/passive.svg"
import { ReactComponent as SafeSecure } from "../../assets/earnPage/security.svg"
import { ReactComponent as Control } from "../../assets/earnPage/control.svg"
import { ReactComponent as MoneyRoll } from "../../assets/earnPage/roll.svg"
import Card from './Card'


const cards = [
  {
    title: "Passive Income",
    description: "Just snap some pictures, set a price, and hooray, your item is live, making your new source of income official, all in under 5 minutes.",
    icon: <PassiveIncome />
  },
  {
    title: "Safe & Secure",
    description: "With our identity verification process, and Covize© powered protection guarantee of up to $20,000 per item, know we've got your back.",
    icon: <SafeSecure />
  }, 
  {
    title: "You're in Control",
    description: "As lender, you're the boss. Set your own pricing and availability and meet the renter at your location. Rent out your items for just a few hours or days, it's your call.",
    icon: <Control />
  },
  {
    title: "Let the Money Roll",
    description: "We'll handle the payments and automatically deposit the money into your account at the rental end time. Sit back and watch your bank account grow",
    icon: <MoneyRoll />
  }
]

export default function Cards() {

  return (
    <div className='py-5'>
      <div className="container">
        <div className="row gap-sm-0 gap-5">
          {cards.map((card, i) => (
            <Card key={i} {...card} />
          ))}
        </div>
      </div>
    </div>
  )
}
