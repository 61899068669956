import scooter from './assets/trendingSearches/scooter.png';
import drone from './assets/trendingSearches/drone.png';
import flyingTv from './assets/trendingSearches/flyingTv.png';
import guitar from './assets/trendingSearches/guitar.png';
import toolsIcon from './assets/trendingSearches/Tools.png';
import electronicsIcon from './assets/trendingSearches/Electronics.png';
import musicalInstruments from './assets/trendingSearches/Musical-Instruments.png';
import micromobilityIcon from './assets/trendingSearches/Micromobility.png';
import drillIcon from './assets/trendingSearches/img1.jpg';
import circularSaw from './assets/trendingSearches/CircularSaw.png';
import camerasPhoto from './assets/trendingSearches/Cameras-Photo.png';
import sportsEquipment from './assets/trendingSearches/SportsEquipment.png';
import {
  TRANSITION_COMPLETE,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
} from './util/transaction';
import exploreCamerasPhoto from './assets/explore-cameras-photo.png';
import exploreElectronics from './assets/explore-electronics.png';
import exploreMicromobility from './assets/explore-micromobility.png';
import exploreMusicalInstruments from './assets/explore-musical-instruments.png';
import exploreSportsEquipment from './assets/explore-sport-equipments.png';
import exploreTools from './assets/explore-tools.png';
import laptop from './assets/laptop.png';
import pressureWasher from './assets/pressure-water.png';
/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    queryParamNames: ['keywords'],
    config: {
      className: 'searchInput',
    },
  },
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'rentalOptions',
    label: 'rentalOptions',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_rentalOptions'],
    config: {
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'sixHoursRental',
          label: 'Six hours',
          // hideFromFilters: true,
          // hideFromListingInfo: true,
        },
        {
          key: 'singleDayRental',
          label: 'Single Day',
          // hideFromFilters: true,
          // hideFromListingInfo: true,
        },
        {
          key: 'multiDayRental',
          label: 'Multi-Days',
          // hideFromFilters: true,
          // hideFromListingInfo: true,
        },
        // { key: '200h', label: 'Registered yoga teacher 200h' },
        // { key: '500h', label: 'Registered yoga teacher 500h' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'secondary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 250,
      step: 5,
    },
  },
  {
    id: 'distance',
    label: 'Distance',
    type: 'DistanceFilter',
    group: 'secondary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['pub_distance'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 25,
      step: 1,
    },
  },
  {
    id: 'instantBooking',
    label: 'Instant Booking',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_instantBooking'],
    config: {
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'instantBooking',
          label: 'Instant Booking',
          // hideFromFilters: true,
          // hideFromListingInfo: true,
        },
        {
          key: 'delivery',
          label: 'Delivery',
          // hideFromFilters: true,
          // hideFromListingInfo: true,
        },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Distance' },
    // { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    {
      key: 'relevance',
      label: 'Relevance',
      longLabel: 'Relevance (Keyword search)',
    },
  ],
};

export const otpStatus = [
  {
    key: 'sent',
    msg: 'Otp sent',
  },
  {
    key: ' Otp Verified',
    msg: 'Otp Verified',
  },
  {
    key: 'Not Match',
    msg: 'Otp not Match',
  },
];

export const otpSteps = {
  SENT: 'sent',
  VERIFIED: 'verified',
  COMPLETED: 'completed',
};

export const shopByBrands = [
  {
    name: 'Virtual Reality',
    icon: flyingTv,
    search: '?pub_subCategory=vrHeadsets',
  },
  {
    name: 'Pressure Washer',
    icon: pressureWasher,
    search: '?pub_subCategory=pressureWashers',
  },
  {
    name: 'Carpet Cleaner',
    icon: drillIcon,
    search: '?pub_subCategory=drills',
  },
  { name: 'Laptop', icon: laptop, search: '?pub_subCategory=laptopsTablets' },
  { name: 'Drone', icon: drone, search: '?pub_subCategory=drones' },
  { name: 'Circular Saw', icon: circularSaw, search: '?pub_subCategory=saws' },
];

export const renterData = [
  {
    name: 'BROWSE RENTALS',
    icon: 'browseRentals',
    search: '?pub_brand=browseRentals',
    description:
      'Browse by category or search for a keword. Filter by price, distance, or delivery options',
  },
  {
    name: 'SELECT & CONNECT',
    icon: 'selectAndConnect',
    search: '?pub_brand=selectAndConnect',
    description:
      'Select an item, read some reviews. Have any questions Message the owner',
  },
  {
    name: 'FLEXIBLE OPTIONS',
    icon: 'flexibleOptions',
    search: '?pub_brand=flexibleOptions',
    description:
      'Choose a date and time. Go for six hours, twelve hours, or daily pricing',
  },
  {
    name: 'EXPERIENCE',
    icon: 'experience',
    search: '?pub_brand=experience',
    description: 'Ready set go. Get to experience what Runtize is all about',
  },
];

export const lenderData = [
  {
    name: 'LIST YOUR ITEMS',
    icon: 'listYourItems',
    search: '?pub_brand=listYourItems',
    description:
      'Snap some pictures, choose your price, write a short description and list your stuff',
  },
  {
    name: 'FLEXIBLE PRICING',
    icon: 'flexiblePricing',
    search: '?pub_brand=flexiblePricing',
    description:
      'Flexibly price your items for just six hour and twelve hour or even multi day rentals',
  },
  {
    name: 'BE IN CONTROL',
    icon: 'beInControl',
    search: '?pub_brand=beInControl',
    description:
      'Manage your availability, review booking requests,and track your earnings',
  },
  {
    name: 'EARN CASH',
    icon: 'earnCash',
    search: '?pub_brand=earnCash',
    description:
      'Ready set go. Let the money roll in and watch your bank account grow',
  },
];

export const displayCategories = [
  {
    key: 'electronics',
    value: 'Electronics',
    icon: electronicsIcon,
    label: 'NEW',
    search: '?pub_category=electronics',
    abstractIcon: exploreElectronics,
    tag: 'New',
    tagColor: '#68CEBC',
    pathname: '/category/electronics',
  },
  {
    key: 'tools',
    value: 'Tools',
    icon: toolsIcon,
    label: 'NEW',
    pathname: '/category/tools',
    search: '?pub_category=tools',
    abstractIcon: exploreTools,
    tag: 'New',
    tagColor: '#68CEBC',
  },
  {
    key: 'micromobility',
    value: 'Micromobility',
    icon: micromobilityIcon,
    label: 'BETA',
    search: '?pub_category=micromobility',
    abstractIcon: exploreMicromobility,
    tag: 'Coming Soon',
    tagColor: '#F0A840',
    pathname: '/category/micromobility',
  },
  {
    key: 'musicalInstruments',
    value: 'Musical Instruments',
    icon: musicalInstruments,
    label: 'COMING SOON',
    search: '?pub_category=musicalInstruments',
    abstractIcon: exploreMusicalInstruments,
    tag: 'Coming Soon',
    tagColor: '#E75B57',
    pathname: '/category/musicalInstruments',
  },
  {
    key: 'camerasPhoto',
    value: 'Cameras & Photo',
    icon: camerasPhoto,
    label: 'COMING SOON',
    search: '?pub_category=camerasPhoto',
    abstractIcon: exploreCamerasPhoto,
    tag: 'Coming Soon',
    tagColor: '#E75B57',
    pathname: '/category/camerasPhoto',
  },
  {
    key: 'sportsEquipment',
    value: 'Sports Equipment',
    icon: sportsEquipment,
    label: 'COMING SOON',
    search: '?pub_category=sportsEquipment',
    abstractIcon: exploreSportsEquipment,
    tag: 'Coming Soon',
    tagColor: '#E75B57',
    pathname: '/category/sportsEquipment',
  },
];

export const displayCategoriesData = {
  tools: {
    titlePrimary: 'Rent',
    titleSecondary: 'Tools',
    desc: `The Runtize renting marketplace now offers a wide range of tools for your next project. From drills to saws, we have it all.`,
  },
  electronics: {
    titlePrimary: 'Rent',
    titleSecondary: 'Electronics',
    desc: `The Runtize renting marketplace now offers a wide range of electronics. From drones to laptops, we have it all.`,
  },
  micromobility: {
    titlePrimary: 'Rent',
    titleSecondary: 'Micromobility',
    desc: `The Runtize renting marketplace now offers a wide range of micromobility. From eBikes to eScooters, we have it all.`,
  },
  musicalInstruments: {
    titlePrimary: 'Rent',
    titleSecondary: 'Musical Instruments',
    desc: `The Runtize renting marketplace now offers a wide range of musical instruments. From guitars to pianos, we have it all.`,
  },
  camerasPhoto: {
    titlePrimary: 'Rent',
    titleSecondary: 'Cameras & Photo',
    desc: `The Runtize renting marketplace now offers a wide range of cameras & photo. From drones to cameras, we have it all.`,
  },
  sportsEquipment: {
    titlePrimary: 'Rent',
    titleSecondary: 'Sports Equipment',
    desc: `The Runtize renting marketplace now offers a wide range of sports equipment. From bicycles to segways, we have it all.`,
  },
};

export const exploreTheMarketPlace = [
  {
    name: 'Tools',
    icon: 'toolsIcon',
    label: null,
    search: '?pub_brand=tools',
  },
  {
    name: 'Electronics',
    icon: 'electronicsIcon',
    label: 'NEW',
    search: '?pub_brand=electronics',
  },
  {
    name: 'Musical Instruments',
    icon: 'musicalInstruments',
    label: 'COMING SOON',
    search: '?pub_brand=musicalInstrument',
  },
  {
    name: 'Micromobility',
    icon: 'micromobilityIcon',
    label: 'BETA',
    search: '?pub_brand=Micromobility',
  },
  {
    name: 'Cameras & Photo',
    icon: 'camerasPhoto',
    label: 'COMING SOON',
    search: '?pub_brand=camerasphoto',
  },
  {
    name: 'Sports Equipment',
    icon: 'sportsEquipment',
    label: 'COMING SOON',
    search: '?pub_brand=sportsEquipment',
  },
];
export const productReview = [
  {
    icon: 'heroBikes',
    cardTitle: 'Bronx m, New York',
    miles: '21 Miles away',
    days: '7 Days',
    search: '?pub_brand=camerasphoto',
  },
  {
    icon: 'heroOven',
    cardTitle: 'Brooklyn, New York',
    miles: '1.3 Miles away',
    days: '16 Days',
    search: '?pub_brand=camerasphoto',
  },
  // {
  //   icon: 'camerasphoto',
  //   cardTitle: 'New York, NY',
  //   miles: '0.4 Miles away',
  //   days: '16 Six hours',
  //   search: '?pub_brand=camerasphoto',
  // },
];

export const userType = {
  LENDER: 'lender',
  RENTER: 'renter',
};

export const category = [
  {
    key: 'electronics',
    value: 'Electronics',
    searchText: 'electronics Gadgets Technology',
  },
  {
    key: 'tools',
    value: 'Tools',
    searchText: 'tools diy',
  },
  {
    key: 'micromobility',
    value: 'Micromobility',
  },
  {
    key: 'musicalInstrument',
    value: 'Musical Instruments',
    searchText: 'Musical instruments Music gear Musical equipment',
  },
  {
    key: 'camerasphoto',
    value: 'Cameras and Photo',
  },
  {
    key: 'sportsEquipment',
    value: 'Sports Equipment',
  },
];

export const subCategory = [
  {
    key: 'guitarsBasses',
    value: 'Guitars & Basses',
    parentId: 'musicalInstrument',
  },
  { key: 'brass', value: 'Brass', parentId: 'musicalInstrument' },
  { key: 'amplifiers', value: 'Amplifiers', parentId: 'musicalInstrument' },
  { key: 'djEquipment', value: 'DJ Equipment', parentId: 'musicalInstrument' },
  {
    key: 'windsWoodwinds',
    value: 'Winds & Woodwinds',
    parentId: 'musicalInstrument',
  },
  {
    key: 'drumsPercussion',
    value: 'Drums & Percussion',
    parentId: 'musicalInstrument',
  },
  {
    key: 'pianosKeyboards',
    value: 'Pianos & Keyboards',
    parentId: 'musicalInstrument',
  },
  {
    key: 'stringInstruments',
    value: 'String Instruments',
    parentId: 'musicalInstrument',
  },
  {
    key: 'stageSoundEquipment',
    value: 'Stage & Sound Equipment',
    parentId: 'musicalInstrument',
  },
  { key: 'microphones', value: 'Microphones', parentId: 'musicalInstrument' },
  { key: 'other', value: 'Other', parentId: 'musicalInstrument' },

  {
    key: 'bicyclesEBikes',
    value: 'Bicycles & eBikes',
    parentId: 'micromobility',
  },
  { key: 'segways', value: 'Segways', parentId: 'micromobility' },
  { key: 'eScooters', value: 'eScooters', parentId: 'micromobility' },
  { key: 'oneWheelers', value: 'One Wheelers', parentId: 'micromobility' },
  {
    key: 'electricSkateboards',
    value: 'Electric Skateboards',
    parentId: 'micromobility',
  },
  {
    key: 'electricUnicycles',
    value: 'Electric Unicycles',
    parentId: 'micromobility',
  },
  { key: 'hoverboards', value: 'Hoverboards', parentId: 'micromobility' },
  { key: 'other', value: 'Other', parentId: 'micromobility' },

  { key: 'drones', value: 'Drones', parentId: 'electronics' },
  {
    key: 'smartphones',
    value: 'Smartphones',
    parentId: 'electronics',
    searchText: 'Smartphones Cell phone mobiles',
  },
  {
    key: 'laptopsTablets',
    value: 'Laptops & Tablets',
    parentId: 'electronics',
  },
  {
    key: 'vrHeadsets',
    value: 'VR Headsets',
    parentId: 'electronics',
    searchText:
      'VR Headsets Virtual Reality VR goggles Mixed reality AR Augemented reality',
  },
  {
    key: 'consolesGaming',
    value: 'Consoles & Gaming',
    parentId: 'electronics',
  },
  { key: 'other', value: 'Other', parentId: 'electronics' },

  { key: 'comingSoon', value: 'Coming soon', parentId: 'camerasphoto' },
  { key: 'comingSoon', value: 'Coming soon', parentId: 'sportsEquipment' },
  { value: 'Carpet Cleaner', parentId: 'tools', key: 'carpetCleaner' },
  { value: 'Pressure Washer', parentId: 'tools', key: 'pressureWashers' },
  { value: 'Ladder', parentId: 'tools', key: 'ladder' },
  { value: 'Generator', parentId: 'tools', key: 'generator' },
  { value: 'Pallet Jack', parentId: 'tools', key: 'palletJack' },
  { value: 'Saw', parentId: 'tools', key: 'saw' },
  { value: 'Paint Sprayer', parentId: 'tools', key: 'paintSprayer' },
  { value: 'Jack Hammer', parentId: 'tools', key: 'jackHammer' },
  { value: 'Air Compressor', parentId: 'tools', key: 'airCompressor' },
  { value: 'Drill', parentId: 'tools', key: 'drill' },
  { value: 'Hand Truck', parentId: 'tools', key: 'handTruck' },
  { value: 'Welder', parentId: 'tools', key: 'welder' },
  { value: 'Nail Gun', parentId: 'tools', key: 'nailGun' },
  { value: 'Grinders & Sanders', parentId: 'tools', key: 'grindersSanders' },
  { value: 'Planers&Routers', parentId: 'tools', key: 'planersRouters' },
  { value: 'Cleaning Equipment', parentId: 'tools', key: 'cleaningEquipment' },
  { value: 'Plumbing Equipment', parentId: 'tools', key: 'plumbingEquipment' },
  { value: 'Compactors', parentId: 'tools', key: 'compactors' },
];

export const subSubCategory = [
  {
    key: 'coreDrill',
    value: 'Core drill',
    parentId: 'drill',
  },
  {
    key: 'hammerDrill',
    value: 'Hammer drill',
    parentId: 'drill',
  },
  {
    key: 'jigSaw',
    value: 'Jigsaw',
    parentId: 'saw',
  },
  {
    key: 'brandSaw',
    value: 'Brand saw',
    parentId: 'saw',
  },
  {
    key: 'chainSaw',
    value: 'Chainsaw',
    parentId: 'saw',
  },
  {
    key: 'circularSaw',
    value: 'Circular saw',
    parentId: 'saw',
  },
  {
    key: 'concreteSaw',
    value: 'Concrete saw',
    parentId: 'saw',
  },
  {
    key: 'tableSaw',
    value: 'Table saw',
    parentId: 'saw',
  },
  {
    key: 'tileSaw',
    value: 'Tile saw',
    parentId: 'saw',
  },
  { value: 'Carpet Cleaner', parentId: 'cleaningEquipment', key: 'carpetCleaner' },
  { value: 'Pressure Washer', parentId: 'cleaningEquipment', key: 'pressureWashers' },
  { value: 'Dehumidifier', parentId: 'cleaningEquipment', key: 'dehumidifier' },
  { value: 'Purifier', parentId: 'cleaningEquipment', key: 'purifier' },
  { value: 'Sewer Snake', parentId: 'plumbingEquipment', key: 'sewerSnake' },
  { value: 'Water Pump', parentId: 'plumbingEquipment', key: 'waterPump' },
  { value: 'Rammer Compactor', parentId: 'compactors', key: 'rammerCompactor' },
  { value: 'Plate Compactor', parentId: 'compactors', key: 'plateCompactor' },
];

export const instantBooking = [
  {
    key: false,
    label: 'Off',
  },
  {
    key: true,
    label: 'On',
  },
];

export const hearAboutUs = [
  {
    key: 'wordOfMouth',
    value: 'Word Of Mouth',
  },
  {
    key: 'searchEngine',
    value: 'Search Engine',
  },
  {
    key: 'posterCard',
    value: 'Poster Card',
  },
  {
    key: 'twitter',
    value: 'Twitter',
  },
  {
    key: 'instagram',
    value: 'Instagram',
  },
  {
    key: 'youtube',
    value: 'Youtube',
  },
  {
    key: 'tiktok',
    value: 'TikTok',
  },
  {
    key: 'other',
    value: 'Other',
  },
];

export const deliveryDistance = [
  {
    key: 'oneMile',
    value: '1 Mile',
  },
  {
    key: 'twoMiles',
    value: '2 Miles',
  },
  {
    key: 'threeMiles',
    value: '3 Miles',
  },
  {
    key: 'fourMiles',
    value: '4 Miles',
  },
  {
    key: 'fiveMiles',
    value: '5 Miles',
  },
  {
    key: 'sixMiles',
    value: '6 Miles',
  },
  {
    key: 'sevenMiles',
    value: '7 Miles',
  },
  {
    key: 'eightMiles',
    value: '8 Miles',
  },
  {
    key: 'nineMiles',
    value: '9 Miles',
  },
  {
    key: 'tenMiles',
    value: '10 Miles',
  },
];

export const deliveryPrice = [
  {
    key: '5',
    value: '$ 5',
  },
  {
    key: '10',
    value: '$ 10',
  },
  {
    key: '15',
    value: '$ 15',
  },
  {
    key: '20',
    value: '$ 20',
  },
  {
    key: '25',
    value: '$ 25',
  },
];

// Months Array

export const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const transitionStatus = [
  'transition/expire-payment',
  'transition/decline',
  'transition/expire',
  'transition/cancel',
  'transition/complete',
];

export const TransactionComplete = [
  TRANSITION_COMPLETE,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
];

export const TransacationPending = [
  'transition/request-payment',
  'transition/request-payment-after-enquiry',
];

export const BookingRequestPending = ['transition/confirm-payment'];

export const IsDraftListing = ['draft'];
