import React, { useState, useEffect } from 'react';
import PropTypes, { array, func, string } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';

import css from './AutoSuggestion.module.css';
import { category } from '../../marketplace-custom-config';

const getSuggestedWord = (data = [], inputValue = '') => inputValue.split('').length ? [...data.filter(e => e && e.key && (e.key.search(inputValue) > -1 || e.key.search(inputValue) > -1))] : data;

const AutoSuggestion = props => {
  const {
    className,
    keyCount,
    inputValue = '',
    onSelectSuggestion,
    getSuggestions = config.custom.keywordSuggestions || [],
    // InProgress,
    // defaultKeyCount
  } = props;

  const [positionInit, SetpositionInit] = useState([0, 0]);

  const suggestedWords = inputValue
    ? getSuggestedWord(getSuggestions, inputValue)
    : [];

  useEffect(() => {
    if (typeof window != 'undefined') {
      SetpositionInit([window.scrollX, window.scrollY]);
    }
  }, [])

  useEffect(() => {
    if (typeof window != 'undefined') {
      if (suggestedWords && suggestedWords.length && keyCount > -1) {
        const scrollElem =
          [...document.getElementsByTagName('p')].find(
            el => el.textContent === suggestedWords[keyCount].PropertyName,
          );

        if (scrollElem) {
          window.scrollTo(positionInit[0], positionInit[1]);
          scrollElem && scrollElem.previousSibling.scrollIntoView();
          window.scrollTo(positionInit[0], positionInit[1]);
        }

      }
    }
  }, [keyCount]);

  const classes = classNames(className || css.autoSuggestion);

  return inputValue && suggestedWords && suggestedWords.length
    ? <div className={classes}>
      <div
        className={css.suggestedItems}
      >
        {suggestedWords && suggestedWords.length
          ? suggestedWords.map((word, i) => {
            return category.map((e) => {
              return (
                <div
                  key={'autoSuggestion_' + i}
                  className={
                    keyCount == i
                      ? css.locationBorder
                      // : word.type == 'LOCATION'
                      //   ? css.location
                      : css.property
                  }
                  onClick={() => onSelectSuggestion(word, e)}
                >
                  <div className={css.items}>
                    <div
                      key={word.key + i}
                      className={css.suggestedWord}
                    >   <p className={css.title}>{word.value} in {e.value}</p>


                      {/* <p className={css.propertyName}>{word.PropertyName}</p> */}
                    </div>{' '}
                  </div>
                </div>
              )
            })
          })
          : null}
      </div>
    </div>
    : null
};

AutoSuggestion.propTypes = {
  // suggestionArray: array.isRequired,
  inputValue: string.isRequired,
  onSelectSuggestion: func.isRequired,
};

export default AutoSuggestion;
