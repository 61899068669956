import React from 'react';

export default function Senja() {
  return (
    <section>
      <div
        className="senja-embed"
        data-id="5564b80a-133b-4009-9793-87e5613480d3"
        data-mode="shadow"
        data-lazyload="false"
      ></div>
    </section>
  );
}
