import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';
import { IconCollection, Modal, NamedLink } from '../../components';

import css from './EditListingDescriptionPanel.module.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    onUpdateProfile,
    currentUser,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { profile } = currentUser && currentUser.attributes || {};
  const { locationForListings, geolocationForListings, streetAddress } = profile?.publicData || {};
  const { location: locations, zipLocation } = profile?.protectedData || {};
  const zipLoc = currentUser && currentUser.id && currentUser.attributes?.profile?.protectedData?.zipLocation
  const zipLtLg = currentUser && currentUser.id && currentUser.attributes?.profile?.protectedData?.zipCodeLatLng
  const origin = zipLtLg && {
    lat: parseFloat((zipLtLg[1] + zipLtLg[3]) / 2),
    lng: parseFloat((zipLtLg[0] + zipLtLg[2]) / 2)
  }
  const [openModal, setopenModal] = useState(false);
  const [modelData, setmodelData] = useState(null);

  const { description, title, publicData } = currentListing.attributes;
  const { storeLink, category, subCategory, instantBooking, subSubCategory } = publicData || {};
  const locationFieldsPresent =
    publicData &&
    publicData.location &&
    publicData.location.address &&
    publicData.geolocation;
  const loactionForListingsInitialValues = currentListing?.publicData && currentListing?.publicData?.geolocation;

  const location =
    publicData && publicData.location ? publicData.location : {};
  const { address, building } = locationForListings?.address ? locationForListings : location;

  const loactionInitialValues = {
    building,
    location: locationFieldsPresent
      ? {
        search: address,
        selectedPlace: { address, origin: geolocation },
      }
      : loactionForListingsInitialValues ? {
        search: address,
        selectedPlace: { address, origin: geolocationForListings },
      }
        : streetAddress ? {
          search: zipLocation,
          selectedPlace: { address: zipLocation, origin },
        }
          :
          null,
  };

  const panelTitle = <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />;

  const certificateOptions = findOptionsForSelectFilter(
    'certificate',
    config.custom.filters
  );
  const handleModel = props => {
    setmodelData(props);
    setopenModal(true);
  };
  return (
    <div className={classes}>
      <div className={css.informationContents}>
        <h1 className={css.title}>{panelTitle}</h1>
        <div className={css.dividerLine}></div>
        <EditListingDescriptionForm
          className={css.form}
          handleModel={handleModel}
          initialValues={{
            title,
            description,
            ...loactionInitialValues,
            storeLink,
            category,
            subCategory,
            instantBooking: instantBooking == "instantBooking" ? true : false,
            subSubCategory,
          }}
          saveActionMsg={submitButtonText}
          onSubmit={values => {
            const {
              title,
              description,
              category,
              subCategory,
              storeLink,
              building = '',
              location,
              subSubCategory,
            } = values;
            const {
              selectedPlace: { address, origin },
            } = location;

            const updateValues = {
              title: title.trim(),
              description,
              geolocation: origin,
              publicData: {
                category,
                subCategory,
                storeLink,
                instantBooking: JSON.parse(values.instantBooking) ? "instantBooking" : "delivery",
                location: { address, building },
                subSubCategory,
              },
            };
            onSubmit(updateValues);
            if (!streetAddress) {
              const { address: addresss, city, state, zipCode, zipLocation, zipCodeLatLng, isZipCodeValue, locations } = values;
              const streetAddress = locations?.search;
              const formetZipLocation = zipLocation && zipLocation.length && zipLocation?.split(',');
              const updatedAddress = formetZipLocation ? formetZipLocation[1].split(" ") : null
              let fullAddress;
              if (isZipCodeValue) {
                fullAddress = {
                  address: " ",
                  city: formetZipLocation[0],
                  state: updatedAddress[1],
                  zipCode: zipCode,
                  streetAddress: " ",
                };
              } else {
                fullAddress = {
                  address: addresss,
                  city: city,
                  state: state,
                  zipCode: zipCode,
                  streetAddress,
                };
              }
              const profile = {
                publicData: fullAddress,
                protectedData: {
                  zipLocation: zipLocation ? zipLocation : zipLoc,
                  zipCodeLatLng: zipCodeLatLng ? zipCodeLatLng : zipLtLg,
                  location,
                }
              };
              onUpdateProfile(profile);
            }
          }}
          onChange={onChange}
          disabled={disabled}
          ready={ready}
          streetAddress={streetAddress}
          updated={panelUpdated}
          locationForListings={locationForListings}
          geolocationForListings={geolocationForListings}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          certificateOptions={certificateOptions}
        />

        <Modal
          isOpen={openModal}
          listingStep2={modelData == 'storeLink' && true}
          listingStep={modelData == 'storeLink' ? false : true}
          onClose={() => {
            setopenModal(false);
          }}
          containerClassName={css.modal}
          onManageDisableScrolling={() => { }}
        >
          {modelData == 'storeLink' ? (
            <div className={css.modalContainer}>
              <div className={css.modalTitle}>
                <div> <IconCollection name="WRITE_ABOUT_INFO" /></div>
                <div>
                  <h2>
                    <FormattedMessage id="EditListingDescriptionForm.descriptionModelHeading" />
                  </h2>

                  <h6 className={css.firstParagraph}>
                    <FormattedMessage id="EditListingDescriptionForm.descriptionModelText1" />
                  </h6>
                  <h6>
                    <FormattedMessage id="EditListingDescriptionForm.descriptionModelText2" />
                  </h6>
                </div>
              </div>
            </div>
          )
            : modelData == 'location' ? (
              <div className={css.modalContainer}>
                <p>
                  <span style={{ marginRight: "10px" }}>
                    <IconCollection name="WRITE_ABOUT_INFO" />
                  </span>
                  Address is applied site wide. You can change it at any time from
                  <NamedLink name="AddressPage"> Address </NamedLink>
                  in the menu bar
                </p>
              </div>
            )
              : modelData == 'instantBooking' ? (
                <div className={css.modalContainer}>
                  <p>
                    <span style={{ marginRight: "10px" }}>
                      <IconCollection name="WRITE_ABOUT_INFO" />
                    </span>
                    Instant booking allows renters to book your listing without waiting for you to accept their request.
                  </p>
                </div>
              )
                : (
                  <div className={css.modalContainer}>
                    <div className={css.modalTitle}>
                      <IconCollection name="WRITE_ABOUT_INFO" />
                      <div>
                        <h2>
                          <FormattedMessage id="EditListingDescriptionForm.storeLinkModelHeading" />
                        </h2>

                        <div className={css.paragraphList}>
                          <IconCollection name="WRITE_ABOUT_LIST" />
                          <p>
                            <FormattedMessage id="EditListingDescriptionForm.storeLinkModelText1" />
                          </p>
                        </div>
                        <div className={css.paragraphList}>
                          <IconCollection name="WRITE_ABOUT_LIST" />
                          <p>
                            <FormattedMessage id="EditListingDescriptionForm.storeLinkModelText2" />
                          </p>
                        </div>
                        <div className={css.paragraphList}>
                          <IconCollection name="WRITE_ABOUT_LIST" />
                          <p>
                            <FormattedMessage id="EditListingDescriptionForm.storeLinkModelText3" />
                          </p>
                        </div>
                        <div className={css.paragraphList}>
                          <IconCollection name="WRITE_ABOUT_LIST" />
                          <p>
                            <FormattedMessage id="EditListingDescriptionForm.storeLinkModelText4" />
                          </p>
                        </div>

                        <div className={css.modalSubTitle}>
                          <h4>
                            <FormattedMessage id="EditListingDescriptionForm.storeLinkModelText5" />
                          </h4>
                          <h4>
                            <FormattedMessage id="EditListingDescriptionForm.storeLinkModelText6" />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
        </Modal>
      </div>
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onUpdateProfile: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
