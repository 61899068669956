import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './SectionSuggestedArticles.module.css';
import Collapsible from 'react-collapsible';
import { FormattedMessage } from '../../util/reactIntl';
import { IconAmenities, IconArrowHead } from '..';

const SectionSuggestedArticles = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  const randomText = <FormattedMessage id="SectionFaq.randomText" />;

  // const downArrow = <IconAmenities type="arrowPointingDown" />;
  const downArrow = <IconArrowHead direction="down" />;
  return (
    <div className={classes}>
      <div className={css.sectionMain}>
        <div className={css.sectionContainer}>
          <h1>
            <FormattedMessage id="SectionSuggestedArticles.Suggested" />
           <span className={css.textColor}> <FormattedMessage id="SectionSuggestedArticles.Articles" /></span>
          </h1>

          <div className={css.faqContent}>
            <div>
              <div className={css.faqText}>
                <Collapsible
                  trigger={
                    <FormattedMessage id="SectionFaq.whatIsRuntizeMarketplace" />
                  }
                >
                  {randomText}
                </Collapsible>
                {downArrow}
              </div>
              <div className={css.faqText}>
                <Collapsible
                  trigger={
                    <FormattedMessage id="SectionFaq.whoCanUseMarketplaceExtensions" />
                  }
                >
                  {randomText}
                </Collapsible>
                {downArrow}
              </div>
            </div>

            <div>
              <div className={css.faqText}>
                <Collapsible
                  trigger={
                    <FormattedMessage id="SectionFaq.howDoExtensionsWork" />
                  }
                >
                  {randomText}
                </Collapsible>
                {downArrow}
              </div>
              <div className={css.faqText}>
                <Collapsible
                  trigger={<FormattedMessage id="SectionFaq.howDoIfindFree" />}
                >
                  {randomText}
                </Collapsible>
                {downArrow}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionSuggestedArticles.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

SectionSuggestedArticles.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionSuggestedArticles;
