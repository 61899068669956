import React from 'react';
// import PropTypes from 'prop-types';
import css from './BookingRequestPannel.module.css';
import BookingRequestCardInbox from '../BookingRequestCard/BookingRequestCardInbox';

const BookingRequestPannel = (props) => {
    const { bookingRequestPendingList,
        onAcceptSale,
        onDeclineSale,
        acceptInProgress,
        declineInProgress,
        declineSaleError,
        acceptSaleError,
        onManageDisableScrolling,
    } = props;

    return (
        <div className={css.main}>
            <div className={css.mainVocation}>
                {bookingRequestPendingList &&
                    bookingRequestPendingList?.length > 0 &&
                    bookingRequestPendingList?.filter((e) => e?.attributes?.processName != "instant-booking-process" && !(e?.listing?.attributes?.deleted)).map((l, i) => (
                        <div className={css.productContent}>
                            <BookingRequestCardInbox
                                key={(l.id.uuid) + i}
                                listing={l}
                                onAcceptSale={onAcceptSale}
                                index={i}
                                onManageDisableScrolling={onManageDisableScrolling}
                                onDeclineSale={onDeclineSale}
                                acceptInProgress={acceptInProgress}
                                declineInProgress={declineInProgress}
                                declineSaleError={declineSaleError}
                                acceptSaleError={acceptSaleError}
                            />
                        </div>
                    ))}
            </div>
            {/* <div className={css.paragraph}>
                <p>If you know you will be out of town or just unable to  manage your account and you don't want your response rate to be affected by declined booking requests and slow replies you can turn on Vacation Mode and pause your account</p>
            </div> */}
        </div>
    );
}


BookingRequestPannel.propTypes = {};


export default BookingRequestPannel;