import React from 'react';

import css from './LocationAutocompleteInput.module.css';

const IconHourGlass = () => (

  <svg  className={css.iconSvg} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11 19.0781C15.4183 19.0781 19 15.4964 19 11.0781C19 6.65985 15.4183 3.07812 11 3.07812C6.58172 3.07812 3 6.65985 3 11.0781C3 15.4964 6.58172 19.0781 11 19.0781Z" stroke="#1FD1BC" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M21 21.0782L16.65 16.7281" stroke="#1FD1BC" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  
);

export default IconHourGlass;
