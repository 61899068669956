import React, { Component } from 'react';
import { string, bool } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import {
  IconCollection,
  Map,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
} from '../../components';
import config from '../../config';

import css from './ListingPage.module.css';
import Skeleton from 'react-loading-skeleton';
import '../../assets/react-loading-skeleton.css';

const states = require('us-state-codes');

class SectionMapMaybe extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: true };
  }

  render() {
    const {
      className,
      rootClassName,
      geolocation,
      publicData,
      distanceInMiles,
      listingId,
      showReviews,
    } = this.props;

    if (!geolocation) {
      return null;
    }

    const address =
      publicData && publicData.location ? publicData.location.address : '';
    const formetZipLocation = address?.split(',');

    const splitted = address && address?.split(',');
    const stateName =
      splitted && splitted.length == 4
        ? splitted[2]
        : splitted && splitted.length == 5
        ? splitted[3]
        : splitted && splitted[1];
    const cityName =
      splitted && splitted.length == 4
        ? splitted[1]
        : splitted && splitted.length == 5
        ? splitted[2]
        : splitted && splitted[0];
    const stateName1 =
      stateName?.split(' ')[1] + ' ' + stateName?.split(' ')[2];
    const finalState =
      stateName?.split(' ').length == 4 ? stateName1 : stateName?.split(' ')[1];

    const classes = classNames(rootClassName || css.sectionMap, className, {
      [css.sectionMapWithoutReviews]: !showReviews,
    });
    const cacheKey = listingId
      ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}`
      : null;

    const mapProps = config.maps.fuzzy.enabled
      ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, cacheKey) }
      : { address, center: geolocation };
    const map = <Map {...mapProps} useStaticMap={this.state.isStatic} />;

    return (
      <div className={classes}>
        <h2 className={css.locationTitle}>
          <div className={css.brandStoreIcon}>
            <Menu>
              <MenuLabel>
                <IconCollection name="BRAND_STORE_INFO" />
              </MenuLabel>
              <MenuContent rootClassName={css.menuContent}>
                <MenuItem key="item">
                  Exact address provided after rental confirmation
                </MenuItem>
              </MenuContent>
            </Menu>
          </div>
          <FormattedMessage id="ListingPage.locationTitle" />
        </h2>
        {this.state.isStatic ? (
          <button
            className={css.map}
            onClick={() => {
              this.setState({ isStatic: false });
            }}
          >
            {map}
          </button>
        ) : (
          <div className={css.map}>{map}</div>
        )}
        <p className={css.locationText}>
          {cityName ? cityName : null}, {stateName ? stateName : null}{' '}
          <span className={css.locationText}>
            {distanceInMiles ? (
              <>
                (
                {distanceInMiles.toFixed(0) <= 10
                  ? distanceInMiles.toFixed(1)
                  : distanceInMiles.toFixed(0)}{' '}
                mi)
              </>
            ) : (
              <Skeleton width={50} height={10} />
            )}
          </span>
        </p>
      </div>
    );
  }
}

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
  showReviews: false,
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
  showReviews: bool.isRequired,
};

export default SectionMapMaybe;
