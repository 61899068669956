import React from 'react';
import Earn from './Earn';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import {
  Footer,
  LayoutWrapperFooter,
  LayoutWrapperTopbar,
} from '../../components';
import Banner from './Banner';
import Cards from './Cards';
import Explore from './Explore';
import HowItWorks from './HowItWorks';
import FAQ from './FAQ';
import Senja from './Senja';
import EarnBanner from '../../components/Banner/EarnBanner';

export default function EarnPage() {
  return (
    <main>
      <EarnBanner />
      <LayoutWrapperTopbar>
        <TopbarContainer currentPage="SearchPage" />
      </LayoutWrapperTopbar>
      <Earn />
      <Banner />
      <Cards />
      <div className="py-5">
        <Explore />
      </div>
      <HowItWorks selectedTab="lender" />
      <Senja />
      <FAQ page="earn" />
      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </main>
  );
}
