import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './TabNav.module.css';

const Tab = props => {
  const { className, id, disabled, text, selected, linkProps } = props;
  const linkClasses = classNames(css.link, {
    [css.selectedLink]: selected,
    [css.disabled]: disabled,
  });
  return (
    <div id={id} className={className}>
      <NamedLink className={linkClasses} {...linkProps}>
       <div className={css.menuCircleWrapper}>
       <div className={css.menuCircle}>
          <div className={css.menuCircleDot}></div>
          <svg
            width="16"
            height="13"
            viewBox="0 0 16 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.0463 0.161743C11.3554 1.58056 9.09862 3.79471 7.18899 6.1163C6.53794 6.91164 5.73506 7.81446 5.06239 8.78192C4.28117 7.6426 3.32632 6.6108 2.21968 5.7294C1.67717 5.29951 0.917574 5.21345 0.375065 5.7294C-0.0807213 6.18091 -0.167445 7.12661 0.375065 7.55669C1.78551 8.67455 2.91401 10.0287 3.73866 11.6196C3.76031 11.641 3.76031 11.6626 3.78211 11.684C3.91229 12.9308 5.95215 13.3177 6.34268 11.8775C6.34268 11.8344 6.36432 11.813 6.36432 11.7915V11.7701C6.42941 11.6626 6.47286 11.5336 6.53795 11.4261C6.73321 11.0607 6.95029 10.7167 7.189 10.3727C7.79661 9.44847 8.46928 8.54546 9.18543 7.70713C10.9215 5.62196 12.9179 3.64427 15.3266 2.37597C16.8456 1.60194 15.5219 -0.612211 14.0464 0.161667L14.0463 0.161743Z"
              fill="#1FD1BC"
            />
          </svg>
        </div>
       </div>
        {text}
      </NamedLink>
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
};

const TabNav = props => {
  const {
    className,
    rootClassName,
    tabRootClassName,
    tabs,
    editListNav,
    inboxPage,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;
  return (
    <nav className={classNames(classes, inboxPage && css.inboxPage)}>
      <div className={classNames(css.tabsMain, editListNav && css.editListNav)}>
        {tabs.map((tab, index) => {
          const id = typeof tab.id === 'string' ? tab.id : `${index}`;
          return <Tab key={id} id={id} className={tabClasses} {...tab} />;
        })}
      </div>
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
};

export default TabNav;
