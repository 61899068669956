import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconAmenities, NamedLink, Button } from '..';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionExploreMarketPlace.module.css';

const SectionExploreMarketPlace = props => {
  const { rootClassName, className, exploreTheMarketPlace, viewport } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classNames(classes, css.sectionMain)}>
      <div className={css.sectionContainer}>
        <h1>
          <FormattedMessage
            id="SectionExploreMarketPlace.exploreTheMarketplace"
            values={{
              Marketplace: <span className={css.textColor}>Marketplace</span>,
            }}
          />
        </h1>
        <div className={css.cardContent}>
          {exploreTheMarketPlace &&
            exploreTheMarketPlace.length &&
            exploreTheMarketPlace.map((ele, i) => {
              return (
                <div key={i}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <IconAmenities viewport={viewport} type={ele.icon} />
                 
                  <h4>{ele.name}</h4>
                  {ele.label ? (
                    <Button
                      className={`${ele.label == 'BETA'
                        ? css.betaText
                        : ele.label == 'NEW'
                          ? css.newText
                          : ele.label == 'COMING SOON'
                            ? css.commingText
                            : null
                        }`}
                    >
                      {ele?.label}
                    </Button>
                  ) : null}
                   </NamedLink> 
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

SectionExploreMarketPlace.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;
SectionExploreMarketPlace.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionExploreMarketPlace;
