import { showUser } from "../ProfilePage/ProfilePage.duck"

export const GET_USER_SUCCESS = 'app/MessagePage/GET_USER_SUCCESS'
export const CACHE_USER = 'app/MessagePage/CACHE_USER'

const initialState = {
    uuidToUser: {},
}

const messagePageReducer = (state = initialState, action) => {
    switch (action.type) {
        case CACHE_USER:
            return {
                ...state,
                isLoading: false,
                uuidToUser: {
                    ...state.uuidToUser,
                    [action.payload.id.uuid]: action.payload
                }
            }
        default:
            return state
    }
}

export default messagePageReducer;

export const getUserSuccess = (user) => ({
    type: CACHE_USER,
    payload: user
})

export const cacheUser = (user) => async (dispatch) => {
    dispatch(getUserSuccess(user))
}
