import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './HowCanWeHelpYou.module.css';
import { TopbarSearchForm } from '../../forms';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import IconAmenities from '../IconAmenities/IconAmenities';

const HowCanWeHelpYou = props => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const { rootClassName, className, history, location } = props;

  const handleSubmit = values => {
    const keywords = values;
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        keywords
      )
    );
  };

  const search = (
    <TopbarSearchForm
      landingHero={true}
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={handleSubmit}
      getLocation={location}
    />
  );

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.sectionContainer}>
        <div className={css.heroContentLeft}>
          <h3
            className={classNames(css.heroMainTitle, {
              [css.heroMainTitleFEDelay]: mounted,
            })}
          >
            <FormattedMessage
              id="HowCanWeHelpYou.title"
              values={{
                helpYou: <span className={css.textColor}>Help You?</span>,
              }}
            />
          </h3>
          {/* <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
            <FormattedMessage id="SectionHero.subTitle" />
          </h2> */}

          <div className={css.searchInput}>
            <div className={css.searchIcon}>
              <IconAmenities type="searchLens" />
            </div>
            {search}
          </div>
        </div>
        <div className={css.heroContentRight}>
          <IconAmenities type="howCanWeHelp" />
        </div>
      </div>
    </div>
  );
};

HowCanWeHelpYou.defaultProps = { rootClassName: null, className: null };

HowCanWeHelpYou.propTypes = {
  rootClassName: string,
  className: string,
};

export default HowCanWeHelpYou;
