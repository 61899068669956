import pick from 'lodash/pick';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { transactionLineItems } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import {
  findNextBoundary,
  nextMonthFn,
  monthIdStringInTimeZone,
} from '../../util/dates';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import {
  fetchCurrentUser,
  fetchCurrentUserHasOrdersSuccess,
} from '../../ducks/user.duck';
import moment from 'moment';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST =
  'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS =
  'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST =
  'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS =
  'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const GET_CATEGORY_LISTING_REQUEST = 'app/GET_CATEGORY_LISTING_REQUEST';
export const GET_CATEGORY_LISTING_SUCCESS = 'app/GET_CATEGORY_LISTING_SUCCESS';
export const GET_CATEGORY_LISTING_FAILURE = 'app/GET_CATEGORY_LISTING_FAILURE';

export const FETCH_DAILY_TIME_SLOTS_REQUEST =
  'app/ListingPage/FETCH_DAILY_TIME_SLOTS_REQUEST';
export const FETCH_DAILY_TIME_SLOTS_SUCCESS =
  'app/ListingPage/FETCH_DAILY_TIME_SLOTS_SUCCESS';
export const FETCH_DAILY_TIME_SLOTS_ERROR =
  'app/ListingPage/FETCH_DAILY_TIME_SLOTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2019-12': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  timeSlots: null,
  fetchDailyTimeSlotsError: null,
  timeSlots: null,
  getCategoryInProgress: false,
  getCategoryError: null,
  getAllCategory: [],
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };
    case FETCH_DAILY_TIME_SLOTS_REQUEST:
      return { ...state, fetchDailyTimeSlotsError: null };
    case FETCH_DAILY_TIME_SLOTS_SUCCESS:
      const existingTimeslots = state.timeSlots;
      return {
        ...state,
        timeSlots: !existingTimeslots
          ? payload
          : payload.reduce((arr, item) => {
              const duplicateDay = existingTimeslots.filter(
                itm =>
                  moment(itm.attributes.start).format('YYYY/MM/DD') ==
                  moment(item.attributes.start).format('YYYY/MM/DD')
              );
              if (!duplicateDay || (duplicateDay && !duplicateDay.length)) {
                arr.push(item);
              }
              return arr;
            }, existingTimeslots),
      };
    case FETCH_DAILY_TIME_SLOTS_ERROR:
      return { ...state, fetchDailyTimeSlotsError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return {
        ...state,
        fetchLineItemsInProgress: true,
        fetchLineItemsError: null,
      };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return {
        ...state,
        fetchLineItemsInProgress: false,
        fetchLineItemsError: payload,
      };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return {
        ...state,
        sendEnquiryInProgress: false,
        sendEnquiryError: payload,
      };

    case GET_CATEGORY_LISTING_REQUEST:
      return { ...state, getCategoryInProgress: true, getCategoryError: null };
    case GET_CATEGORY_LISTING_SUCCESS:
      return {
        ...state,
        getCategoryInProgress: false,
        getAllCategory: resultIds(payload.data),
      };
    case GET_CATEGORY_LISTING_FAILURE:
      return {
        ...state,
        getCategoryInProgress: false,
        getCategoryError: payload,
      };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({
  type: FETCH_REVIEWS_SUCCESS,
  payload: reviews,
});
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchDailyTimeSlotsRequest = () => ({
  type: FETCH_DAILY_TIME_SLOTS_REQUEST,
});
export const fetchDailyTimeSlotsSuccess = timeSlots => ({
  type: FETCH_DAILY_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
export const fetchDailyTimeSlotsError = error => ({
  type: FETCH_DAILY_TIME_SLOTS_ERROR,
  error: true,
  payload: error,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({
  type: SEND_ENQUIRY_ERROR,
  error: true,
  payload: e,
});

export const getAllCategoryRequest = () => ({
  type: GET_CATEGORY_LISTING_REQUEST,
});
export const getAllCategorySuccess = response => ({
  type: GET_CATEGORY_LISTING_SUCCESS,
  payload: { data: response.data },
});
export const getAllCategoryFailure = e => ({
  type: GET_CATEGORY_LISTING_FAILURE,
  payload: e,
});

// ================ Thunks ================ //

export const fetchAllCategory = () => (dispatch, getState, sdk) => {
  dispatch(getAllCategoryRequest());
  const params = {
    'fields.image': [
      'variants.default',
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.square-small',
      'variants.square-small2x',
    ],
    'fields.listing ': [
      'title',
      'geolocation',
      'description',
      'price',
      'publicData',
      'availabilityPlan',
    ],
    'fields.user': [
      'profile.displayName',
      'profile.abbreviatedName',
      'profile.metadata',
      'profile.publicData.businessName'
    ],
    include: ['author', 'author.profileImage', 'images'],
    page: 1,
    per_page: 24,
    pub_featured: true,
  };
  sdk.listings
    .query({ ...params })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(getAllCategorySuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(getAllCategoryFailure(e));
    });
};

export const showListing = (listingId, isOwn = false) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      'variants.default',

      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = userId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      type: 'ofProvider',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
      return response;
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

export const fetchReviewsLite = userId => (dispatch, getState, sdk) => {
  return sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      type: 'ofProvider',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      return response;
    });
};

export const fetchReviewsOnce = userId => {
  return sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      type: 'ofProvider',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      return response;
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (
  dispatch,
  getState,
  sdk
) => {
  const monthId = monthIdStringInTimeZone(start, timeZone);
  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    per_page: 500,
    page: 1,
  };
  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      timeSlots.forEach(timeSlot => {
        timeSlot.attributes.start = new Date(
          timeSlot.attributes.start.toLocaleString('en-US', {
            timeZone: timeZone,
          })
        );
        timeSlot.attributes.end = new Date(
          timeSlot.attributes.end.toLocaleString('en-US', {
            timeZone: timeZone,
          })
        );
      });

      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
      dispatch(fetchDailyTimeSlotsSuccess(timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendEnquiry = (listingId, message) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendEnquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const fetchDailyTimeSlots = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchDailyTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const start = moment
    .utc()
    .startOf('day')
    .toDate();
  const end = moment()
    .utc()
    .startOf('day')
    .add(timeSlotsRange, 'days')
    .toDate();
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then(timeSlots => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: moment(end)
            .add(secondRange, 'days')
            .toDate(),
        };

        return dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(fetchDailyTimeSlotsSuccess(combined));
        });
      } else {
        dispatch(fetchDailyTimeSlotsSuccess(timeSlots));
      }
    })
    .catch(e => {
      dispatch(fetchDailyTimeSlotsError(storableError(e)));
    });
};
// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes &&
    attributes.availabilityPlan &&
    attributes.availabilityPlan.timezone;
  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const nextBoundary = findNextBoundary(tz, new Date());

    const nextMonth = nextMonthFn(nextBoundary, tz);
    const nextAfterNextMonth = nextMonthFn(nextMonth, tz);
    const thirdMonth = nextMonthFn(nextAfterNextMonth, tz);
    const daysCount = moment(thirdMonth).diff(moment(), 'days');
    const endDate = moment()
      .add(90, 'days')
      .toDate();
    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextAfterNextMonth, thirdMonth, tz)),
      daysCount < 90
        ? dispatch(fetchTimeSlots(listing.id, thirdMonth, endDate, tz))
        : () => {},
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchTransactionLineItems = ({
  bookingData,
  listingId,
  isOwnListing,
}) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ bookingData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        bookingData: bookingData,
      });
    });
};

export const loadData = (params, search) => (dispatch, getState) => {
  const listingId = new UUID(params.id.split('?')[0]);
  const ownListingVariants = [
    LISTING_PAGE_DRAFT_VARIANT,
    LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  ];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  return Promise.all([
    dispatch(showListing(listingId)).then(r => {
      const listing = r.data.data;
      fetchMonthlyTimeSlots(dispatch, listing);
      dispatch(fetchReviews(listing.relationships.author.data.id));
    }),
    dispatch(fetchAllCategory()),
  ]).then(responses => {
    return responses;
  });
};
