import React from 'react';

const landingFaq = [
  {
    question: 'How do I get my item after booking?',
    answer:
      'You can either meet the lender at their location at the booked time or pay an extra fee of $9 at checkout to get the item delivered directly to you.',
  },
  {
    question: 'Are there any requirements to rent items?',
    answer:
      'Yes, to maintain Runtize as the safe platform it is, you&#39;ll need to upload a form of identification such as an ID card, passport, or driver&#39;s license to our encrypted verification partner so we know you are at least 18 years of age and have no criminal record.',
  },
  {
    question: 'What if there is an issue that needs immediate support?',
    answer:
      'We are very proud of our fast reply rate and high cus`tomer satisfaction score. You can expect a response from our live chat customer support in no more than 10 minutes, with an average reply time of 3 minutes.',
  },
  {
    question: 'Are there any requirements to list items for rent?',
    answer:
      "If you have already signed up and verified your identity, you don't need to do anything else. Just press the 'List an Item' button, and your items will be live within 5 minutes.",
  },
];

const earnFaq = [
  {
    question: "How is my item safe in a stranger's hands?",
    answer:
      "All Runtize users undergo indentity verification and a rigorous background check process. We confirm their identity to ensure accountability and screen for any criminal activity or past online fraud. Additionally, you can review the renter's profile and past reviews before accepting any rental requests.",
  },
  {
    question: 'What if my item gets damaged or stolen?',
    answer:
      'All rentals on Runtize are covered by our $20,000 Covize® Protection Guarantee. This ensures that you are protected against damage or theft that we cannot recover or successfully charge to the renter.',
  },
  {
    question: 'How is the item getting to the renter?',
    answer:
      'Renters have the option to either pick up the item from your location or use our integrated delivery service to have a Runtize courier pick it up.',
  },
  {
    question: 'How do I keep track of rental requests?',
    answer:
      "We will notify you of any important updates or requests via email and text message, so you don't need to constantly check your account.",
  },
];

/**
 * 
 * @param {{ page: "landing" | "earn"}} param
 * @returns 
 */
export default function FAQ({
  page = 'landing',
}) {
  const faqs = page === 'landing' ? landingFaq : earnFaq;
  return (
    <div>
      <section className="faq__area">
        <div className="container">
          <div className="section__title">
            <h3 style={{ lineHeight: '1.2' }}>
              Frequently Asked <br className="d-md-none" />{' '}
              <span>Questions</span>
            </h3>
          </div>
          <div className="faq__inner__blk">
            <div className="accordion" id="faq-accordion">
              <div className="row g-4">
                {faqs.map((faq, i) => (
                  <div className="col-lg-6" key={i}>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id={`heading${i}`}>
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${i}`}
                          aria-expanded="true"
                          aria-controls={`collapse${i}`}
                        >
                          {faq.question}
                        </button>
                      </h2>
                      <div
                        id={`collapse${i}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading${i}`}
                        data-bs-parent="#faq-accordion"
                      >
                        <div className="accordion-body">
                          {faq.answer}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
