import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { REVIEW_TYPE_OF_PROVIDER, propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  IconCollection,
  DashboardNavBar,
} from '../../components';
import config from '../../config';
import { TopbarContainer } from '..';
import css from './DashboardPage.module.css';
import MainDashboard from '../../components/DashboardPannel/MainDashboard/MainDashboard';
import EarnTotal from '../../components/DashboardPannel/EarnPannel/EarnTotal';
import moment from 'moment';
import { BookingRequestPending, month, TransacationPending, TransactionComplete } from '../../marketplace-custom-config';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { vacationListing } from '../SettingPage/SettingPage.duck';
import { TRANSITION_ENQUIRE, TRANSITION_EXPIRE } from '../../util/transaction';

// Current Year Name
export const currentYearName = new Date().getFullYear().toString();

// Previous Year Name
export const previousYearName = (new Date().getFullYear() - 1).toString();

export class DashboardPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileBool: 'profile',
      isMonthId: []
    };
  }
  render() {
    const {
      currentUser,
      scrollingDisabled,
      intl,
      allTransaction,
      params,
      transactions,
      reviews,
      onVacationListing,
      onManageDisableScrolling,
      OwnListing
    } = this.props;

    // Complete Transacation global function
    const completeTransaction = transactions && transactions.length > 0 && transactions
      .filter((item) => (item?.provider?.id?.uuid === currentUser?.id?.uuid))
      .filter((item) => {
        const TransitionStatus = TransactionComplete.includes(item?.attributes?.lastTransition)
        if (TransitionStatus) {
          return item;
        }
      })

    const allTransactions = transactions && transactions.length > 0 && transactions
      .filter((item) => (item?.provider?.id?.uuid === currentUser?.id?.uuid))
      .filter((item) => {
        const TransitionStatus = item?.attributes?.lastTransition !== TRANSITION_EXPIRE && item?.attributes?.lastTransition !== TRANSITION_ENQUIRE
        if (TransitionStatus) {
          return item;
        }
      });


    // Pending Transacation 
    const bookingRequestPendingList = transactions && transactions.filter((item) => {
      const TransitionStatus = BookingRequestPending.includes(item?.attributes?.lastTransition)
      if (moment().unix() < moment(item?.booking?.attributes?.end).unix() && TransitionStatus) {
        return item;
      }
    });

    const onlyPending = bookingRequestPendingList && bookingRequestPendingList?.filter((e) => e.booking.attributes
      .state === 'pending');

    const isProviderBookingPeding = onlyPending && onlyPending.filter((e) => e?.provider?.id?.uuid === currentUser?.id?.uuid);

    const isInstantBookingPending = isProviderBookingPeding &&
      isProviderBookingPeding?.length > 0 &&
      isProviderBookingPeding?.filter((e) => e?.attributes?.processName !== "instant-booking-process" &&
        !(e?.listing?.attributes?.deleted));

    const d = new Date();

    // Current Month Name
    const CurrentMonthName = month[d.getMonth()];

    // PrevMonth Name
    const prevMonthName = month[d.getMonth() - 1];

    const SecondPrevMonth = () => {
      if (prevMonthName == "January") {
        return month[11]
      } else {
        return month[d.getMonth() - 2]
      }
    }

    //SecondPrevMonth Name

    const prevSecondMonthName = SecondPrevMonth();

    // Total Amount
    const TotalAmount = completeTransaction && completeTransaction.length > 0 && completeTransaction
      .reduce((acc, curr) => curr.attributes?.payoutTotal?.amount + acc, 0);

    // Total Amount Current Month
    const TotalAmountCurrentMonth = completeTransaction && completeTransaction.length > 0 && completeTransaction
      .filter((item) => moment(item?.attributes?.lastTransitionedAt).format('MMMM') == CurrentMonthName)
      .reduce((acc, curr) => curr.attributes?.payoutTotal?.amount + acc, 0);

    // Total Amount Prev Month 
    const TotalAmountPrevMonth = completeTransaction && completeTransaction.length > 0 && completeTransaction
      .filter((item) => moment(item?.attributes?.lastTransitionedAt).format('MMMM') == prevMonthName)
      .reduce((acc, curr) => curr.attributes?.payoutTotal?.amount + acc, 0);

    // Total Amount Second Prev Month 
    const TotalAmountSecondPrevMonth = completeTransaction && completeTransaction.length > 0 && completeTransaction
      .filter((item) => month.includes(moment(item?.attributes?.lastTransitionedAt).format('MMMM')))
      .reduce((acc, curr) => curr.attributes?.payoutTotal?.amount + acc, 0);

    // Total Amount Current Year
    const TotalAmountCurrentYear = completeTransaction && completeTransaction.length > 0 && completeTransaction
      .filter(item => moment(item?.attributes?.lastTransitionedAt).format('YYYY') === currentYearName)
      .reduce((acc, curr) => curr.attributes?.payoutTotal?.amount + acc, 0);

    //Total Amount Previous Year
    const TotalAmountPreviousYear = completeTransaction && completeTransaction.length > 0 && completeTransaction
      .filter(item => moment(item?.attributes?.lastTransitionedAt).format('YYYY') === previousYearName)
      .reduce((acc, curr) => curr.attributes?.payoutTotal?.amount + acc, 0);

    const calculateTotalAmounts = (data, months, yearName = null) => {
      const monthTotals = {};
      if (yearName && data) {
        data = data.filter(item => moment(item?.attributes?.lastTransitionedAt).format('YYYY') === yearName);
      }

      // Initialize all months with 0 amounts and rentals
      months.forEach(monthName => {
        monthTotals[monthName] = { amount: 0, rentals: 0 };
      });

      data && data.forEach(item => {
        const monthName = moment(item?.attributes?.lastTransitionedAt).format('MMMM');
        const amount = item.attributes?.payoutTotal?.amount || 0;
        const rentals = 1;

        if (months.includes(monthName)) {
          monthTotals[monthName].amount += amount;
          monthTotals[monthName].rentals += rentals;
        }
      });

      return monthTotals;
    };


    const monthTotalAmounts = calculateTotalAmounts(completeTransaction, month);

    // ========================   Charts Functions ==============================================

    // CurrentMonth Amounts Chart

    const CurrentMonthArrayChart = completeTransaction && completeTransaction.length > 0 && completeTransaction.reduce((acc, curr) => {
      if (moment(curr?.attributes?.lastTransitionedAt).format('MMMM') == CurrentMonthName) {
        acc.unshift([moment(curr?.attributes?.lastTransitionedAt).format('LL'), curr?.attributes?.payinTotal?.amount / 100])
        return acc;
      } else {
        return []
      }
    }, [])


    CurrentMonthArrayChart.length > 0 && CurrentMonthArrayChart.unshift(["date", "Earning"])

    // PrevMonths Amounts Chart

    const PrevMonthArrayChart = completeTransaction && completeTransaction.length > 0 && completeTransaction.reduce((acc, curr) => {
      if (moment(curr?.attributes?.lastTransitionedAt).format('MMMM') == prevMonthName) {
        acc.unshift([moment(curr?.attributes?.lastTransitionedAt).format('LL'), curr?.attributes?.payinTotal?.amount / 100])
        return acc;
      } else {
        return []
      }
    }, [])

    PrevMonthArrayChart.length > 0 && PrevMonthArrayChart.unshift(["date", "Earning"])

    //SecondPrevMonth Name Chart

    const prevSecondMonthArrayChart = completeTransaction && completeTransaction.length > 0 && completeTransaction.reduce((acc, curr) => {
      if (moment(curr?.attributes?.lastTransitionedAt).format('MMMM') == prevSecondMonthName) {
        acc.unshift([moment(curr?.attributes?.lastTransitionedAt).format('LL'), curr?.attributes?.payinTotal?.amount / 100])
        return acc;
      } else {
        return []
      }
    }, [])

    prevSecondMonthArrayChart.length > 0 && prevSecondMonthArrayChart.unshift(["date", "Earning"])

    let props = {
      TotalAmount: TotalAmount,

      CurrentMonthDetails: {
        TotalAmountCurrentMonth: TotalAmountCurrentMonth,
        CurrentMonthName: CurrentMonthName,
      },
      prevMonthsDetails: {
        TotalAmountPrevMonth: TotalAmountPrevMonth,
        prevMonthName: prevMonthName,
      },

      secondprevMonthDetais: {
        prevSecondMonthName: prevSecondMonthName,
        TotalAmountSecondPrevMonth: TotalAmountSecondPrevMonth
      },

      currentYearDetails: {
        totalAmount: TotalAmountCurrentYear,
        name: currentYearName,
        monthTotalAmounts: calculateTotalAmounts(completeTransaction, month, currentYearName),
      },

      previousYearDetails: {
        totalAmount: TotalAmountPreviousYear,
        name: previousYearName,
        monthTotalAmounts: calculateTotalAmounts(completeTransaction, month, previousYearName),
      },

      Charts: {
        CurrentMonthArrayChart: CurrentMonthArrayChart,
        PrevMonthArrayChart: PrevMonthArrayChart,
        prevSecondMonthArrayChart: prevSecondMonthArrayChart
      },
      Booking: {
        requestBookings: isInstantBookingPending.length,
        totalRentals: completeTransaction.length,
      }

    }

    const DashbordMain =
      (
        <MainDashboard {...props} reviews={reviews.filter(item => item?.attributes?.type === REVIEW_TYPE_OF_PROVIDER)} OwnListing={OwnListing} currentUser={currentUser} monthTotalAmounts={monthTotalAmounts} onVacationListing={onVacationListing} onManageDisableScrolling={onManageDisableScrolling} />
      )

    const EarnTotalComponent = (
      <EarnTotal completeTransaction={completeTransaction} TotalAmount={TotalAmount} />
    )

    const title = intl.formatMessage({ id: 'DashboardPage.NameLabel' });


    return (
      <Page
        className={css.root}
        title={title}
        scrollingDisabled={scrollingDisabled}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ProfileSettingsPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.sectionWrapper}>
            <div className={css.content}>
              {/* <div className={css.routeLink}>
                <NamedLink name="LandingPage">
                  {' '}
                  <FormattedMessage id="ProfileSettingsForm.homeLink" />
                </NamedLink>
                <IconCollection name="PROFILE_ARROW" />
                <p className={css.accountSetting}>
                  <FormattedMessage id="DashboardPage.NameLabel" />
                </p>
              </div> */}
              <div className={css.navMainContainer}>
                <div className={css.navContainer}>
                  {' '}
                  <DashboardNavBar currentUser={currentUser} />
                </div>
                <div className={css.informationContainer}>
                  <div className={css.informationContents}>
                    {/* <div className={css.arrowBack}>
                      <NamedLink name="LandingPage">
                        <IconCollection name="BACK_ARROW_PERSONAL" />
                      </NamedLink>
                    </div> */}
                    {/* <h2 className={css.title}>
                      {params.slug == "earntotal" ? null : <FormattedMessage id="DashboardPage.NameLabel" />}

                    </h2> */}
                    {/* <div className={css.borderTitle}></div> */}
                    {params.slug == "earntotal" ? EarnTotalComponent : DashbordMain}
                    { }
                  </div>
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

DashboardPageComponent.defaultProps = {
  currentUser: null,
  currentUserListing: null,
  uploadImageError: null,
  updateProfileError: null,
};

DashboardPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  intl: intlShape.isRequired,
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = state => {
  const { allTransaction, transactionRefs } = state.DashboardPage;
  const {
    currentUser,
    OwnListing,
  } = state.user;
  const {
    reviews,
  } = state.ProfilePage;

  return { OwnListing, allTransaction, reviews, currentUser, transactions: getMarketplaceEntities(state, transactionRefs), scrollingDisabled: isScrollingDisabled(state) };
};

const mapDispatchToProps = dispatch => ({
  onVacationListing: (params) => dispatch(vacationListing(params)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});
const DashboardPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(DashboardPageComponent);

export default DashboardPage;
