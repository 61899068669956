import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import moment from 'moment';
import {
  LINE_ITEM_NIGHT,
  LINE_ITEM_UNITS,
  DATE_TYPE_DATE,
  propTypes,
} from '../../util/types';

import css from './BookingBreakdown.module.css';

const BookingPeriod = props => {
  const { startDate, endDate, dateType, timeZone ,selectedTime,pageName} = props;

  const timeFormatOptions =
    dateType === DATE_TYPE_DATE
      ? {
          weekday: 'long',
        }
      : {
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
        };

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
  };

  const timeZoneMaybe = timeZone ? { timeZone } : null;
  const oneDayEndDate = moment(startDate).add(1,'day')
  
  return (
    <>
      <div className={css.bookingPeriod}>
        <div className={css.bookingPeriodSection}>
          {
            pageName!=="chekoutPage" ? 
            <div className={css.dayLabel}>
            <FormattedMessage id="BookingBreakdown.bookingStart" />
          </div>
          :null
          }
          <div className={css.dayInfo}>
            {
               pageName!=="chekoutPage"?
              <FormattedDate
              value={startDate}
              {...timeFormatOptions}
              {...timeZoneMaybe}
            /> 
            :null
            }
            
          </div>
          <div className={css.itemLabel}>
            {
              pageName!=="chekoutPage" ? 
              <FormattedDate
              value={startDate}
              {...dateFormatOptions}
              {...timeZoneMaybe}
            />
            :null
            }
           
          </div>
        </div>

        <div className={css.bookingPeriodSectionRigth}>
          {
            pageName!=="chekoutPage" ?
            <div className={css.dayLabel}>
            <FormattedMessage id="BookingBreakdown.bookingEnd" />
          </div>
          :null 

          }
          <div className={css.dayInfo}>
            {
               pageName!=="chekoutPage"?
              <FormattedDate
              value={endDate._i}
              {...timeFormatOptions}
              {...timeZoneMaybe}
            />
            :null
              }
           </div>
          <div className={css.itemLabel}>
            {
              pageName!=="chekoutPage" ? 
              <FormattedDate
              value={endDate._i}
              {...dateFormatOptions}
              {...timeZoneMaybe}
            />
            :null
            }
            
          </div>
        </div>
      </div>
    </>
  );
};

const LineItemBookingPeriod = props => {
  const { booking, unitType, dateType, timeZone,selectedTime,pageName } = props;

  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = displayStart || start;
  const localEndDateRaw = displayEnd || end;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isUnit = unitType === LINE_ITEM_UNITS;
  const endDay =
    isUnit || isNightly
      ? localEndDateRaw
      : moment(localEndDateRaw).subtract(1, 'days');

  return (
    <>
      <div className={css.lineItem}>
        <BookingPeriod
          startDate={localStartDate}
          pageName={pageName}
          endDate={endDay}
          dateType={dateType}
          timeZone={timeZone}
          selectedTime={selectedTime}
        />
      </div>
      <hr className={css.totalDivider} />
    </>
  );
};
LineItemBookingPeriod.defaultProps = { dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;
