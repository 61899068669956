import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, format, ...rest } = props;

  if (format === 'desktop') {
    return (
      <svg
        width="265"
        height="40"
        viewBox="0 0 265 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1_592)">
          <path d="M75.7173 22.9193C75.7173 21.0941 76.0674 19.3688 76.7646 17.7435C77.4619 16.1182 78.41 14.7015 79.6091 13.4935C80.8081 12.2856 82.2084 11.3304 83.8101 10.625C85.4146 9.91958 87.1184 9.56982 88.9301 9.56982H88.9826C89.9307 9.56982 90.663 9.84022 91.1793 10.381C91.6957 10.9218 91.9554 11.6096 91.9554 12.4472C91.9554 12.8646 91.8766 13.2555 91.722 13.6111C91.5674 13.9697 91.3544 14.2754 91.0889 14.5281C90.8205 14.7809 90.5084 14.9807 90.1466 15.1306C89.7848 15.2776 89.3968 15.354 88.9826 15.354H88.8542C87.8711 15.3716 86.9375 15.5744 86.0477 15.9653C85.1608 16.3562 84.379 16.8853 83.708 17.5554C83.037 18.2226 82.4973 19.0015 82.0918 19.8861C81.6862 20.7708 81.4762 21.726 81.4587 22.7518V31.8689C81.4587 32.2686 81.3858 32.6419 81.2399 32.9887C81.094 33.3355 80.8956 33.6412 80.6447 33.8998C80.3938 34.1614 80.0963 34.3642 79.752 34.5112C79.4078 34.6581 79.046 34.7316 78.6668 34.7316C78.2875 34.7316 77.8937 34.6581 77.5407 34.5112C77.1877 34.3642 76.8726 34.1585 76.5984 33.8969C76.3212 33.6353 76.1082 33.3326 75.9507 32.9858C75.7932 32.639 75.7173 32.2628 75.7173 31.863V22.9193Z" fill="#06062F" />
          <path d="M94.8231 12.4589C94.8231 12.0592 94.8961 11.6859 95.0419 11.3391C95.1878 10.9923 95.392 10.6866 95.6487 10.428C95.9084 10.1664 96.2089 9.96358 96.5531 9.81663C96.8974 9.66967 97.2679 9.59619 97.6646 9.59619C98.0614 9.59619 98.4348 9.66967 98.7907 9.81663C99.1437 9.96358 99.4501 10.1693 99.7097 10.428C99.9694 10.6866 100.176 10.9923 100.331 11.3391C100.486 11.6859 100.564 12.0592 100.564 12.4589V22.7752C100.564 23.6951 100.728 24.5475 101.055 25.3293C101.381 26.1111 101.834 26.793 102.411 27.3749C102.989 27.9569 103.666 28.4124 104.442 28.7416C105.218 29.0708 106.061 29.2354 106.977 29.2354C107.893 29.2354 108.684 29.0649 109.407 28.7269C110.131 28.3889 110.752 27.9275 111.268 27.3455C111.785 26.7636 112.181 26.0788 112.459 25.2881C112.733 24.4975 112.873 23.6422 112.873 22.7223V12.4589C112.873 12.0592 112.952 11.6859 113.106 11.3391C113.261 10.9923 113.477 10.6866 113.754 10.428C114.028 10.1664 114.34 9.96358 114.685 9.81663C115.029 9.66967 115.399 9.59619 115.796 9.59619C116.193 9.59619 116.563 9.66967 116.908 9.81663C117.252 9.96358 117.549 10.1693 117.8 10.428C118.051 10.6895 118.25 10.9923 118.395 11.3391C118.541 11.6859 118.614 12.0592 118.614 12.4589V22.7223C118.614 24.424 118.32 26.017 117.736 27.5013C117.15 28.9856 116.339 30.2876 115.306 31.4074C114.273 32.5272 113.045 33.4089 111.621 34.0526C110.201 34.6963 108.651 35.0166 106.98 35.0166C105.308 35.0166 103.709 34.6963 102.236 34.0526C100.763 33.4119 99.4734 32.5301 98.3706 31.4074C97.2679 30.2876 96.4014 28.9856 95.7713 27.5013C95.1411 26.017 94.829 24.424 94.829 22.7223V12.4589H94.8231Z" fill="#06062F" />
          <path d="M122.541 21.9287C122.541 20.2298 122.856 18.6339 123.486 17.1408C124.116 15.6478 124.977 14.3428 126.071 13.2259C127.165 12.1061 128.446 11.2273 129.91 10.5837C131.375 9.94293 132.953 9.61963 134.642 9.61963C136.331 9.61963 137.921 9.93999 139.412 10.5837C140.903 11.2244 142.204 12.1061 143.316 13.2259C144.427 14.3457 145.305 15.6507 145.953 17.1408C146.601 18.6339 146.921 20.2298 146.921 21.9287V31.8952C146.921 32.2949 146.848 32.6682 146.703 33.015C146.557 33.3618 146.358 33.6616 146.107 33.9114C145.857 34.1642 145.559 34.3641 145.215 34.511C144.871 34.658 144.5 34.7315 144.103 34.7315C143.707 34.7315 143.333 34.658 142.977 34.511C142.621 34.3641 142.315 34.1642 142.047 33.9114C141.778 33.6587 141.568 33.3589 141.413 33.0121C141.259 32.6653 141.18 32.292 141.18 31.8923V21.9404C141.163 21.0381 140.976 20.1916 140.623 19.401C140.27 18.6104 139.794 17.9197 139.199 17.3289C138.604 16.7382 137.913 16.2709 137.128 15.9211C136.34 15.5743 135.509 15.4009 134.628 15.4009C133.747 15.4009 132.871 15.5713 132.101 15.9093C131.331 16.2473 130.663 16.7088 130.094 17.2907C129.525 17.8727 129.079 18.5634 128.761 19.3628C128.44 20.1622 128.282 21.0117 128.282 21.9169V31.8923C128.282 32.292 128.209 32.6653 128.064 33.0121C127.918 33.3589 127.719 33.6587 127.471 33.9114C127.223 34.1642 126.926 34.3641 126.584 34.511C126.24 34.658 125.881 34.7315 125.502 34.7315C125.123 34.7315 124.729 34.658 124.37 34.511C124.011 34.3641 123.696 34.1642 123.431 33.9114C123.165 33.6587 122.949 33.3618 122.786 33.015C122.623 32.6682 122.541 32.2949 122.541 31.8952V21.9287Z" fill="#06062F" />
          <path d="M151.604 4.95818C151.604 4.54083 151.683 4.1558 151.837 3.80017C151.992 3.44453 152.202 3.13593 152.47 2.87435C152.739 2.61276 153.042 2.40409 153.389 2.24831C153.734 2.09254 154.104 2.01318 154.501 2.01318C154.898 2.01318 155.277 2.08666 155.639 2.23362C156 2.38057 156.318 2.58925 156.595 2.85965C156.87 3.13005 157.091 3.4416 157.255 3.79723C157.418 4.15286 157.5 4.54083 157.5 4.95524V9.14936H166.576C166.973 9.14936 167.346 9.22872 167.699 9.38449C168.052 9.54027 168.358 9.74894 168.618 10.0105C168.878 10.2721 169.085 10.5807 169.239 10.9363C169.394 11.292 169.473 11.6711 169.473 12.0679C169.473 12.4647 169.394 12.8115 169.239 13.1495C169.085 13.4875 168.878 13.7844 168.618 14.0342C168.358 14.2869 168.052 14.4868 167.699 14.6338C167.346 14.7807 166.97 14.8542 166.576 14.8542H157.5V22.4606C157.5 23.4158 157.672 24.3064 158.016 25.1293C158.36 25.9552 158.836 26.6753 159.44 27.2925C160.044 27.9098 160.753 28.3918 161.572 28.7386C162.392 29.0854 163.282 29.2588 164.248 29.2588C165.091 29.2588 165.876 29.1383 166.599 28.8944C167.323 28.6504 167.996 28.2595 168.615 27.7217C168.959 27.4277 169.295 27.1867 169.624 27.0045C169.951 26.8223 170.339 26.7312 170.788 26.7312C171.115 26.7312 171.436 26.8017 171.745 26.9398C172.055 27.078 172.332 27.2749 172.574 27.5247C172.816 27.7746 173.009 28.0714 173.154 28.4094C173.3 28.7474 173.373 29.1089 173.373 29.491C173.373 29.9436 173.286 30.3757 173.114 30.793C172.941 31.2104 172.682 31.5837 172.338 31.9128C171.252 32.9533 170.024 33.7351 168.653 34.2582C167.282 34.7785 165.814 35.0401 164.245 35.0401C162.486 35.0401 160.84 34.7138 159.306 34.0643C157.771 33.4118 156.435 32.5183 155.297 31.3809C154.159 30.2434 153.258 28.9061 152.596 27.369C151.931 25.8318 151.601 24.1771 151.601 22.4077V4.95818H151.604Z" fill="#06062F" />
          <path d="M176.579 12.4854C176.579 12.1033 176.655 11.7359 176.81 11.3773C176.964 11.0217 177.175 10.7131 177.44 10.4515C177.705 10.1899 178.021 9.98123 178.379 9.82546C178.738 9.66969 179.118 9.59033 179.511 9.59033C179.905 9.59033 180.276 9.66969 180.617 9.82546C180.961 9.98123 181.256 10.1899 181.504 10.4515C181.752 10.7131 181.95 11.0217 182.096 11.3773C182.242 11.7329 182.315 12.1033 182.315 12.4854V32.101C182.315 32.5008 182.242 32.874 182.096 33.2208C181.95 33.5677 181.749 33.8733 181.492 34.132C181.235 34.3936 180.935 34.5964 180.591 34.7433C180.247 34.8903 179.879 34.9637 179.485 34.9637C179.091 34.9637 178.718 34.8903 178.365 34.7433C178.012 34.5964 177.703 34.3906 177.437 34.132C177.172 33.8704 176.962 33.5677 176.807 33.2208C176.652 32.874 176.576 32.5008 176.576 32.101V12.4854H176.579Z" fill="#06062F" />
          <path d="M207.244 9.59326C207.571 9.59326 207.903 9.65792 208.239 9.78724C208.574 9.91656 208.88 10.1047 209.158 10.3486C209.432 10.5926 209.656 10.8835 209.829 11.2215C210.001 11.5595 210.088 11.9475 210.088 12.3795V12.4324C210.088 12.8851 209.992 13.3054 209.802 13.6963C209.613 14.0872 209.362 14.4134 209.053 14.6721L195.195 29.1031H207.839C208.667 29.1031 209.356 29.3647 209.907 29.8849C210.459 30.4052 210.736 31.0665 210.736 31.8659C210.736 32.2656 210.663 32.6389 210.517 32.9857C210.371 33.3325 210.167 33.6382 209.91 33.8968C209.654 34.1555 209.344 34.3612 208.991 34.5082C208.638 34.6551 208.253 34.7286 207.842 34.7286H189.072C188.657 34.7286 188.275 34.6463 187.922 34.4817C187.569 34.3171 187.263 34.0996 187.003 33.8292C186.743 33.5588 186.542 33.2561 186.396 32.9181C186.25 32.5801 186.178 32.2274 186.178 31.863C186.16 31.5161 186.224 31.1723 186.373 30.8343C186.519 30.4963 186.738 30.1788 187.032 29.8849L201.021 15.3245H189.334C188.937 15.3245 188.567 15.2452 188.223 15.0894C187.878 14.9336 187.575 14.725 187.318 14.4634C187.059 14.2018 186.857 13.8991 186.711 13.5523C186.566 13.2054 186.493 12.8322 186.493 12.4324C186.493 12.0327 186.566 11.6595 186.711 11.3126C186.857 10.9658 187.061 10.666 187.318 10.4133C187.578 10.1605 187.878 9.96065 188.223 9.8137C188.567 9.66674 188.937 9.59326 189.334 9.59326H207.253H207.244Z" fill="#06062F" />
          <path d="M176.39 4.26475C176.39 3.82976 176.471 3.42122 176.635 3.03914C176.798 2.65705 177.02 2.32199 177.303 2.0369C177.586 1.74886 177.916 1.52549 178.295 1.3609C178.671 1.19631 179.085 1.11401 179.532 1.11401C179.978 1.11401 180.363 1.19631 180.742 1.3609C181.119 1.52549 181.451 1.7518 181.734 2.0369C182.017 2.32199 182.245 2.65705 182.417 3.03914C182.589 3.42122 182.674 3.82976 182.674 4.26475C182.674 4.69974 182.589 5.13473 182.417 5.51387C182.245 5.89596 182.017 6.23102 181.734 6.51611C181.451 6.80415 181.122 7.02752 180.742 7.19211C180.366 7.3567 179.961 7.439 179.532 7.439C179.103 7.439 178.674 7.3567 178.295 7.19211C177.918 7.02752 177.586 6.80121 177.303 6.51611C177.02 6.23102 176.795 5.89596 176.635 5.51387C176.471 5.13179 176.39 4.71443 176.39 4.26475Z" fill="#E75B57" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M64.395 15.9005C55.9142 8.83488 49.6244 12.2061 46.0156 16.4883C42.964 20.1064 37.1731 27.4307 37.1731 27.4307L47.4743 36.7683L64.395 15.9005Z" fill="#11E3BE" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1758 30.0084C19.1758 30.0084 27.2861 37.2328 29.3866 39.1197C31.4871 41.0066 39.6674 45.5887 47.4743 36.7684C47.4743 36.7684 35.3614 25.7349 33.4709 24.1302C31.4813 22.4402 26.9944 21.3674 23.8436 24.718C21.8394 26.8489 19.1758 30.0084 19.1758 30.0084Z" fill="#68CEBC" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 26.0994C8.48078 33.1651 14.7706 29.7939 18.3794 25.5116C21.431 21.8936 27.2219 14.5693 27.2219 14.5693L16.9207 5.23169L0 26.0994Z" fill="#E75B57" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M45.2192 11.9916C45.2192 11.9916 37.1089 4.76727 35.0084 2.88035C32.9079 0.993439 24.7276 -3.58865 16.9207 5.23165C16.9207 5.23165 29.0336 16.2651 30.9241 17.8699C32.9137 19.5598 37.4006 20.6326 40.5514 17.282C42.5556 15.1512 45.2192 11.9916 45.2192 11.9916Z" fill="#F0A840" />
          <path d="M238.778 22.8193C237.85 23.0397 230.679 24.7503 230.679 24.7503C229.124 25.0824 230.084 25.7173 230.778 25.9171C230.994 25.9788 231.496 26.1375 232.085 26.3227C231.826 26.8047 231.505 27.2309 231.125 27.61C230.74 27.9892 230.198 28.383 229.497 28.7916C228.844 29.1707 228.158 29.4352 227.441 29.5793C226.723 29.7233 226.017 29.7585 225.32 29.6821C224.623 29.6057 223.943 29.4264 223.281 29.1413C222.618 28.8562 222.017 28.4741 221.472 27.998L236.07 19.5069C236.817 19.0719 237.299 18.49 237.511 17.7581C237.724 17.0263 237.617 16.2856 237.185 15.5303C236.254 13.9814 235.084 12.6881 233.672 11.6594C232.26 10.6278 230.746 9.90479 229.127 9.48744C227.508 9.07008 225.836 8.96428 224.118 9.17001C222.397 9.37575 220.76 9.93125 219.202 10.8365C217.63 11.7506 216.32 12.9174 215.272 14.3311C214.225 15.7448 213.475 17.2732 213.026 18.9161C212.577 20.5591 212.451 22.252 212.653 23.9979C212.854 25.7437 213.411 27.4161 214.33 29.0149C215.237 30.5991 216.39 31.9129 217.784 32.9504C219.179 33.9909 220.696 34.7227 222.332 35.1518C223.969 35.5809 225.658 35.6926 227.397 35.4839C229.136 35.2782 230.793 34.7168 232.365 33.8028C233.41 33.1973 234.32 32.5566 235.096 31.8835C235.519 31.5191 235.887 31.1546 236.21 30.7902C236.452 31.5367 236.657 32.1657 236.721 32.3744C236.919 33.0063 237.546 33.406 237.812 32.3744C238.077 31.3427 239.665 24.7797 239.895 23.8156C240.125 22.8487 240.026 22.5166 238.772 22.8163L238.778 22.8193ZM218.598 21.482C218.648 20.7207 218.817 19.9889 219.103 19.2894C219.389 18.5899 219.786 17.9345 220.296 17.3231C220.807 16.7118 221.428 16.1945 222.16 15.7683C222.893 15.3422 223.648 15.0512 224.427 14.8895C225.206 14.7279 225.973 14.6956 226.723 14.7925C227.473 14.8895 228.196 15.1012 228.894 15.4274C229.591 15.7536 230.221 16.1945 230.784 16.7441L218.802 23.7128C218.613 22.9868 218.546 22.2403 218.595 21.479L218.598 21.482Z" fill="#11E3BE" />
        </g>
        <defs>
          <clipPath id="clip0_1_592">
            <rect width="240" height="42" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      className={className}
      {...rest}
      width="28"
      height="26"
      viewBox="0 0 28 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="#0095B3"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M14.0221667 14.9028333c-2.123449-1.7178872-3.3997596-4.2705084-3.5-6.99999997C10.5891066 5.44596767 11.6329727 3.11721418 13.4225 1.4325c.3334333-.32683002.8670667-.32683002 1.2005 0 1.7886677 1.68521534 2.8320183 4.01375565 2.8991667 6.47033333-.1002404 2.72949157-1.376551 5.28211277-3.5 6.99999997z" />
        <path d="M17.43 6.67783333C19.1014265 5.54101183 21.0786397 4.93849069 23.1 4.95c.4796887.00063444.8689352.3883177.8715.868.0697835 2.45026057-.8386379 4.82752063-2.5246667 6.6068333-2.0009973 1.8590387-4.7084726 2.7613887-7.4246666 2.4745M10.6166667 6.69066667C8.9359545 5.53533534 6.93938056 4.92739894 4.9 4.95c-.48033591-.0000107-.87062682.38767479-.87383333.868-.07166144 2.4509653.835542 4.82959311 2.52116666 6.6103333 2.01953017 1.8618845 4.74324717 2.7635551 7.47483337 2.4745M22.75 16.0625c0 2.4161667-3.9176667 4.375-8.75 4.375-4.83233333 0-8.75-1.9541667-8.75-4.375" />
        <path d="M26.0283333 12.559c.701412 1.0334801 1.0821012 2.2510363 1.0943334 3.5 0 4.8323333-5.8765 8.75-13.125 8.75-7.24850003 0-13.12500003-3.9141667-13.12500003-8.7465.01168117-1.248701.39154021-2.4661979 1.092-3.5" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
