import React, { useState } from 'react';
import classNames from 'classnames';
import { IsDraftListing } from '../../marketplace-custom-config';
import css from './SettingPannel.module.css';
import Modal from '../Modal/Modal';
import { FormattedMessage } from 'react-intl';
import IconCollection from '../IconCollection/IconCollection';
import NamedLink from '../NamedLink/NamedLink';
const states = require('us-state-codes');
const SettingPannel = (props) => {
  const [openModal, setopenModal] = useState(false);

  const { currentUser, onVacationListing, OwnListing ,onManageDisableScrolling} = props;
  const {address}=currentUser&&currentUser.attributes?.profile?.publicData?.locationForListings||{}
  const splitted = address && address.split(",");
  const stateName = splitted && splitted.length == 4 ? splitted[2] :splitted && splitted.length == 5 ? splitted[3] : splitted?.[1];
  const cityName =  splitted && splitted.length == 4 ? splitted[1] : splitted && splitted.length == 5 ? splitted[2] : splitted?.[0]
  const  stateName1 = stateName?.split(" ")[1]+" "+stateName?.split(" ")[2]
  const finalState = stateName?.split(" ").length == 4 ? stateName1:stateName?.split(" ")[1]
  
  const inVacationMode =
    (currentUser &&
      currentUser.id &&
      currentUser.attributes.profile.publicData.isVacationMode) ||
    false;
  const IsDraftArray =
    OwnListing &&
    OwnListing.length > 0 &&
    OwnListing.filter((item, idx) =>
      IsDraftListing.includes(item?.attributes.state)
    );
  
  const IsDraftStatus = IsDraftListing.includes(
    IsDraftArray[0]?.attributes?.state
  );
 
  const handleModel = () => {
    setopenModal(!openModal);
  };

  const isDraft = <Modal
    id="SettingPage.isDraft"
    settingVacation={true}
    isOpen={openModal}
    onClose={() => { setopenModal(false)}}
    onManageDisableScrolling={onManageDisableScrolling}
    usePortal
   >
     <div className={css.modalContainer}>
      <h5>Please finish your draft listings first</h5>
      <NamedLink name="ManageListingPage">Click</NamedLink>
     </div>
  </Modal>

  return (
    <div className={css.settingCards}>
      {/* <div className={css.cardWrapper}>
        <div className={css.mainContents}>
          <div className={css.totalAmount}>
            <div className={css.cardTitle}>
              <h6>
                <FormattedMessage id="SettingPage.instantBook" />
              </h6>
            </div>
          </div>
          <div className={css.viewDetails}>
            <h6>
            {openModal ? <FormattedMessage id="SettingPage.on" />
             : <FormattedMessage id="SettingPage.off" />}
            </h6>
            <div
              className={css.checkboxWrapper}
              onClick={() => {
                if (IsDraftStatus) {
                  handleModel();
                } else {
                  onVacationListing(inVacationMode ? 'Close' : 'Open');
                }
              }}
            >
              <div
                className={classNames(css.ball, {
                  [css.toggled]: openModal,
                })}
              ></div>
            </div>
          </div>
        </div>
        <div className={css.paragraph}>
          <p>
            <FormattedMessage id="SettingPage.instantDescription" />
          </p>
        </div>
      </div> */}
      {/* --------------------------------- */}
      {/* <div className={css.cardWrapper}>
        <div className={css.mainContents}>
          <div className={css.totalAmount}>
            <div className={css.cardTitle}>
              <h6>
                <FormattedMessage id="SettingPage.deliveredYouTitle" />
              </h6>
            </div>
          </div>
          <div className={css.viewDetails}>
            <h6>
            {openModal ? <FormattedMessage id="SettingPage.on" />
             : <FormattedMessage id="SettingPage.off" />}
            </h6>
            <div
              className={css.checkboxWrapper}
              onClick={() => {
                if (IsDraftStatus) {
                  handleModel();
                } else {
                  onVacationListing(inVacationMode ? 'Close' : 'Open');
                }
              }}
            >
              <div
                className={classNames(css.ball, {
                  [css.toggled]: openModal,
                })}
              ></div>
            </div>
          </div>
        </div>
        <div className={css.paragraph}>
          <p>
            <FormattedMessage id="SettingPage.deliveredYouDescription" />
          </p>
        </div>
      </div> */}
      {/* --------------------------------- */}
      {/* <div className={css.cardWrapper}>
        <div className={css.mainContents}>
          <div className={css.totalAmount}>
            <div className={css.cardTitle}>
              <h6>
                <FormattedMessage id="SettingPage.generalTitle" />
              </h6>
            </div>
          </div>
        </div>
        <div className={css.paragraph}>
          <p>
            <FormattedMessage id="SettingPage.generalDescription" />
          </p>
        </div>
        <div className={css.changeCards}>
        <div className={css.changeCard}>
        <div className={css.textContents}>
                <p>Available days</p>
                <h6>All</h6>
            </div>
            <button><FormattedMessage id="SettingPage.change" /></button>
        </div>
        <div className={css.changeCard}>
            <div className={css.textContents}>
                <p>Time</p>
                <h6>08:00 AM - 10:00 PM</h6>
            </div>
            <button><FormattedMessage id="SettingPage.change" /></button>
        </div>
        </div>
      </div> */}
      {/* --------------------------------- */}
      <div className={css.cardWrapper}>
        <div className={css.mainContents}>
          <div className={css.totalAmount}>
            <div className={css.cardTitle}>
              <h6>
                <FormattedMessage id="SettingPage.vacationMode" />
              </h6>
            </div>
            {inVacationMode ? (
              <div className={css.accountPaused}>
                <div className={css.pausedCircle}></div>
                <p>
                  <FormattedMessage id="SettingPage.accountPaused" />
                </p>
              </div>
            ) : (
              <div className={css.accountStaus}>
                <div className={css.activeCircle}></div>
                <p>
                  <FormattedMessage id="SettingPage.accountActive" />
                </p>
              </div>
            )}
          </div>
          <div className={css.viewDetails}>
            <h6>
              {inVacationMode ? (
                <FormattedMessage id="SettingPage.on" />
              ) : (
                <FormattedMessage id="SettingPage.off" />
              )}
            </h6>
            <div
              className={css.checkboxWrapper}
              onClick={() => {
                if (IsDraftStatus) {
                  handleModel();
                } else {
                  onVacationListing(inVacationMode ? 'Close' : 'Open');
                }
              }}
            >
              <div
                className={classNames(css.ball, {
                  [css.toggled]: inVacationMode,
                })}
              ></div>
            </div>
          </div>
        </div>
        <div className={css.paragraph}>
          <p>
            <FormattedMessage id="SettingPage.vacationDetails" />
          </p>
        </div>
        {isDraft}
      </div>
      {/* --------------------------------- */}
      <div className={css.cardWrapper}>
        <div className={css.mainContents}>
          <div className={css.totalAmount}>
            <div className={css.cardTitle}>
              <h6>
                <FormattedMessage id="SettingPage.addressTitle" />
              </h6>
            </div>
          </div>
        </div>
        <div className={classNames(css.paragraph, css.addressDescription)}>
          <p>
            <FormattedMessage id="SettingPage.addressDescription" />
          </p>
        </div>
        <div className={css.changeCard}>
          <div className={css.locationContent}>
            <span>
              <IconCollection name="SETTING_CHANGE" />
            </span>
            <p>{cityName},{states.getStateCodeByStateName(finalState)}</p>
          </div>
          <button>
            <FormattedMessage id="SettingPage.change" />
          </button>
        </div>
      </div>
    </div>
  );
};

SettingPannel.propTypes = {};

export default SettingPannel;
