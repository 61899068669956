import React, { Component } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { richText } from '../../util/richText';
import {
  ensureListing,
  ensureUser,
  getExternalUserReviews,
  calculateAverageUserReview,
} from '../../util/data';
import {
  AvatarMedium,
  IconCollection,
  ResponsiveImage,
} from '../../components';
import lighting from '../../assets/ListingCard/lighting.png';

import css from './SearchMapInfoCard.module.css';

const ON = 'On';
const MIN_LENGTH_FOR_LONG_WORDS = 10;

// ListingCard is the listing info without overlayview or carousel controls
const ListingCard = props => {
  const {
    className,
    clickHandler,
    intl,
    isInCarousel,
    listing,
    bookmarkedData,
    urlToListing,
    currentUser,
    reviews,
  } = props;
  const { title, price } = listing.attributes;
  const ensuredAuthor = ensureUser(listing?.author);

  const authorReviews = reviews?.find(
    review =>
      review?.included &&
      review?.included?.find(
        included => included?.id?.uuid === ensuredAuthor?.id?.uuid
      )
  );

  // Retrieve external reviews for the author.
  const authorExternalReviews = getExternalUserReviews(listing?.author);

  // Calculate the average review considering both local and external reviews for the user.
  const averageReview = calculateAverageUserReview(
    authorReviews,
    authorExternalReviews
  );

  const isInstantBooking = listing?.attributes?.publicData?.instantBooking;
  const id = listing.id.uuid;
  const authorImage = (
    <AvatarMedium
      user={listing.author}
      style={{ width: '40px', height: '40px' }}
    />
  );

  const formattedPrice =
    price && price.currency === config.currency
      ? formatMoney(intl, price)
      : price?.currency;
  const firstImage =
    listing.images && listing.images.length > 0 ? listing.images[0] : null;

  // listing card anchor needs sometimes inherited border radius.
  const classes = classNames(
    css.anchor,
    css.borderRadiusInheritTop,
    { [css.borderRadiusInheritBottom]: !isInCarousel },
    className
  );

  const bookmark = currentUser?.attributes?.profile.publicData?.bookmark || [];
  const index = bookmark?.findIndex(ids => ids === id);
  const handleClick = e => {
    e.preventDefault();
    index > -1
      ? [
          bookmark.splice(index, 1),
          bookmarkedData({ publicData: { bookmark } }),
        ]
      : [bookmark.push(id), bookmarkedData({ publicData: { bookmark } })];
  };

  return (
    <div
      className={classNames(css.card, css.borderRadiusInheritTop, {
        [css.borderRadiusInheritBottom]: !isInCarousel,
      })}
    >
      <a
        alt={title}
        className={classes}
        href={urlToListing}
        onClick={e => {
          e.preventDefault();
          // Use clickHandler from props to call internal router
          clickHandler(listing);
        }}
      >
        <div style={{ flex: 1.3 }}>
          <div
            className={classNames(
              css.threeToTwoWrapper,
              css.borderRadiusInheritTop
            )}
          >
            <div
              className={classNames(
                css.aspectWrapper,
                css.borderRadiusInheritTop
              )}
            >
              <ResponsiveImage
                rootClassName={classNames(
                  css.rootForImage,
                  css.borderRadiusInheritTop
                )}
                alt={title}
                noImageMessage={intl.formatMessage({
                  id: 'SearchMapInfoCard.noImage',
                })}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
                sizes="250px"
              />
            </div>
          </div>
          <div
            className={classNames(css.info, {
              [css.borderRadiusInheritBottom]: !isInCarousel,
            })}
          ></div>
        </div>
        <div className={css.infoMain}>
          <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <h4 className={css.title}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </h4>

              <div className={css.mainInfo}>
                {authorImage}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h4 className={css.title}>
                    {richText(listing.author.attributes.profile.displayName, {
                      longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                      longWordClass: css.longWord,
                    })}
                  </h4>
                  <div
                    className={classNames(css.ratingStar, css.locationContent)}
                  >
                    <IconCollection name="RATING_STAR" />
                    <h6>{averageReview}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={css.price}>
            <div className={css.priceContent}>
              {isInstantBooking == ON && <img src={lighting} alt="" />}
              <h5 className={css.priceValue}>{formattedPrice}</h5>
              <span className={css.dayText}>Day</span>
              <p className={css.perUnit}>
                {/* <FormattedMessage id={unitTranslationKey} /> */}
              </p>
            </div>
            <span onClick={e => handleClick(e)}>
              {index > -1 ? (
                <IconCollection name="FILLED_LOVE_ICON" />
              ) : (
                <IconCollection name="LOVE_ICON" />
              )}
            </span>
          </div>
        </div>
      </a>
    </div>
  );
};

ListingCard.defaultProps = {
  className: null,
};

ListingCard.propTypes = {
  className: string,
  listing: propTypes.listing.isRequired,
  clickHandler: func.isRequired,
  intl: intlShape.isRequired,
  isInCarousel: bool.isRequired,
};

class SearchMapInfoCard extends Component {
  constructor(props) {
    super(props);

    this.state = { currentListingIndex: 0 };
  }
  render() {
    const {
      className,
      rootClassName,
      intl,
      listings,
      currentUser,
      bookmarkedData,
      createURLToListing,
      onListingInfoCardClicked,
      reviews,
    } = this.props;

    const currentListing = ensureListing(
      listings[this.state.currentListingIndex]
    );
    const hasCarousel = listings.length > 1;
    const pagination = hasCarousel ? (
      <div
        className={classNames(
          css.paginationInfo,
          css.borderRadiusInheritBottom
        )}
      >
        <button
          className={css.paginationPrev}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.setState(prevState => ({
              currentListingIndex:
                (prevState.currentListingIndex + listings.length - 1) %
                listings.length,
            }));
          }}
        />
        <div className={css.paginationPage}>
          {`${this.state.currentListingIndex + 1}/${listings.length}`}
        </div>
        <button
          className={css.paginationNext}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.setState(prevState => ({
              currentListingIndex:
                (prevState.currentListingIndex + listings.length + 1) %
                listings.length,
            }));
          }}
        />
      </div>
    ) : null;

    const classes = classNames(rootClassName || css.root, className);
    const caretClass = classNames(css.caret, {
      [css.caretWithCarousel]: hasCarousel,
    });

    return (
      <div className={classes}>
        <div className={css.caretShadow} />
        <ListingCard
          reviews={reviews}
          currentUser={currentUser}
          bookmarkedData={bookmarkedData}
          clickHandler={onListingInfoCardClicked}
          urlToListing={createURLToListing(currentListing)}
          listing={currentListing}
          intl={intl}
          isInCarousel={hasCarousel}
        />
        {pagination}
        <div className={caretClass} />
      </div>
    );
  }
}

SearchMapInfoCard.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchMapInfoCard.propTypes = {
  className: string,
  rootClassName: string,
  listings: arrayOf(propTypes.listing).isRequired,
  onListingInfoCardClicked: func.isRequired,
  createURLToListing: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(SearchMapInfoCard);
