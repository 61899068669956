import React, { useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { getDefaultTimeZoneOnBrowser, timestampToDate } from '../../util/dates';
import {
  propTypes,
} from '../../util/types';
import {
  Button,
  Modal,
} from '../../components';
import {
  EditListingAvailabilityPlanForm,
  EditListingAvailabilityExceptionForm,
} from '../../forms';

import css from './EditListingAvailabilityPanel.module.css';
import _, { isArray } from 'lodash';
import moment from 'moment';

const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
const WEEKS = [
  { key: 'mon', label: 'Monday' },
  { key: 'tue', label: 'Tuesday' },
  { key: 'wed', label: 'Wednesday' },
  { key: 'thu', label: 'Thursday' },
  { key: 'fri', label: 'Friday' },
  { key: 'sat', label: 'Saturday' },
  { key: 'sun', label: 'Sunday' },
];

// We want to sort exceptions on the client-side, maximum pagination page size is 100,
// so we need to restrict the amount of exceptions to that.
const MAX_EXCEPTIONS_COUNT = 100;

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

/////////////
// Weekday //
/////////////
const findEntry = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.find(d => d.dayOfWeek === dayOfWeek);

const getEntries = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.filter(d => d.dayOfWeek === dayOfWeek);

const Weekday = props => {
  const { availabilityPlan, dayOfWeek, openEditModal } = props;
  const hasEntry = findEntry(availabilityPlan, dayOfWeek);
  return (
    <div
      className={classNames(css.weekDay, { [css.blockedWeekDay]: !hasEntry })}
      onClick={() => openEditModal(true)}
      role="button"
    >
      <div className={css.dayOfWeek}>
        <FormattedMessage
          id={`EditListingAvailabilityPanel.dayOfWeek.${dayOfWeek}`}
        />
      </div>
      <div className={css.entries}>
        {availabilityPlan && hasEntry
          ? getEntries(availabilityPlan, dayOfWeek).map(e => {
            return (
              <span
                className={css.entry}
                key={`${e.dayOfWeek}${e.startTime}`}
              >{`${moment(`${e.startTime}`, ["HH.mm"]).format("hh:mm A")} - ${e.endTime === '00:00' ? '24:00' : moment(`${e.endTime}`, ["HH.mm"]).format("hh:mm A")
                }`}</span>
            );
          })
          : null}
      </div>
    </div>
  );
};

///////////////////////////////////////////////////
// EditListingAvailabilityExceptionPanel - utils //
///////////////////////////////////////////////////

// Create initial entry mapping for form's initial values
const createEntryDayGroups = (entries = {}) =>
  entries.reduce((groupedEntries, entry) => {
    const { startTime, endTime: endHour, dayOfWeek } = entry;
    const dayGroup = groupedEntries[dayOfWeek] || [];
    return {
      ...groupedEntries,
      [dayOfWeek]: [
        ...dayGroup,
        {
          startTime,
          endTime: endHour === '00:00' ? '24:00' : endHour,
        },
      ],
    };
  }, {});

// Create initial values
const createInitialValues = availabilityPlan => {
  const { timezone, entries } = availabilityPlan || {};
  const tz = timezone || defaultTimeZone();
  return {
    timezone: tz,
    ...createEntryDayGroups(entries),
  };
};

// Create entries from submit values
const createEntriesFromSubmitValues = values =>
  WEEKDAYS.reduce((allEntries, dayOfWeek) => {
    const dayValues = values[dayOfWeek] || [];
    const dayEntries = dayValues.map(dayValue => {
      const { startTime, endTime } = dayValue;
      // Note: This template doesn't support seats yet.
      return startTime && endTime
        ? {
          dayOfWeek,
          seats: 1,
          startTime,
          endTime: endTime === '24:00' ? '00:00' : endTime,
        }
        : null;
    });

    return allEntries.concat(dayEntries.filter(e => !!e));
  }, []);

// Create availabilityPlan from submit values
const createAvailabilityPlan = values => ({
  availabilityPlan: {
    type: 'availability-plan/time',
    timezone: values.timezone,
    entries: createEntriesFromSubmitValues(values),
  },
});

// Ensure that the AvailabilityExceptions are in sensible order.
//
// Note: if you allow fetching more than 100 exception,
// pagination kicks in and that makes client-side sorting impossible.
const sortExceptionsByStartTime = (a, b) => {
  return a.attributes.start.getTime() - b.attributes.start.getTime();
};

//////////////////////////////////
// EditListingAvailabilityPanel //
//////////////////////////////////
const EditListingAvailabilityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    availabilityExceptions,
    fetchExceptionsInProgress,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    disabled,
    ready,
    onSubmit,
    onManageDisableScrolling,
    onNextTab,
    submitButtonText,
    updateInProgress,
    onPrevious,
    errors,
    values,
    onShowStripeOnboarding,
    paymentMethod
  } = props;
  // Hooks
  const [isEditPlanModalOpen, setIsEditPlanModalOpen] = useState(false);
  const [isEditExceptionsModalOpen, setIsEditExceptionsModalOpen] = useState(
    false
  );
  const [valuesFromLastSubmit, setValuesFromLastSubmit] = useState(null);
  const [saveParams, setSaveParams] = useState(null);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const defaultAvailabilityPlan = {
    type: 'availability-plan/time',
    timezone: defaultTimeZone(),
    entries: [
      { dayOfWeek: 'mon', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'tue', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'wed', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'thu', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'fri', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'sat', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'sun', startTime: '00:00', endTime: '00:00', seats: 1 },
    ]
  };
  const [availabilityPlan, setAvailabilityPlan] = useState(currentListing.attributes.availabilityPlan || defaultAvailabilityPlan);
  const initialValues = valuesFromLastSubmit ? valuesFromLastSubmit : createInitialValues(availabilityPlan);
  const isNextButtonDisabled = availabilityPlan === null ? true : false;

  const handleChange = (params) => {
    setValuesFromLastSubmit(params);
    const allDays = params && params.allDays && params.allDays[0]

    const allWeeks = _.mapValues(params, function (o) { return isArray(o) && o.length; });


    let weeks = Object.keys(allWeeks).filter(key => allWeeks[key]).map(item => {
      return { dayOfWeek: item, startTime: moment(params.startTime, 'hh:mm A').format('HH:mm'), endTime: moment(params.endTime, 'hh:mm A').format('HH:mm'), seats: 1 }
    });
    weeks = weeks.filter(e => e.dayOfWeek !== 'timezone')
    if (allDays) {
      weeks = WEEKDAYS.map(item => {
        return { dayOfWeek: item, startTime: moment(params.startTime, 'hh:mm A').format('HH:mm'), endTime: moment(params.endTime, 'hh:mm A').format('HH:mm'), seats: 1 }
      })
    };

    const availabilityPlan = {
      type: 'availability-plan/time',
      timezone: defaultTimeZone(),
      entries: weeks,
    };

    setAvailabilityPlan(availabilityPlan);
    setIsEditPlanModalOpen(false);
  }

  const handleSubmit = params => {
    if (!availabilityPlan) {
      onSubmit({
        availabilityPlan: defaultAvailabilityPlan,
        publicData: {
          availabilityPlan: defaultAvailabilityPlan
        }
      });
      return
    }

    return onSubmit({
      availabilityPlan: availabilityPlan,
      publicData: {
        availabilityPlan: availabilityPlan
      }
    })
      .then(() => {
        setIsEditPlanModalOpen(false);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };

  const exceptionCount = availabilityExceptions
    ? availabilityExceptions.length
    : 0;
  const sortedAvailabilityExceptions = availabilityExceptions.sort(
    sortExceptionsByStartTime
  );

  // Save exception click handler
  const saveException = values => {
    const { availability, exceptionStartTime, exceptionEndTime } = values;

    // TODO: add proper seat handling
    const seats = availability === 'available' ? 1 : 0;

    return onAddAvailabilityException({
      listingId: listing.id,
      seats,
      start: timestampToDate(exceptionStartTime),
      end: timestampToDate(exceptionEndTime),
    })
      .then(() => {
        setIsEditExceptionsModalOpen(false);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };

  const onlyWeeks = _.mapValues(initialValues, function (o) { return isArray(o) && o.length ? [true] : false });
  const onlyTimes = Object.values(initialValues)?.filter(item => Array.isArray(item))?.flat()?.at(0);
  const startTime = initialValues?.startTime || onlyTimes?.startTime;
  const endTime = initialValues?.endTime || onlyTimes?.endTime;

  return (
    <main className={classes} paymentMethod={paymentMethod}>
      <div className={css.informationContents}>
        <div className={css.contentWrapper}>
          <h1 className={css.title}>
            <FormattedMessage id="EditListingAvailabilityPanel.createListingTitle" />
          </h1>
          <p><FormattedMessage id="EditListingAvailabilityPanel.createListingSubTitle" /></p>
          <div className={css.dividerLine}></div>
          <section className={css.section}>
            <header className={css.sectionHeader}>
              <h2 className={css.sectionTitle}>
                <FormattedMessage id="EditListingAvailabilityPanel.defaultScheduleTitle" />
              </h2>
            </header>
            <div className={css.week}>
              {WEEKDAYS.map(w => (
                <Weekday
                  dayOfWeek={w}
                  key={w}
                  availabilityPlan={availabilityPlan}
                  openEditModal={setIsEditPlanModalOpen}
                />
              ))}
            </div>
          </section>

          {errors.showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingAvailabilityPanel.showListingFailed" />
            </p>
          ) : null}

          <div className={classNames(css.bottomButtons, css.buttonContent)}>
            <Button
              className={css.submitButton}
              type="button"
              onClick={onPrevious}
            >
              <FormattedMessage id="EditListingDescriptionForm.backButtonText" />
            </Button>
            <Button
              className={css.goToNextTabButton}
              onClick={() => handleSubmit(saveParams)}
              disabled={isNextButtonDisabled}
            >
              {submitButtonText}
            </Button>


          </div>
          {onManageDisableScrolling ? (
            <Modal
              id="EditAvailabilityPlan"
              isOpen={isEditPlanModalOpen}
              onClose={() => setIsEditPlanModalOpen(false)}
              onManageDisableScrolling={onManageDisableScrolling}
              containerClassName={css.modalContainer}
              usePortal
              listingEditAvailability={true}
            >
              <EditListingAvailabilityPlanForm
                formId="EditListingAvailabilityPlanForm"
                listingTitle={currentListing.attributes.title}
                availabilityPlan={availabilityPlan}
                weekdays={WEEKDAYS}
                setIsEditPlanModalOpen={setIsEditPlanModalOpen}
                onSubmit={handleChange}
                initialValues={{ ...onlyWeeks, startTime: startTime ? moment(`${startTime}`, ["hh:mm"]).format("hh:mm A") : null, endTime: endTime ? moment(`${endTime}`, ["hh:mm "]).format("hh:mm A") : null }}
                inProgress={updateInProgress}
                fetchErrors={errors}
                weeks={WEEKS}
              />
            </Modal>
          ) : null}
          {onManageDisableScrolling ? (
            <Modal
              id="EditAvailabilityExceptions"
              isOpen={isEditExceptionsModalOpen}
              onClose={() => setIsEditExceptionsModalOpen(false)}
              onManageDisableScrolling={onManageDisableScrolling}
              containerClassName={css.modalContainer}
              usePortal
            >
              <EditListingAvailabilityExceptionForm
                formId="EditListingAvailabilityExceptionForm"
                onSubmit={saveException}
                timeZone={availabilityPlan.timezone}
                availabilityExceptions={sortedAvailabilityExceptions}
                updateInProgress={updateInProgress}
                fetchErrors={errors}
              />
            </Modal>
          ) : null}
        </div>
      </div>
    </main>
  );
};

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  availabilityExceptions: [],
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  fetchExceptionsInProgress: bool.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onSubmit: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onNextTab: func.isRequired,
  onPrevious: func.onPrevious,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAvailabilityPanel;
