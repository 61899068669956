import React from 'react';
import { FormattedMessage } from 'react-intl';
import ListingCard from '../ListingCard/ListingCard';
import NamedLink from '../NamedLink/NamedLink';
import css from './SectionPopularItem.module.css';

const SectionPopularItem = props => {
  const { listings, viewport } = props;
  return (
    <div className={css.sectionContainer}>
      <div className={css.container}>
        <div className={css.titleWrapper}>
          <h1>
            <FormattedMessage id="SectionLatestItem.title" />
          </h1>
          <div>
            <NamedLink name="SearchPage">
              <FormattedMessage id="SectionCategory.viewAll" />
            </NamedLink>
          </div>
        </div>
        <div className={css.imageContent}>
          {listings &&
            listings?.length &&
            listings?.sort((a, b) => (a.attributes.publicData.distance < b.attributes.publicData.distance ? -1 : 1)).slice(0, 8).map((l, i) => (
              <div className={css.productContent}>
                <ListingCard viewport={viewport} key={(l.id.uuid)+i} listing={l}/>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SectionPopularItem;
