import React from 'react';
import classNames from 'classnames';
import css from './Earn.module.css';
import image from '../../assets/earnPage/section.png';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import Typer from './Typer';
import EarnBanner from '../../components/Banner/EarnBanner';

export default function Earn() {
  const history = useHistory();
  return (
    <section
      className={classNames(
        'py-5 mb-4 d-flex align-items-center',
        css.earnSection
      )}
    >
      <div className="container pt-5">
        <div className="row gap-5 gap-md-0">
          <div className="col-lg-6 d-flex align-items-center">
            <div>
              <h1 className={classNames('display-5 fw-bold mb-4')}>
                Earn $400/wk renting out your{' '}
                <Typer
                  strings={[
                    'Tools',
                    'Laptop',
                    'eScooter',
                    'Drone',
                    'Carpet cleaner',
                    'Moped',
                    'Electronics',
                  ]}
                  className={css.laptopText}
                />
              </h1>
              <p className={classNames('h4 mb-5')}>
                Make an average of $60/Day from home doing (almost){' '}
                <span
                  data-tooltip-id="nothing"
                  className="text-decoration-underline"
                >
                  nothing
                </span>
                , safe and{' '}
                <span
                  data-tooltip-id="secure"
                  className="text-decoration-underline"
                >
                  secure
                </span>
                .
              </p>
              <React.Fragment key="tooltips">
                <Tooltip
                  id="nothing"
                  effect="solid"
                  place="bottom"
                  type="dark"
                  backgroundColor="#48C0B3"
                  style={{
                    maxWidth: '350px',
                    background: '#48C0B3',
                    opacity: 1,
                  }}
                  className="p-3 rounded-4"
                >
                  <h3 className="text-white h4 mb-3">Do Nothing</h3>
                  <p>
                    Takes less than 4 minutes to list an item and rentals are
                    picked up from your location so you literally have to do
                    (almost) nothing
                  </p>
                </Tooltip>
                <Tooltip
                  id="secure"
                  effect="solid"
                  place="bottom"
                  type="dark"
                  backgroundColor="#48C0B3"
                  style={{
                    maxWidth: '350px',
                    background: '#48C0B3',
                    opacity: 1,
                  }}
                  className="p-3 rounded-4"
                >
                  <h3 className="text-white h4 mb-3">Secure</h3>
                  <p>
                    All users go through rigorous verifications and background
                    checks and all items listed are protected by Covize for up
                    to $20,000
                  </p>
                </Tooltip>
              </React.Fragment>
              <div className="pt-4">
                <div
                  className={`${css.signupLink} ${css.listItem}`}
                  onClick={() => {
                    history.push(
                      createResourceLocatorString(
                        'NewListingPage',
                        routeConfiguration(),
                        {},
                        {}
                      )
                    );
                  }}
                >
                  <span className={css.signup}>
                    <FormattedMessage id="TopbarDesktop.listAnItem" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <img src={image} alt="Earn" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
}
