import React, { useCallback } from "react";
import Typed from "typed.js";

const Typer = ({ strings = [], ...props }) => {
  const typerRef = useCallback(
    (typer) => {
      if (!typer) return;
      typer.innerHTML = "";
      const span = document.createElement("span");
      span.style.whiteSpace = "pre-wrap";
      typer.append(span);
      const options = {
        strings,
        typeSpeed: 70,
        backSpeed: 30,
        loop: true,
        cursorChar: "|",
      };
      const t = new Typed(span, options);
      return () => t.destroy();
    },
    [strings]
  );
  return <span {...props} ref={typerRef} />;
};

export default Typer;
