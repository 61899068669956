import React from 'react';
import { bool } from 'prop-types';

import DistanceFilterPopup from './DistanceFilterPopup';
import DistanceFilterPlain from './DistanceFilterPlain';

const DistanceFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? (
    <DistanceFilterPopup {...rest} />
  ) : (
    <DistanceFilterPlain {...rest} />
  );
};

DistanceFilter.defaultProps = {
  showAsPopup: false,
};

DistanceFilter.propTypes = {
  showAsPopup: bool,
};

export default DistanceFilter;
