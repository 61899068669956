import {
  FieldSelect,
  FieldTextInput,
  LocationAutocompleteInputField,
} from '../../components';
import config from '../../config';
import { getPlaceDetails, getPlacePredictions } from '../../util/googleMaps';
import * as validators from '../../util/validators';
import css from './LocationForm.module.css';

const LocationForm = props => {
  const { form, formId, values, intl, setshowError } = props;
  const googleMapSessionToken =
    typeof window !== 'undefined'
      ? new window.google.maps.places.AutocompleteSessionToken()
      : null;

  const addressRequiredMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressRequired',
  });
  const locationHeadingText = intl.formatMessage({
    id: 'EditListingDescriptionForm.loactionTitle',
  });
  const addressPlaceholderMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressPlaceholder',
  });
  const zipCodeValidError = intl.formatMessage({
    id: 'SignupForm.zipCodeRequiredError',
  });

  const zipCodeUSValid = intl.formatMessage({
    id: 'SignupForm.zipCodeUSRequiredError',
  });
  const zipCodeLabel = intl.formatMessage({
    id: 'SignupForm.zipCodeLabel',
  });

  const identity = v => v;
  const zipCodeMinLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.zipCodeTooShort',
    },
    {
      minLength: validators.ZIPCODE_MIN_LENGTH,
    }
  );
  const zipCodeMinLength = validators.minLength(
    zipCodeMinLengthMessage,
    validators.ZIPCODE_MIN_LENGTH
  );
  const zipValidError = validators.required(zipCodeValidError);
  const zipCodeValid = validators.zipCodeFormatValid(zipCodeUSValid);

  const handleChange = async event => {
    setshowError(false);
    if (event.target.value && event.target.value.length > 4) {
      getPlacePredictions(event.target.value, googleMapSessionToken, {
        componentRestrictions: { country: config.maps.search.countryLimit },
        types: ['postal_code'],
      })
        .then(data => {
          const selectedPlace = data.predictions[0];
          getPlaceDetails(selectedPlace.place_id, googleMapSessionToken).then(
            data => {
              const { address, addressComponents, origin, bounds } = data;
              let city, state, zipCode, streetAddress, streetNumber;
              addressComponents &&
                addressComponents.forEach(component => {
                  if (component.types.includes('street_number')) {
                    streetNumber = component.long_name;
                  } else if (component.types.includes('sublocality')) {
                    city = component.long_name;
                  } else if (component.types.includes('locality')) {
                    city = component.long_name;
                  } else if (
                    component.types.includes('administrative_area_level_1')
                  ) {
                    state = component.long_name;
                  } else if (component.types.includes('postal_code')) {
                    zipCode = component.long_name;
                  } else if (component.types.includes('route')) {
                    streetAddress = component.long_name;
                  }
                });
              const { sw, ne } = bounds;
              const latLngArray = [sw.lng, sw.lat, ne.lng, ne.lat];
              form.change('city', city);
              form.change('state', state);
              form.change('zipCode', zipCode);
              form.change('zipLocation', address);
              form.change('zipCodeLatLng', latLngArray);
              form.change('location', {
                ...values.location,
                search: `${streetNumber} ${streetAddress}`,
              });
              form.change('origin', origin);
            }
          );
        })
        .catch(err => {
          setshowError(true);
        });
    }
  };

  const onAutocompleteSelection = async selectedPlace => {
    const { address, addressComponents, origin, bounds } = selectedPlace;
    let city, state, zipCode, streetAddress, streetNumber;
    addressComponents.forEach(component => {
      if (component.types.includes('street_number')) {
        streetNumber = component.long_name;
      } else if (component.types.includes('sublocality')) {
        city = component.long_name;
      } else if (component.types.includes('locality')) {
        city = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        state = component.long_name;
      } else if (component.types.includes('postal_code')) {
        zipCode = component.long_name;
      } else if (component.types.includes('route')) {
        streetAddress = component.long_name;
      }
    });
    const { sw, ne } = bounds;
    const latLngArray = [sw.lng, sw.lat, ne.lng, ne.lat];
    form.change('city', city);
    form.change('state', state);
    form.change('zipCode', zipCode);
    form.change('zipLocation', address);
    form.change('zipCodeLatLng', latLngArray);
    form.change('streetAddress', `${streetNumber} ${streetAddress}`);
    form.change('origin', origin);
    form.change('location', {
      ...values.location,
      search: `${streetNumber} ${streetAddress}`,
    });
  };

  return (
    <>
      <div className={css.locationOption}>
        <FieldSelect
          id="option"
          name="option"
          label="Location"
          defaultValue=""
          validate={validators.autocompleteSearchRequired(
            addressRequiredMessage
          )}
        >
          <option disabled value="">
            Select an option
          </option>
          <option value="ZipCode">Zip Code</option>
          <option value="Address">Address</option>
        </FieldSelect>
      </div>

      {values && values.option === 'Address' ? (
        <div className={css.addressBox}>
          <LocationAutocompleteInputField
            className={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            name="location"
            label={locationHeadingText}
            placeholder={addressPlaceholderMessage}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            onAutocompleteSelection={onAutocompleteSelection}
            validate={validators.autocompleteSearchRequired(
              addressRequiredMessage
            )}
          />
        </div>
      ) : null}

      {values && values.option === 'ZipCode' ? (
        <FieldTextInput
          className={css.firstNameRoot}
          type="text"
          id={formId ? `${formId}.zipCode` : 'zipCode'}
          name="zipCode"
          maxLength="5"
          onKeyUp={event => handleChange(event)}
          label={zipCodeLabel}
          validate={validators.composeValidators(
            zipCodeMinLength,
            zipValidError,
            zipCodeValid
          )}
        />
      ) : null}
    </>
  );
};

export default LocationForm;
