import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
  IconCollection,
} from '../../components';
import { ReactComponent as ClimateIcon } from '../../assets/climate-icon.svg';
import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const {
    siteFacebookPage,
    siteInstagramPage,
    siteTwitterHandle,
    siteYoutubePage,
    siteTiktokPage,
  } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  const goToYoutube = intl.formatMessage({ id: 'Footer.goTouYoutube' });
  const goToTiktok = intl.formatMessage({ id: 'Footer.goToTiktok' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink
      key="linkToFacebook"
      href={siteFacebookPage}
      className={css.icon}
      title={goToFb}
    >
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const youtubeLink = siteYoutubePage ? (
    <ExternalLink
      key="linkToYoutube"
      href={siteYoutubePage}
      className={css.icon}
      title={goToYoutube}
    >
      <IconCollection name="YOUTUBE_LOGO" />
    </ExternalLink>
  ) : null;

  const tiktokLink = siteTiktokPage ? (
    <ExternalLink
      key="linkToTiktok"
      href={siteTiktokPage}
      className={css.icon}
      title={goToTiktok}
    >
      <IconCollection name="TIKTOK_LOGO" />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [youtubeLink, twitterLink, fbLink, instragramLink, tiktokLink].filter(
    v => v != null
  );
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <footer className="footer__area">
      <div className="container">
        <div className="footer__top__social__blk">
          <div className="footer__top__social__ico">
            <a href="#">
              <img
                src={`${process.env.PUBLIC_URL}/static/assets/img/youtube.svg`}
                alt=""
              />
            </a>
            <a href="https://twitter.com/Runtize">
              <img
                src={`${process.env.PUBLIC_URL}/static/assets/img/x.svg`}
                alt=""
              />
            </a>
            <a href="https://www.instagram.com/runtize_official/?hl=en">
              <img
                src={`${process.env.PUBLIC_URL}/static/assets/img/instagram.svg`}
                alt=""
              />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100093620793545&mibextid=ZbWKwL">
              <img
                src={`${process.env.PUBLIC_URL}/static/assets/img/facebook.svg`}
                alt=""
              />
            </a>
            <a href="#">
              <img
                src={`${process.env.PUBLIC_URL}/static/assets/img/tiktok.svg`}
                alt=""
              />
            </a>
          </div>
          <div className="blog__btn">
            <a href="#">BLOG</a>
          </div>
        </div>
        <div className="footer__bottom__blk">
          <div className="footer__copyright">
            <p>
              © 2023 runtize. All Rights Reserved.{' '}
              <a className='d-inline-block ms-2' href="https://climate.stripe.com/v7hRSH" target="_blank" rel="noopener noreferrer">
                <ClimateIcon width={20} />
              </a>
            </p>
          </div>
          <div className="footer__link__text">
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-of-service">Terms & Conditions</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
