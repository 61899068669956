import React from 'react';
import { FormattedMessage } from 'react-intl';
import ListingCard from '../ListingCard/ListingCard';
import NamedLink from '../NamedLink/NamedLink';
import css from './SimilarItems.module.css';
import Slider from 'react-slick';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill="#FBFBFB" />
        <path
          d="M19.3587 16.2755C19.5802 16.0928 19.8764 15.9938 20.1822 16.0003C20.4879 16.0068 20.7782 16.1183 20.9891 16.3102L28.6814 23.3097C28.8859 23.4957 29 23.7428 29 23.9997C29 24.2566 28.8859 24.5037 28.6814 24.6896L20.9906 31.6892C20.7797 31.8814 20.4894 31.9931 20.1835 31.9997C19.8776 32.0063 19.5812 31.9073 19.3594 31.7245C19.1377 31.5417 19.0089 31.29 19.0012 31.0249C18.9936 30.7597 19.1078 30.5028 19.3187 30.3106L26.2542 23.999L19.3171 17.6888C19.1065 17.4966 18.9926 17.2397 19.0004 16.9747C19.0082 16.7097 19.137 16.4582 19.3587 16.2755V16.2755Z"
          fill="#1F403B"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="24" fill="#FBFBFB" />
        <path
          d="M19.3587 16.2755C19.5802 16.0928 19.8764 15.9938 20.1822 16.0003C20.4879 16.0068 20.7782 16.1183 20.9891 16.3102L28.6814 23.3097C28.8859 23.4957 29 23.7428 29 23.9997C29 24.2566 28.8859 24.5037 28.6814 24.6896L20.9906 31.6892C20.7797 31.8814 20.4894 31.9931 20.1835 31.9997C19.8776 32.0063 19.5812 31.9073 19.3594 31.7245C19.1377 31.5417 19.0089 31.29 19.0012 31.0249C18.9936 30.7597 19.1078 30.5028 19.3187 30.3106L26.2542 23.999L19.3171 17.6888C19.1065 17.4966 18.9926 17.2397 19.0004 16.9747C19.0082 16.7097 19.137 16.4582 19.3587 16.2755Z"
          fill="#1F403B"
        />
      </svg>
    </div>
  );
}

const SimilarItems = props => {
  const { listings, viewport } = props;
  const isMobile = viewport && viewport.width < 767;

  const settings = {
    dots: false,
    infinite: listings?.length < 4 ? false : true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          // arrows: true,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          arrows: true,
          slidesToScroll: 1,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
        },
      },
    ],
  };
  return (
    <div className={css.sectionContainer}>
      <div className={css.container}>
        <div className={css.titleWrapper}>
          <h1>
            <FormattedMessage id="SectionSimilarItem.title" />
          </h1>
          <div>
            <NamedLink name="SearchPage">
              <FormattedMessage id="SectionCategory.viewAll" />
            </NamedLink>
          </div>
        </div>
        {listings &&
              listings?.length && <div className={css.imageContent}>
          <Slider {...settings}>
            {listings.map((l, i) => (
                <div
                  key={l.id.uuid}
                  //  style={{ width: isMobile ? '157px' : '25%' }}
                  className={css.productContent}
                >
                  <ListingCard key={l.id.uuid + i} listing={l} />
                </div>
              ))}
          </Slider>
        </div>}
      </div>
    </div>
  );
};

export default SimilarItems;
